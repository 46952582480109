// @ts-nocheck
import { Dispatch, SetStateAction, useState, useEffect } from "react";

const useVisibilityAnimation: (
  disappearOffset: number,
  defaultVisible?: boolean
) => [any, Dispatch<SetStateAction<boolean>>] = (
  disappearOffset,
  defaultVisible
) => {
  const [visible, setVisible] = useState(defaultVisible);
  const [noDisplay, setNoDisplay] = useState(!defaultVisible);

  useEffect(() => {
    let timer;
    if (!visible && !noDisplay) {
      timer = setTimeout(() => {
        setNoDisplay(true);
      }, disappearOffset);
    }

    if (visible) setNoDisplay(false);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // TODO: resolve useEffect dep error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const style = noDisplay ? { display: "none" } : null;

  return [style, setVisible];
};

export { useVisibilityAnimation };
