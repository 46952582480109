/* eslint-disable */
// @ts-nocheck
import React, {
  useState,
  useRef,
  Fragment,
  useLayoutEffect,
  useEffect,
  FC,
} from "react";
import { Button } from "../Button/Button";
import { Checkbox } from "../Checkbox/Checkbox";
import { Chip } from "../Chip/Chip";
import { KeywordSearch } from "../KeywordSearch/KeywordSearch";
import { Icon } from "../Icon/Icon";
import { getId, getTestId } from "../../utils/string";
import { Styles } from "../../utils/styles/designTokens/styles";
import {
  CheckboxContainer,
  ChipContainer,
  FieldsetWrapper,
  FilterContainer,
  FilterOptionsContainer,
  FilterOptionsFooter,
  FilterOptionsFooterGradient,
  FooterButtonContainer,
  LinkButton,
  MessageTemplate,
  OptionGroupTitle,
  OptionsContainer,
  Overlay,
  SearchContainer,
} from "./styles";
import {
  FilterOptions,
  IFilterGroupError,
  IFilterGroupLoading,
  FilterGroup,
} from "./types";

export interface IFilterProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  filterOptions: FilterOptions;
  applyFilters?: (selectedOptions: string[]) => void;
  defaultOptions?: string[];
  search?: boolean;
}

export const Filter: FC<IFilterProps> = ({
  id,
  className,
  dataTestId = "filter",
  filterOptions,
  applyFilters,
  defaultOptions = [],
  search = true,
}) => {
  const optionsContainerRef = useRef(null);
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [optionsGradientHeight, setOptionsGradientHeight] = useState(40);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const isSearchActive = searchTerm !== "";

  useEffect(() => {
    if (defaultOptions.length) {
      setCheckedFilters(defaultOptions);
      setAppliedFilters(defaultOptions);
    }
  }, [defaultOptions]);

  useLayoutEffect(() => {
    const hasNoScrollBar =
      optionsContainerRef &&
      optionsContainerRef.current &&
      optionsContainerRef.current.scrollHeight <=
        optionsContainerRef.current.clientHeight;

    hasNoScrollBar && setOptionsGradientHeight(0);
  }, [showFilterOptions]);

  useEffect(() => {
    if (!isSearchActive) {
      setSearchResults({});
      return;
    }

    const results = {};
    Object.entries(filterOptions).forEach(([heading, items]) => {
      const hasError = (items as IFilterGroupError).onRetryClick !== undefined;
      const isLoading = (items as IFilterGroupLoading).isLoading !== undefined;
      const hasGroupOptions = !hasError && !isLoading;

      const filteredItems = Object.keys(items).reduce(
        (accumulator, currentValue) => {
          if (
            hasGroupOptions &&
            items[currentValue]
              .toLowerCase()
              .includes(searchTerm.toLowerCase().trim())
          ) {
            accumulator[currentValue] = items[currentValue];
          }

          return accumulator;
        },
        {}
      );

      if (Object.keys(filteredItems).length) {
        results[heading] = filteredItems;
      }
    });

    setSearchResults(results);
  }, [searchTerm]);

  const handleOptionContainerScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollHeight, clientHeight, scrollTop } = e.target as Element;
    setOptionsGradientHeight(
      (1 / (scrollHeight - clientHeight)) *
        (scrollHeight - clientHeight - scrollTop) *
        40
    );
  };

  const handleOptionToggle = (option: string) => (isChecked: boolean) => {
    const isOptionInCurrentIndex = checkedFilters.indexOf(option) !== -1;
    if (isChecked && !isOptionInCurrentIndex) {
      setCheckedFilters([...checkedFilters, option]);
    } else if (!isChecked && isOptionInCurrentIndex) {
      setCheckedFilters(checkedFilters.filter((value) => option !== value));
    }
  };

  const handleFilterChipClose = (option: string) => () => {
    const newOptions = checkedFilters.filter((value) => option !== value);
    setCheckedFilters(newOptions);
    setAppliedFilters(newOptions);
    applyFilters && applyFilters(newOptions);
  };

  const resetFilters = () => setCheckedFilters([]);

  const onClearFilters = () => {
    setCheckedFilters([]);
    setAppliedFilters([]);
    applyFilters && applyFilters([]);
  };

  const handleApplyClick = () => {
    setAppliedFilters(checkedFilters);
    setShowFilterOptions(false);
    setSearchTerm("");
    applyFilters && applyFilters(checkedFilters);
  };

  const isCheckboxChecked = (optionKey) => checkedFilters.includes(optionKey);

  const handleOnFilterButtonClick = () =>
    setShowFilterOptions(!showFilterOptions);

  const getChipLabelFromKey = (key: number) => {
    let chipValue = "";
    const chipGroupName = Object.keys(filterOptions).find((groupName) => {
      if (key in filterOptions[groupName]) {
        chipValue = filterOptions[groupName][key];
        return true;
      } else {
        return false;
      }
    });

    return chipGroupName ? `${chipGroupName}: ${chipValue}` : `${chipValue}`;
  };

  const renderFilterOverview = () =>
    appliedFilters.length !== 0 && (
      <Fragment>
        {/* TODO: use unique id for filterChipContainer 
            https://integrate.atlassian.net/browse/LEGO-192 */}
        <ChipContainer id="filterChipContainer">
          {appliedFilters.map((option, index) => (
            <Chip
              label={getChipLabelFromKey(option)}
              key={index}
              onClose={handleFilterChipClose(option)}
              dataTestId={`${dataTestId}-${option}-filterChip`}
              maxWidth="200px"
            />
          ))}
        </ChipContainer>
        <LinkButton
          id={getId(id, "clearButton")}
          onClick={onClearFilters}
          data-testid={`${dataTestId}-clearButton`}
        >
          Clear filters
        </LinkButton>
      </Fragment>
    );

  const handleOverlayClick = () => {
    setCheckedFilters(appliedFilters);
    setShowFilterOptions(false);
    setSearchTerm("");
  };

  const renderGroupError = (groupName: string, groupOptions: FilterGroup) => {
    const hasError =
      (groupOptions as IFilterGroupError).onRetryClick !== undefined;

    if (hasError) {
      const { onRetryClick } = groupOptions as IFilterGroupError;

      const handleRetry = () => onRetryClick();

      return (
        <MessageTemplate>
          <Icon
            name="faExclamationTriangle"
            variant="light"
            color={Styles.uno.color.semantic.negative500}
            fontSize={Styles.fontSizes.l4}
            ariaLabel="error"
          />
          <div>There was a problem loading these filters</div>
          <LinkButton
            onClick={handleRetry}
            data-testid={`${dataTestId}-${groupName}-retry-button`}
          >
            Retry
          </LinkButton>
        </MessageTemplate>
      );
    }
  };

  const renderGroupLoading = (groupOptions: FilterGroup) => {
    const isLoading = (groupOptions as IFilterGroupLoading).isLoading === true;

    if (isLoading) {
      return (
        <MessageTemplate>
          <Icon
            name="faSpinner"
            color={Styles.colors.neutral300}
            fontSize={Styles.fontSizes.l4}
            ariaLabel="loading"
            spin
          />
          <div>These filters are still loading</div>
        </MessageTemplate>
      );
    }
  };

  const renderGroupCheckboxes = (
    groupName: string,
    groupOptions: FilterGroup
  ) => {
    const hasError =
      (groupOptions as IFilterGroupError).onRetryClick !== undefined;
    const isLoading =
      (groupOptions as IFilterGroupLoading).isLoading !== undefined;
    const hasGroupOptions = !hasError && !isLoading;

    if (hasGroupOptions) {
      return Object.keys(groupOptions).map((optionKey) => (
        <CheckboxContainer key={optionKey}>
          <Checkbox
            id={getId(id, "options", groupName, optionKey)}
            dataTestId={getTestId(dataTestId, "options", groupName, optionKey)}
            onChange={handleOptionToggle(optionKey)}
            isDefaultChecked={isCheckboxChecked(optionKey)}
            label={groupOptions[optionKey]}
            wrapLabel
          />
        </CheckboxContainer>
      ));
    }
  };

  const renderGroupOptions = (filters: FilterOptions) => {
    return Object.keys(filters).map((key) => (
      <Fragment key={key}>
        <FieldsetWrapper name={key} id={getId(id, "options", key)}>
          {key && <OptionGroupTitle>{key}</OptionGroupTitle>}
          {renderGroupCheckboxes(key, filters[key])}
          {renderGroupLoading(filters[key])}
          {renderGroupError(key, filters[key])}
        </FieldsetWrapper>
      </Fragment>
    ));
  };

  const renderEmptyResult = () => {
    const noResults = Object.keys(searchResults).length === 0;
    if (isSearchActive && noResults) {
      return (
        <MessageTemplate>
          <Icon
            name="faFolderOpen"
            color={Styles.colors.neutral300}
            fontSize={Styles.fontSizes.l4}
            ariaLabel="no results"
          />
          <div>We couldn’t find any filters matching your query</div>
        </MessageTemplate>
      );
    }
  };

  const renderOptions = () => {
    const filters = isSearchActive ? searchResults : filterOptions;

    return (
      showFilterOptions && (
        <FilterOptionsContainer
          id={getId(id, "popup")}
          data-testid={`${dataTestId}-options`}
        >
          {search && (
            <SearchContainer>
              <KeywordSearch
                id={getId(id, "search")}
                data-testid={`${dataTestId}-search-container`}
                onChange={setSearchTerm}
              />
            </SearchContainer>
          )}
          <OptionsContainer
            id={getId(id, "options")}
            ref={optionsContainerRef}
            onScroll={handleOptionContainerScroll}
          >
            {renderGroupOptions(filters)}
            {renderEmptyResult()}
            <FilterOptionsFooterGradient $height={optionsGradientHeight} />
          </OptionsContainer>
          <FilterOptionsFooter>
            <FooterButtonContainer>
              <Button
                id={getId(id, "resetButton")}
                dataTestId={`${dataTestId}-resetFooterButton`}
                onClick={resetFilters}
                label="Clear filters"
                type="secondary"
              />
              <Button
                id={getId(id, "applyButton")}
                dataTestId={`${dataTestId}-applyFooterButton`}
                onClick={handleApplyClick}
                label="Apply"
              />
            </FooterButtonContainer>
          </FilterOptionsFooter>
        </FilterOptionsContainer>
      )
    );
  };

  const renderOverlay = () =>
    showFilterOptions && (
      <Overlay
        data-testid={`${dataTestId}-overlay`}
        onClick={handleOverlayClick}
      />
    );

  return (
    <>
      <FilterContainer id={id} className={className} data-testid={dataTestId}>
        <Button
          id={getId(id, "togglePopup")}
          dataTestId={`${dataTestId}-filterButton`}
          onClick={handleOnFilterButtonClick}
          label="Filter"
          type="secondary"
          icon="faFilter"
          aria-controls={getId(id, "popup")}
          aria-haspopup="true"
          aria-expanded={showFilterOptions}
        />
        {renderFilterOverview()}
        {renderOptions()}
      </FilterContainer>
      {renderOverlay()}
    </>
  );
};
