/* eslint-disable */
import styled, { css } from "styled-components";

interface IOverlayProps {
  $isClosed: boolean;
}

const Overlay = styled.div<IOverlayProps>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  ${({ $isClosed }) =>
    $isClosed &&
    css`
      display: none;
    `}
`;

interface IWrapperProps {
  $width?: string;
}

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  ${({ $width }) => css`
    width: ${$width};
  `}
`;

interface OptionsWrapperProps {
  $hideLabel: boolean;
}

const OptionsWrapper = styled.div<OptionsWrapperProps>`
  position: absolute;
  top: ${({ $hideLabel }) => ($hideLabel ? "45px" : "70px")};
  width: 100%;
`;

export { Overlay, Wrapper, OptionsWrapper };
