/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useState, useEffect } from "react";
import { Styles } from "../../utils/styles/designTokens/styles";
import { Icon } from "../Icon/Icon";
import { ContentWrapper, IconWrapper, LabelWrapper, Wrapper } from "./styles";

export interface IChipProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  label: string;
  value?: string;
  color?:
    | "blue"
    | "indigo"
    | "red"
    | "green"
    | "yellow"
    | "orange"
    | "byzantium"
    | "purple"
    | "";
  onClose?: (val: string) => void;
  maxWidth?: string;
}

export const Chip: FunctionComponent<IChipProps> = ({
  id,
  className,
  dataTestId = "chip",
  label = "",
  value = label,
  color = Styles.colors.white,
  onClose,
  maxWidth,
}) => {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [closeIconColor, setCloseIconColor] = useState("");

  useEffect(() => {
    let newBackgroundColor: string = "";
    let newFontColor: string = "";
    let newBorderColor: string = "";
    let newCloseIconColor: string = "";

    switch (color) {
      case "blue":
        newBackgroundColor = Styles.colors.blue100;
        newFontColor = Styles.colors.blue700;
        newBorderColor = `${Styles.colors.blue500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.blue500;
        break;
      case "indigo":
        newBackgroundColor = Styles.colors.indigo100;
        newFontColor = Styles.colors.indigo700;
        newBorderColor = `${Styles.colors.indigo500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.indigo500;
        break;
      case "red":
        newBackgroundColor = Styles.colors.red100;
        newFontColor = Styles.colors.red700;
        newBorderColor = `${Styles.colors.red500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.red500;
        break;
      case "green":
        newBackgroundColor = Styles.colors.green100;
        newFontColor = Styles.colors.green700;
        newBorderColor = `${Styles.colors.green500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.green500;
        break;
      case "yellow":
        newBackgroundColor = Styles.colors.yellow100;
        newFontColor = Styles.colors.yellow700;
        newBorderColor = `${Styles.colors.yellow500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.yellow500;
        break;
      case "orange":
        newBackgroundColor = Styles.colors.orange100;
        newFontColor = Styles.colors.orange700;
        newBorderColor = `${Styles.colors.orange500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.orange500;
        break;
      case "byzantium":
        newBackgroundColor = Styles.colors.byzantium100;
        newFontColor = Styles.colors.byzantium700;
        newBorderColor = `${Styles.colors.byzantium500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.byzantium500;
        break;
      case "purple":
        newBackgroundColor = Styles.colors.purple100;
        newFontColor = Styles.colors.purple700;
        newBorderColor = `${Styles.colors.purple500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.purple500;
        break;
      default:
        newBackgroundColor = Styles.colors.neutral100;
        newFontColor = Styles.colors.neutral700;
        newBorderColor = `${Styles.colors.neutral500}${Styles.colors.opacity10}`;
        newCloseIconColor = Styles.colors.neutral500;
    }

    setBackgroundColor(newBackgroundColor);
    setFontColor(newFontColor);
    setBorderColor(newBorderColor);
    setCloseIconColor(newCloseIconColor);
  }, [color]);

  const renderCloseIcon = () => {
    if (onClose) {
      return (
        <IconWrapper data-testid="closeChipIcon">
          <Icon name="faTimes" ariaLabel="close chip" color={closeIconColor} />
        </IconWrapper>
      );
    }
  };

  return (
    <Wrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      $maxWidth={maxWidth}
      $clickable={!!onClose}
      onClick={onClose ? () => onClose(value) : null}
    >
      <ContentWrapper>
        <LabelWrapper $fontColor={fontColor}>{label}</LabelWrapper>
        {renderCloseIcon()}
      </ContentWrapper>
    </Wrapper>
  );
};
