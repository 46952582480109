import { FunctionComponent, ReactElement, useState } from "react";
import styled from "styled-components";
import { Styles } from "@integrate/hedgehogger";

type TabbedPageItem = {
  label: string;
  component: ReactElement;
};

type TabbedPagesProps = {
  tabData: TabbedPageItem[];
  currentTabIndex: number;
};

const TabRow = styled.div`
  cursor: default;
  display: inline-block;
  margin: 20px 0;
`;

const TabItem = styled.span`
  cursor: pointer;
  border-left: 2px solid ${Styles.colors.neutral300};
  font-size: 1.1em;
  font-weight: 700;
  padding: 0 10px;
  transition: color 0.07 ease;

  &:first-child {
    border-left: none;
    padding-left: 0;
  }

  &:hover {
    color: ${Styles.colors.blue500};
  }

  &.active {
    color: ${Styles.colors.blue500};
  }
`;

export const TabbedPages: FunctionComponent<TabbedPagesProps> = ({ tabData, currentTabIndex }) => {
  const [currentTab, setCurrentTab] = useState<TabbedPageItem>(tabData[currentTabIndex]);

  const generateTabElem = (): ReactElement => {
    return (
      <div>
        <TabRow>
          {tabData.map((data, i) => {
            return (
              <TabItem
                key={i}
                onClick={() => {
                  setCurrentTab(data);
                }}
                className={data.label === currentTab.label ? "active" : ""}
              >
                {data.label}
              </TabItem>
            );
          })}
        </TabRow>
        {currentTab.component}
      </div>
    );
  };

  return generateTabElem();
};
