import { httpRequest } from "./api";
import {
  IDataTableParams,
  IParsedDataTableRow,
  IDataTableColumn,
  IDataTableHeader,
  IDataTableRowAction,
} from "../../dataTable/DataTable";
import { UsersDataType, UserType } from "./UsersService";
import { BaseApiResponse } from "../Types";
import { StyledLink } from "../../styledLink/StyledLink";
import { ReactElement } from "react";
import { TableHelper } from "../helpers/TableHelper";

export type OrgDataType = OrgsDataType & {
  crmAccountIds: string[];
};

export type OrgDispositionItemType = {
  status: string;
};

export type OrgDispositionType = Record<string, OrgDispositionItemType>;

export type advertiserType = {
  advertiserId?: string;
  advertiserName?: string;
  success: boolean;
  message?: string;
};

export type OrgsDataType = {
  description: string;
  id: string;
  isAgency: boolean;
  isDAPActive: boolean;
  name: string;
  shortId: string;
  shortName: string;
  subscriptionId: string | null;
  advertiser?: advertiserType;
  dispositionMap: OrgDispositionType;
};

export type PartnerDataType = {
  id: string;
  ownerId: string;
  ownerName: string;
  ownerShortId: string;
  publisherId: string;
  publisherName: string;
  publisherShortId: string;
};

export type PartnersResponse = BaseApiResponse & {
  partners: PartnerDataType[];
  skip: number;
  sortBy: string;
  sortOrder: string;
  take: number;
  totalCount: number;
};
export type PermittedMarketerType = OrgDataType;

export type OrgType = BaseApiResponse & {
  organization: OrgDataType | null;
};

export type OrgsType = BaseApiResponse & {
  organizations: OrgDataType[];
  skip: number;
  sortBy: string;
  sortOrder: string;
  take: number;
  totalCount: number;
};

interface DeleteBody {
  ids: string[];
}

interface LicenseProperty{
  allowed: number;
  availed: number;
}
interface LicenseInfo{
  [key: string]: LicenseProperty;
}

interface LicenseCount{
  [key: string]: number;
}
export interface GetLicenseDataResponse  {
  data: GetLicenseData;
  success: boolean;
  messages: [];
}

export interface LicenseData {
  orgId: string;
  licenses: LicenseCount;
}

export interface GetLicenseData {
  organizationId: string;
  licenses:LicenseInfo;
}

export class OrgService {
  static getOrgs = (params: IDataTableParams): Promise<OrgsType> => {
    return httpRequest({
      url: `/api/accountmanagement/organizations/`,
      method: "GET",
      params: params,
    });
  };

  static getOrg = (orgId: string): Promise<OrgType> => {
    return httpRequest({
      url: `/api/accountmanagement/organizations/${orgId}`,
      method: "GET",
      params: {},
    });
  };

  static getLicense = (organizationId: string): Promise<GetLicenseDataResponse> => {
    return httpRequest({
      url: `/api/accountmanagement/license/${organizationId}`,
      method: "GET",
    });
  };

  static postLicense = (licenseData: LicenseData): Promise<BaseApiResponse> => {
    return httpRequest({
      url: `/api/accountmanagement/license/`,
      method: "POST",
      data: licenseData,
    });
  };

  static saveOrg = (orgData: OrgDataType): Promise<OrgType> => {
    return httpRequest({
      url: `/api/accountmanagement/organizations/${orgData.id}`,
      method: "PUT",
      data: orgData,
    });
  };

  static createOrg = (orgData: OrgDataType): Promise<OrgType> => {
    return httpRequest({
      url: `/api/accountmanagement/organizations`,
      method: "POST",
      data: orgData,
    });
  };

  static deletePartners = (
    orgId: string,
    body: DeleteBody
  ): Promise<OrgType> => {
    return httpRequest({
      url: `api/accountmanagement/organizations/${orgId}/partners/`,
      method: "DELETE",
      data: body,
    });
  };

  static getPartners = (
    orgData: OrgDataType,
    params: IDataTableParams
  ): Promise<PartnersResponse> => {
    return httpRequest({
      url: `/api/accountmanagement/organizations/${orgData.id}/partners/`,
      method: "GET",
      params: params,
    });
  };

  static matchPartner = (
    orgData: OrgDataType,
    sellerId: string
  ): Promise<OrgType> => {
    return httpRequest({
      url: `/api/accountmanagement/organizations/${orgData.id}/partners/${sellerId}/`,
      method: "POST",
    });
  };

  static deleteDispositions = (
    dispKeys: string[],
    dispObj: OrgDispositionType
  ): OrgDispositionType => {
    const clonedOrgDisps = { ...dispObj };
    dispKeys.forEach((key) => {
      delete clonedOrgDisps[key];
    });
    return clonedOrgDisps;
  };

  static getOrgUsers = (
    orgData: OrgDataType,
    params: IDataTableParams
  ): Promise<UsersDataType> => {
    return httpRequest({
      url: `/api/accountmanagement/organizations/${orgData.id}/users`,
      method: "GET",
      params: params,
    });
  };

  static createEmptyOrgDataType = (): OrgDataType => {
    return {
      id: "",
      shortId: "",
      name: "",
      shortName: "",
      description: "",
      crmAccountIds: [],
      subscriptionId: "",
      isAgency: false,
      isDAPActive: false,
      dispositionMap: {},
      advertiser: {
        advertiserId: "",
        advertiserName: "",
        success: true,
        message: "No Advertiser details found."
      }
    };
  };

  static transformSalesforceIdStrToArray = (idStr: string): string[] => {
    const newIds = [];
    const tempIds = idStr.split(",");
    for (let i = 0; i < tempIds.length; i++) {
      const trimmedVal = tempIds[i].trim();
      if (trimmedVal) {
        newIds.push(trimmedVal);
      }
    }
    return newIds;
  };

  static deleteUsersFromOrg = (
    orgData: OrgDataType,
    body: DeleteBody
  ): Promise<UserType> => {
    return httpRequest({
      url: `api/accountmanagement/organizations/${orgData.id}/users/`,
      method: "DELETE",
      data: body,
    });
  };

  static transformOrgDataForSaving = (
    orgId: string,
    shortId: string,
    orgName: string,
    shortName: string,
    orgDesc: string,
    zuoraId: string,
    salesforceIdsStr: string,
    isAgency: boolean,
    isDAPActive: boolean,
    orgDispositions?: OrgDispositionType
  ): OrgDataType => {
    const transformed: OrgDataType = {
      id: orgId || "",
      shortId: shortId || "",
      name: orgName || "",
      shortName: shortName || "",
      description: orgDesc || "",
      dispositionMap: orgDispositions || {},
      isAgency: typeof isAgency === "boolean" ? isAgency : false,
      isDAPActive: typeof isDAPActive === "boolean" ? isDAPActive : false,
      subscriptionId: zuoraId || "",
      crmAccountIds: [],
    };

    transformed.crmAccountIds =
      OrgService.transformSalesforceIdStrToArray(salesforceIdsStr);

    return transformed;
  };

  static searchRows = (
    data: IParsedDataTableRow[],
    value: string
  ): IParsedDataTableRow[] => {
    return data.filter(function (row) {
      let hasSearchValue = false;
      if (row && row.columns) {
        for (const column of row.columns) {
          if (
            String(column.value).toLowerCase().includes(value.toLowerCase())
          ) {
            hasSearchValue = true;
          }
        }
      }
      return hasSearchValue;
    });
  };

  static searchOrgData = (
    data: OrgDataType[],
    value: string
  ): OrgDataType[] => {
    return data.filter(function (org: OrgDataType) {
      let hasSearchValue = false;
      if (org) {
        if (org.name && org.name.toLowerCase().includes(value.toLowerCase())) {
          hasSearchValue = true;
        }
        if (org.shortName && org.shortName.toLowerCase().includes(value.toLowerCase())) {
          hasSearchValue = true;
        }
        if (org.shortId && org.shortId.toLowerCase().includes(value.toLowerCase())) {
          hasSearchValue = true;
        }
        if (org.description && org.description.toLowerCase().includes(value.toLowerCase())) {
          hasSearchValue = true;
        }
      }
      return hasSearchValue;
    });
  };

  static findByPropertyName = (
    columns: IDataTableColumn[],
    key: string
  ): any => {
    for (const column of columns) {
      if (String(column.id).toLowerCase() === key.toLowerCase()) {
        return column.value;
      }
    }
    return "";
  };

  static sort = (
    data: IParsedDataTableRow[],
    tableParams: IDataTableParams
  ): IParsedDataTableRow[] => {
    let newData = [...data];
    if (tableParams.sortOrder === "desc") {
      newData = newData.sort((a, b) =>
        OrgService.findByPropertyName(a.columns, tableParams.sortBy) >
        OrgService.findByPropertyName(b.columns, tableParams.sortBy)
          ? -1
          : 1
      );
    } else {
      newData = newData.sort((a, b) =>
        OrgService.findByPropertyName(a.columns, tableParams.sortBy) >
        OrgService.findByPropertyName(b.columns, tableParams.sortBy)
          ? 1
          : -1
      );
    }
    return newData;
  };

  static tableHeaderColumns: IDataTableHeader[] = [
    {
      label: "Name",
      id: "Name",
      hideDisabled: true
    },
    {
      label: "Short Name",
      id: "ShortName",
    },
    {
      label: "Description",
      id: "Description",
    },
    {
      label: "ID",
      id: "Id",
      disableSort: true,
    },
  ];


  static renderOrgName = (org: OrgDataType, enableLink?: boolean): string | ReactElement => {
    if (enableLink) {
      return (
        <StyledLink to={`/orgs/${org.id}`}>
        {org.name}
        </StyledLink>
      )
    } else {
      return org.name;
    }
  }

  static renderOrgId = (org: OrgDataType, enableLink?: boolean): string | ReactElement => {
    if (enableLink) {
      return (
        <StyledLink to={`/orgs/${org.id}`}>
        {org.id}
        </StyledLink>
      )
    } else {
      return org.id;
    }
  }

  static transformOrgsToRows = (
    results: OrgsType,
    selectedOrgs: OrgDataType[],
    onEdit?: (id: string) => void,
    onChangeClaims?: (id: string) => void,
    onDelete?: (id: string) => void
  ): IParsedDataTableRow[] => {
    return OrgService.transformOrgDataTypeArrayIntoRows(
      results.organizations,
      selectedOrgs,
      onEdit,
      onChangeClaims,
      onDelete
    );
  };

  static transformOrgDataTypeArrayIntoRows = (
    orgs: OrgDataType[],
    selectedOrgs: OrgDataType[],
    onEdit?: (id: string) => void,
    onChangeClaims?: (id: string) => void,
    onDelete?: (id: string) => void,
  ): IParsedDataTableRow[] => {
    if (orgs.length == 0) {
      return [];
    }

    const parsedData: IParsedDataTableRow[] = orgs.map(
      (org: OrgDataType) => {
        const actionsArray: IDataTableRowAction[] = [];
        onEdit && actionsArray.push({
          label: "Edit",
          action: () => onEdit(org.id),
        })
        onChangeClaims && actionsArray.push({
          label: "Edit Claims",
          action: () => onChangeClaims(org.id),
        })
        onDelete && actionsArray.push({
          label: "Delete",
          action: () => onDelete(org.id),
        })

        const isChecked = TableHelper.checkIdExistsInArray(org.id, selectedOrgs);
        return OrgService.createDataTableRow(org, actionsArray, isChecked, !!onEdit);
      }
    );
    return parsedData;
  };

  static createDataTableRow = (
    org: OrgDataType,
    actionArray: IDataTableRowAction[],
    isChecked: boolean,
    showLink = false
  ): IParsedDataTableRow => {
    return {
      id: org.id,
      actions: actionArray,
      isDefaultChecked: isChecked,
      columns: [
        {
          id: "Name",
          value: OrgService.renderOrgName(org, showLink),
        },
        {
          id: "ShortName",
          value: org.shortName,
        },
        {
          id: "Description",
          value: org.description,
        },
        {
          id: "Id",
          value: OrgService.renderOrgId(org, showLink),
        },
      ],
    };
  };
}
