import { httpRequest } from "./api";
import { IDataTableHeader, IDataTableParams, IParsedDataTableRow } from "../../dataTable/DataTable";
import { BaseApiResponse } from "../Types";
import { OrgDataType, OrgsDataType, PermittedMarketerType } from "./OrgService";
import { StyledLink } from "../../styledLink/StyledLink";
import { ReactElement } from "react";
import { TableHelper } from "../helpers/TableHelper";

export type UserContact = {
  firstName: string;
  lastName: string;
  email: UserEmailAddress[];
  address: any[];
};

export type UserEmailAddress = {
  address: string;
  attributes: any;
  isPrimary: boolean;
  label: string;
};

export type OrgClaim = {
  claims: string[];
  organizationId?: string;
  organization: OrgsDataType;
};

export type UserDataType = {
  description: string;
  id: string;
  userName: string;
  contact: UserContact | null;
  organizationClaims: OrgClaim[];
  permittedMarketers: PermittedMarketerType[];
};

export type UserType = BaseApiResponse & {
  user: UserDataType | null;
};

export type UsersDataType = BaseApiResponse & {
  users: UserDataType[];
  skip: number;
  sortBy: string;
  sortOrder: string;
  take: number;
  totalCount: number;
};

export type DeletePermittedMarketersRequest = {
  userId: string;
  permittedMarketerIds: string[];
};

export type AddPermittedMarketersRequest = DeletePermittedMarketersRequest;

export type CreateUserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  organizationId: string;
  isAdmin: boolean;
  isBuyer: boolean;
  isSeller: boolean;
  isAgency: boolean;
};

export type CreateUserResponse = CreateUserRequest;

export type AddPermittedMarketersResponse = {
  success: boolean,
  messages: string[]
}

export class UsersService {
  static readonly BASE_URL = "/api/accountmanagement/users/";

  static getUsers = (params: IDataTableParams): Promise<UsersDataType> => {
    return httpRequest({
      url: UsersService.BASE_URL,
      method: "GET",
      params: params,
    });
  };

  static getUser = (userId: string): Promise<UserType> => {
    return httpRequest({
      url: `/api/accountmanagement/users/${userId}`,
      method: "GET",
      params: {},
    });
  };

  static createUser = (params: CreateUserRequest): Promise<CreateUserResponse> => {
    return httpRequest({
      url: UsersService.BASE_URL,
      method: "POST",
      data: params,
    });
  };

  static addPermittedMarketers = (request: AddPermittedMarketersRequest): Promise<AddPermittedMarketersResponse> => {
    return httpRequest({
      url: UsersService.BASE_URL + `${request.userId}/permittedmarketers`,
      method: "POST",
      data: request.permittedMarketerIds,
  })}

  static deletePermittedMarketers = (request: DeletePermittedMarketersRequest): Promise<UserType> => {
    return httpRequest({
      url: UsersService.BASE_URL + `${request.userId}/permittedmarketers`,
      method: "DELETE",
      data: request.permittedMarketerIds,
    });
  };

  static getNumberOfOrgs = (user: UserDataType): number => {
    return user.organizationClaims.length;
  };

  static getNumberOfClaims = (user: UserDataType, orgId: string): ReactElement => {
    let numOfClaims = 0;
    const orgClaim = user.organizationClaims.find((oc) => oc.organizationId === orgId);
    let claims = "";
    if (orgClaim) {
      numOfClaims = orgClaim.claims.length;
      claims = orgClaim.claims.join(", ");
    }

    return <div title={claims}>{numOfClaims}</div>;
  };

  static getFirstName = (user: UserDataType): string => {
    return user.contact?.firstName ? user.contact.firstName : "";
  };

  static getLastName = (user: UserDataType): string => {
    return user.contact?.lastName ? user.contact.lastName : "";
  };

  static tableHeaderColumns: IDataTableHeader[] = [
    {
      label: "Email Address",
      id: "Email",
    },
    {
      label: "First Name",
      id: "FirstName",
    },
    {
      label: "Last Name",
      id: "LastName",
    },
    {
      label: "# of Orgs",
      id: "NumOfOrgs",
      disableSort: true,
    },
  ];

  static tableHeaderColumnsWithClaims: IDataTableHeader[] = [
    {
      label: "Email Address",
      id: "Email",
    },
    {
      label: "First Name",
      id: "FirstName",
    },
    {
      label: "Last Name",
      id: "LastName",
    },
    {
      label: "# of Claims",
      id: "NumOfClaims",
      disableSort: true,
    },
  ];

  static renderEmail = (user: UserDataType, enableLink?: boolean): string | ReactElement => {
    if (enableLink) {
      return <StyledLink to={`/users/${user.id}`}>{user.userName}</StyledLink>;
    } else {
      return user.userName;
    }
  };

  static searchUserData = (data: UserDataType[], value: string): UserDataType[] => {
    return data.filter(function (user: UserDataType) {
      let hasSearchValue = false;
      if (user) {
        if (user.userName && user.userName.toLowerCase().includes(value.toLowerCase())) {
          hasSearchValue = true;
        }
        if (user.contact?.firstName && user.contact?.firstName.toLowerCase().includes(value.toLowerCase())) {
          hasSearchValue = true;
        }
        if (user.contact?.lastName && user.contact?.lastName.toLowerCase().includes(value.toLowerCase())) {
          hasSearchValue = true;
        }
      }
      return hasSearchValue;
    });
  };

  static transformUsersToRows = (
    results: UsersDataType,
    selectedUsers: UserDataType[],
    onEdit?: (id: string) => void,
    onChangeClaims?: (user: UserDataType) => void,
    onDelete?: (user: UserDataType) => void,
    orgId?: string
  ): IParsedDataTableRow[] => {
    if (results && results.users) {
      const users = results.users;
      const parsedData: IParsedDataTableRow[] = users.map((user: UserDataType) => {
        const actionsArray = [];
        onEdit &&
          actionsArray.push({
            label: "Edit",
            action: () => onEdit(user.id),
          });
        onChangeClaims &&
          actionsArray.push({
            label: "Edit Claims",
            action: () => onChangeClaims(user),
          });
        onDelete &&
          actionsArray.push({
            label: "Unassign",
            action: () => onDelete(user),
          });

        const isChecked = TableHelper.checkIdExistsInArray(user.id, selectedUsers);
        const parsedRow: IParsedDataTableRow = {
          id: user.id,
          actions: actionsArray,
          isDefaultChecked: isChecked,
          columns: [
            {
              id: "Email",
              value: UsersService.renderEmail(user, !!onEdit),
            },
            {
              id: "FirstName",
              value: UsersService.getFirstName(user),
            },
            {
              id: "LastName",
              value: UsersService.getLastName(user),
            },
          ],
        };
        if (orgId) {
          parsedRow.columns.push({
            id: "NumOfClaims",
            value: UsersService.getNumberOfClaims(user, orgId),
          });
        } else {
          parsedRow.columns.push({
            id: "NumOfOrgs",
            value: UsersService.getNumberOfOrgs(user),
          });
        }

        return parsedRow;
      });
      return parsedData;
    }
    return [];
  };

  static transformOrgClaimToData = (orgClaims: OrgClaim[] | undefined): OrgDataType[] => {
    const orgs: OrgDataType[] = [];
    if (orgClaims) {
      orgClaims.forEach((orgClaim: OrgClaim) => {
        const org: OrgDataType = {
          ...orgClaim.organization,
          crmAccountIds: [],
        };
        orgs.push(org);
      });
    }
    return orgs;
  };
}
