import styled from 'styled-components';

const NotificationWrapper = styled.div`
    display: grid;
    min-width: 320px;
    width: auto;
    grid-row-gap: 16px;
    position: absolute;
    bottom: 24px;
    box-sizing: border-box;
`;

export { NotificationWrapper };
