/* eslint-disable */
import styled, { css, keyframes } from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";

interface IWrapperProps {
  $width: string;
}

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  z-index: unset;
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
`;
interface IPopoverProps {
  $active: boolean;
}

const Animation = keyframes`
  0% {
    opacity: 0;
    display: none;
  }
  20% {
    display: block;
  }
  100% {
    opacity: 1;
  }
`;

const Popover = styled.div<IPopoverProps>`
  display: none;
  z-index: 1;
  width: 300px;
  height: 304px;
  background: ${Styles.colors.white};
  box-shadow: 0px 1px 10px #00000033;
  border-radius: ${Styles.borderRadius.s};
  transform: scale(0);
  transition: 0.2s;
  opacity: 0;
  position: absolute;

  ${({ $active }) =>
    $active &&
    css`
      animation-name: ${Animation};
      animation-iteration-count: 1;
      animation-duration: 0.3s;
      animation-direction: alternate;
      display: block;
      opacity: 1;
      transform: scale(1);
    `}

  padding: 24px;
  box-sizing: border-box;
`;

const PopoverHeader = styled.div`
  display: grid;
  grid-template-columns: 30px auto 30px;
`;

const PopoverMonth = styled.div`
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.m1};
  justify-self: center;
  align-self: center;
  color: ${Styles.colors.text.darkPrimary};
`;

const PopoverDay = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-top: 16px;
  grid-column-gap: 8px;
  text-align: center;
  text-transform: uppercase;
  font-size: ${Styles.fontSizes.s1};
`;

const DayHeader = styled.div`
  font-family: ${Styles.fonts.primary};
  color: ${Styles.colors.text.darkPlaceholder};
  padding-bottom: 8px;
`;

const PopoverBody = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-template-rows: repeat(5, 30px);
  grid-row-gap: 3px;
`;

interface IPopoverBodyItemProps {
  $gridColumn?: number;
  $notCurrentMonth?: boolean;
}

const PopoverBodyItem = styled.div<IPopoverBodyItemProps>`
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.s2};
  text-align: center;

  ${({ $gridColumn }) =>
    $gridColumn &&
    css`
      grid-column: ${$gridColumn};
    `}

  ${({ $notCurrentMonth }) =>
    $notCurrentMonth &&
    css`
      color: ${Styles.colors.neutral300};
    `}
`;

interface IDateButtonProps {
  $notCurrentMonth?: boolean;
  $isActive?: boolean;
}

const DateButton = styled.button<IDateButtonProps>`
  border: 0;
  height: 32px;
  width: 32px;
  background-color: ${Styles.colors.white};
  border-radius: ${Styles.borderRadius.s};
  cursor: pointer;
  transition: all 70ms ease;
  &:hover,
  &:focus {
    background: ${Styles.colors.blue500};
    color: ${Styles.colors.white};
  }

  ${({ $notCurrentMonth }) =>
    $notCurrentMonth &&
    css`
      color: ${Styles.colors.neutral300};
      &:hover,
      &:focus {
        color: ${Styles.colors.neutral500};
        background-color: ${Styles.colors.white};
      }
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${Styles.colors.blue500};
      color: ${Styles.colors.white};
    `}
`;

const IconButton = styled.button`
  padding: 5px 10px;
  border-radius: ${Styles.borderRadius.s};
  display: grid;
  align-items: center;
  justify-content: center;
  background: ${Styles.colors.neutral100};
  color: ${Styles.colors.text.darkSecondary};
  border: 0;
  justify-self: center;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${Styles.colors.neutral300};
  }
`;

export {
  Wrapper,
  Popover,
  PopoverHeader,
  PopoverMonth,
  PopoverDay,
  DayHeader,
  PopoverBody,
  PopoverBodyItem,
  DateButton,
  IconButton,
};
