import { FunctionComponent, ReactElement } from "react";
import { H1, FormLabel } from "../shared/StyledComponents";
import styled from "styled-components";
import { Styles } from "@integrate/hedgehogger";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface IPageHeaderProps {
  title: string;
  subtitle?: string;
  returnToLabel?: string;
  returnToLink?: string;
}

const Spacer = styled.span`
  height: 12px;
  display: block;
`;

const HeaderWrapper = styled.div`
display: grid;
grid-template-columns: 75% auto;
align-items: center;
justify-items: start;
margin: 4px 0;
text-align: start;

button {
  justify-self: end;
}
`;

const ReturnLink = styled.span`
height: 12px;
display: block;
& > a {
  align-items: center;
  color: ${Styles.colors.neutral700};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.07s ease;

  &:hover {
    color: ${Styles.colors.blue500};
  }
`;

export const PageHeader: FunctionComponent<IPageHeaderProps> = ({
  title,
  subtitle = "",
  returnToLabel = "",
  returnToLink = "",
}): ReactElement => {

  return (
    <HeaderWrapper>
      <div>
        {returnToLabel && returnToLink ? (
          <ReturnLink>
            <Link to={returnToLink}>
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp; RETURN TO {returnToLabel}
            </Link>
          </ReturnLink>
        ) : (
          <Spacer></Spacer>
        )}
        <H1>{title}</H1>
        <FormLabel>{subtitle}</FormLabel>
      </div>
    </HeaderWrapper>
  );
};
