import { FunctionComponent, ReactElement, useState } from 'react';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import {Styles} from '@integrate/hedgehogger';

const Wrapper = styled.li`
  padding : 12px 0;
  position: relative;
  transition: all 0.2s ease;

  & > a {
    display: block;
    text-decoration: none;
  }
`;

const TabIcon = styled.div<{ isHovered: boolean }>`
  color: #8d99aa;
  font-size: 1.5rem;

  ${({ isHovered }) =>
  isHovered &&
  `
    color: ${Styles.colors.blue500};
  `}
`;

const TabText = styled.div<{ isHovered: boolean }>`
  color: white;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 4px 0;

  ${({ isHovered }) =>
  isHovered &&
  `
    color: ${Styles.colors.blue500};
  `}
`;

const TabIndicator = styled.div`
  align-items: center;
  color: white;
  display: flex;
  font-size: 1.5rem;
  height: 100%;
  position: absolute;
  right: -2px;
  top: 0px;
`

interface INavItemProps {
  path: string,
  label: string,
  icon: IconDefinition,
  isSelected: boolean,
}

export const NavItem : FunctionComponent<INavItemProps> =  ({
  path,
  label,
  icon,
  isSelected
}) : ReactElement => {
  const [isHovered, setHovered] = useState(false);

  const handleHover = (hoverState: boolean) => () => {
    setHovered(hoverState);
  };

    const ariaTabIndicator = `Current tab: ${label}`;
    const iconTitle = label + " Icon";
    return (
      <Wrapper>
        <NavLink
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
          to={path}>
          <TabIcon isHovered={isHovered}>
            <FontAwesomeIcon title={iconTitle} icon={icon} />
          </TabIcon>
          <TabText isHovered={isHovered}>{label}</TabText>
          {isSelected &&
            <TabIndicator aria-label={ariaTabIndicator}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </TabIndicator>
          }
        </NavLink>
      </Wrapper>
    );
};
