/* eslint-disable */
import styled, { css } from "styled-components";
import { Styles } from "../../../utils/styles/designTokens/styles";

interface IWrapperProps {
  $width?: string;
  $isFixed?: boolean;
  $leftPosition: number;
  $rightBorder?: boolean;
  $isSelectable?: boolean;
  $hasSort?: boolean;
  $isLoading: boolean;
}

const Wrapper = styled.th<IWrapperProps>`
  position: relative;
  background-color: ${Styles.colors.neutral100};
  padding: 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #0000008a; //TODO: find this colour
  font-size: 11px;
  font-family: ${Styles.fonts.primary};
  width: auto;
  position: sticky;
  top: 0;
  z-index: 1;
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
  ${({ $isFixed, $isSelectable, $leftPosition }) =>
    $isFixed &&
    css`
      z-index: 2;
      left: ${$isSelectable
        ? $leftPosition + 49
        : $leftPosition
        ? $leftPosition
        : 0}px;
    `}
      ${({ $rightBorder }) =>
    $rightBorder &&
    css`
      &:after {
        height: calc(100% + 1px);
        content: "";
        width: 10px;
        background: transparent
          linear-gradient(90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        right: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
    `}
    ${({ $hasSort, $isLoading }) =>
    $hasSort &&
    !$isLoading &&
    css`
      cursor: pointer;
      user-select: none;
      transition: 0.4s;
      &:hover {
        background: ${Styles.colors.neutral300};
      }
    `}
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface IIconWrapper {
  $isActive: boolean;
  $isLoading: boolean;
}

const SortIconWrapper = styled.div<IIconWrapper>`
  grid-column: 1;
  user-select: none;
  font-size: ${Styles.fontSizes.s3};
  margin-left: 10px;
  color: ${Styles.colors.black};
  ${({ $isLoading }) =>
    !$isLoading &&
    css`
      cursor: pointer;
    `}
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${Styles.colors.neutral900};
    `}
`;

export { Wrapper, LabelWrapper, SortIconWrapper };
