/* eslint-disable */
import React, {
  FunctionComponent,
  Fragment,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  Background,
  Body,
  ButtonContainer,
  Container,
  CustomFontAwesomeIcon,
  Footer,
  FooterContentContainer,
  Header,
  IconButton,
  SubTitle,
  Title,
  Wrapper,
} from "./styles";

export interface IModalProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  title: string;
  subTitle?: string;
  active?: boolean;
  children?: ReactNode;
  buttons?: ReactNode[];
  /** @deprecated */
  footerContent?: ReactNode | string;
  minHeight?: string;
  width?: string;
  maxWidth?: string;
  closeOnBlur?: boolean;
  minWidth?: string;
  closeCallback?: () => void;
}

export const Modal: FunctionComponent<IModalProps> = ({
  id,
  className,
  dataTestId = "modal",
  title,
  subTitle,
  active = false,
  children,
  buttons,
  footerContent,
  minHeight = "186px",
  closeOnBlur,
  minWidth = "600px",
  closeCallback,
  width,
  maxWidth,
}) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const handleClose = () => {
    setIsActive(false);
    closeCallback && closeCallback();
  };

  const handleBlur = () => {
    closeOnBlur && handleClose();
  };

  return (
    <Fragment>
      <Container $active={isActive}>
        <Wrapper
          id={id}
          aria-modal={true}
          role="dialog"
          className={className}
          $minHeight={minHeight}
          $minWidth={minWidth}
          $width={width}
          $maxWidth={maxWidth}
          data-testid={dataTestId}
        >
          <Header>
            <Title>{title}</Title>
            <IconButton
              data-testid="close-button"
              aria-label="close modal"
              onClick={handleClose}
            >
              <CustomFontAwesomeIcon size="xs" icon={faTimes} />
            </IconButton>
          </Header>
          {subTitle && <SubTitle data-testid="subtitle">{subTitle}</SubTitle>}
          <Body>{children}</Body>
          <Footer>
            <FooterContentContainer>{footerContent}</FooterContentContainer>
            <ButtonContainer>{buttons}</ButtonContainer>
          </Footer>
        </Wrapper>
      </Container>
      <Background
        $active={isActive}
        onClick={handleBlur}
        data-testid="modal-background"
      />
    </Fragment>
  );
};
