/* eslint-disable */
import styled, { css } from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";

interface IIconWrapperProps {
  $opacity?: number;
  $padding?: string;
  $fontSize?: string;
  $clickable?: boolean;
}

const IconWrapper = styled.span<IIconWrapperProps>`
  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `}

  ${({ $clickable }) =>
    $clickable &&
    css`
      &:hover {
        background: ${Styles.colors.neutral50};
        border-radius: ${Styles.borderRadius.s};
        cursor: pointer;
      }
    `}
  ${({ $fontSize }) =>
    $fontSize &&
    css`
      font-size: ${$fontSize};
    `}
`;

export { IconWrapper };
