/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHover } from "../../hooks/useHover";
import { Icon } from "../Icon/Icon";
import {
  ActiveWrapper,
  IconWrapper,
  InactiveActiveWrapper,
  InputWrapper,
  ValueWrapper,
  Wrapper,
} from "./styles";

export interface IInlineEditProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  value: string;
  onSave: (value: string) => void;
  width?: string;
}

export const InlineEdit: FunctionComponent<IInlineEditProps> = ({
  id,
  className,
  dataTestId = "inlineEdit",
  value,
  width = "25vw",
  onSave,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const { isHovered, handleHover, setHover } = useHover();

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const cancelInput = () => {
    setNewValue(value);
    setIsEditable(false);
    setHover(false);
  };

  const confirmInput = () => {
    setIsEditable(false);
    const trimmedValue = newValue.trim();

    if (trimmedValue.length > 0 && trimmedValue !== value) {
      onSave(trimmedValue);
      setNewValue(trimmedValue);
      setHover(false);
    } else {
      cancelInput();
    }
  };

  const makeEditable = () => {
    setNewValue(newValue);
    setIsEditable(true);
  };

  const handleKeyDown = (handleKey: () => void, keys: string[]) => (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (keys.find((key) => key === event.key)) {
      handleKey();
    }
  };

  const handleMakeEditable = () => {
    makeEditable();
  };

  const handleConfirmInput = () => {
    confirmInput();
  };

  const handleOnChange = (e) => {
    setNewValue(e.target.value);
  };

  return (
    <Wrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      onClick={handleMakeEditable}
    >
      {isEditable ? (
        <ActiveWrapper>
          <InputWrapper
            data-testid={`${dataTestId}Input`}
            $width={width}
            onBlur={handleConfirmInput}
            value={newValue}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown(confirmInput, ["Enter", "Escape"])}
          ></InputWrapper>
        </ActiveWrapper>
      ) : (
        <InactiveActiveWrapper
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
          onFocus={handleHover(true)}
          onBlur={handleHover(false)}
          tabIndex={0}
          onKeyDown={handleKeyDown(makeEditable, ["Enter"])}
        >
          <ValueWrapper
            data-testid={`${dataTestId}Value`}
            $width={width}
            $isHovered={isHovered}
          >
            {newValue}
          </ValueWrapper>
          <IconWrapper $isHovered={isHovered}>
            <Icon ariaLabel="Edit" name={"faEdit"} />
          </IconWrapper>
        </InactiveActiveWrapper>
      )}
    </Wrapper>
  );
};
