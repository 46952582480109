import { FunctionComponent, ReactElement, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { faBuilding, faUser, faUserLock } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import integrateLogo from "../media/images/integrate-logo-60px.png";
import { NavItem } from "../navitem/NavItem";
import { IdToken, useAuth0 } from "@auth0/auth0-react";

interface NavBarProps {
  idToken?: IdToken;
}

const Wrap = styled.nav`
  background-color: #00173a;
  display: flex;
  flex-direction: column;
`;

const HomeLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 22px 0;

  & > img {
    width: 50%;
  }
`;

const HomeUserWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 16px;

  & > button {
    width: 50%;
    border-radius: 50%;

  }

  & > img {
    width: 60%;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const Spacer = styled.div`
  flex: 100;
`;

const NavBody = styled.ul`
  color: #8d99aa;
  list-style-type: none;
  padding: 0;
  text-align: center;
  margin: 0;
`;

export const NavBar: FunctionComponent<NavBarProps> = ({ idToken }): ReactElement => {
  const [urlPath, setUrlPath] = useState("");
  const history = useHistory();
  const { isAuthenticated, logout } = useAuth0();

  // Fetches initial URL path. UseEffect() cannot listen to pathname changes for some reason.
  useEffect(() => {
    setUrlPath(window.location.pathname);
  }, []);

  // Listens to get the updated path name AFTER the first load.
  useEffect(() => {
    history.listen((location) => {
      setUrlPath(location.pathname);
    });
  }, [history]);

  const handleClickLogout = () => {
    localStorage.setItem('AUTH_TOKEN', '');
    logout({ returnTo: window.location.origin });
  };
  return (
    <Wrap>
      <NavLink to="/">
        <HomeLogoWrap>
          <img src={integrateLogo} alt="Integrate Logo" />
        </HomeLogoWrap>
      </NavLink>
      <NavBody>
        <NavItem
          path="/orgs"
          label="Orgs"
          icon={faBuilding}
          isSelected={urlPath === "/orgs"}
        ></NavItem>
        <NavItem
          path="/users"
          label="Users"
          icon={faUser}
          isSelected={urlPath === "/users"}
        ></NavItem>
        <NavItem
          path="/claims"
          label="Claims"
          icon={faUserLock}
          isSelected={urlPath === "/claims"}
        ></NavItem>
      </NavBody>
      <Spacer />
      {isAuthenticated && (
        <HomeUserWrap>
          <img
            title="Logout"
            onClick={handleClickLogout}
            src={idToken?.picture}
            alt={idToken?.name}
          ></img>
        </HomeUserWrap>
      )}
    </Wrap>
  );
};
