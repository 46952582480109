const getDisplayName = (
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined = ''
) => {
  let displayName = '';
  if (firstName && lastName) {
    displayName = `${firstName} ${lastName}`;
  } else if (firstName) {
    displayName = firstName;
  } else {
    displayName = email;
  }

  return displayName;
};

const getUserInitials = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  if (!firstName || !lastName) {
    return undefined;
  }

  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

export { getDisplayName, getUserInitials };
