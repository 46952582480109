/* eslint-disable */
import React, { FunctionComponent, ReactElement } from "react";
import { Container, BadgeGridContainer } from "./styles";

interface BaseBadgeProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  label: string;
  color?: string;
  backgroundColor?: string;
  small?: boolean;
}

interface StartIconBadgeProps {
  startIcon?: ReactElement;
  endIcon?: never;
}

interface EndIconBadgeProps {
  endIcon?: ReactElement;
  startIcon?: never;
}

export type BadgeProps = (StartIconBadgeProps | EndIconBadgeProps) &
  BaseBadgeProps;

export const Badge: FunctionComponent<BadgeProps> = ({
  id,
  className,
  dataTestId = "badge",
  label,
  color,
  backgroundColor,
  small = false,
  startIcon,
  endIcon,
}) => {
  return (
    <Container
      id={id}
      className={className}
      data-testid={dataTestId}
      $color={color}
      $backgroundColor={backgroundColor}
      $small={small}
      $status={label}
    >
      {!small && !!startIcon && startIcon}
      {label}
      {!small && !!endIcon && endIcon}
    </Container>
  );
};

interface BadgeGridProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  colors: string[];
}

export const BadgeGrid: FunctionComponent<BadgeGridProps> = ({
  id,
  className,
  dataTestId = "badge-grid",
  colors,
}) => {
  return (
    <BadgeGridContainer id={id} className={className} data-testid={dataTestId}>
      {colors.map((color) => (
        <Badge label="badge" backgroundColor={color} />
      ))}
    </BadgeGridContainer>
  );
};
