/* eslint-disable */
import React, { FunctionComponent } from "react";
import { Wrapper, Text } from "./styles";

export const EmptyTableTemplate: FunctionComponent = () => {
  return (
    <Wrapper>
      <Text>No items to show</Text>
    </Wrapper>
  );
};
