import { Badge, Styles } from '@integrate/hedgehogger';
import { IRadioOption } from '@integrate/hedgehogger/lib/components/RadioButtonGroup/RadioButtonGroup';
import { ReactElement } from 'react';
import { IDataTableHeader, IDataTableParams, IDataTableRowAction, IParsedDataTableRow } from '../../dataTable/DataTable';
import { ClaimActions } from '../components/SelectClaims/SelectClaims';
import { BaseApiResponse } from '../Types';
import { httpRequest } from './api';

export type ClaimDataType = {
	id: string;
	key: string;
	label: string;
	description: string;
	stage: string;
};

export type ClaimsDataType = BaseApiResponse & {
	claims: ClaimDataType[];
	skip: number;
	sortBy: string;
	sortOrder: string;
	take: number;
	totalCount: number;
};

export interface PostClaimBody {
	key: string;
	label: string;
	description: string;
	stage: string;
}

export interface DeleteClaimBody {
	id: string;
}

export interface DeleteOrgClaimsBody {
	organizationIds: string[];
	userIds: string[];
}

export interface SetOrgClaimsBody {
	organizationIds: string[];
	userIds: string[];
	claims: string[];
	action: ClaimActions;
}

export type ClaimsType = BaseApiResponse & {
	claims: ClaimDataType[];
	skip: number;
	sortBy: string;
	sortOrder: string;
	take: number;
	totalCount: number;
};

export class ClaimsService {
	// These are not returned by the claims service.
	static readonly NON_FEATURE_CLAIMS = ['User', 'Admin', 'Buyer', 'Agency', 'Seller'];

	static readonly GET_ALL_CLAIMS_PARAMS: IDataTableParams = {
		skip: 0,
		take: 10000,
		search: '',
		sortBy: 'Name',
		sortOrder: 'asc'
	};

	static getClaims = (params: IDataTableParams): Promise<ClaimsDataType> => {
		return httpRequest({
			url: `/api/accountmanagement/claims/`,
			method: 'GET',
			params: params
		});
	};

	static postClaim = (body: PostClaimBody): Promise<ClaimsDataType> => {
		return httpRequest({
			url: `/api/accountmanagement/claims`,
			method: 'POST',
			data: body
		});
	};

	static editClaim = (body: ClaimDataType): Promise<ClaimsDataType> => {
		return httpRequest({
			url: `/api/accountmanagement/claims/${body.id}`,
			method: 'PUT',
			data: body
		});
	};

	static deleteClaim = (id: string): Promise<ClaimsDataType> => {
		return httpRequest({
			url: `/api/accountmanagement/claims/${id}`,
			method: 'DELETE'
		});
	};

	static setOrgClaims = (body: SetOrgClaimsBody): Promise<ClaimsDataType> => {
		return httpRequest({
			url: `/api/accountmanagement/users/orgclaims`,
			method: 'PUT',
			data: body
		});
	};

	static deleteOrgClaims = (body: DeleteOrgClaimsBody): Promise<ClaimsDataType> => {
		return httpRequest({
			url: `/api/accountmanagement/users/orgclaims`,
			method: 'DELETE',
			data: body
		});
	};

	static generateClaimBadge = (stage: string): ReactElement => {
		let color;
		switch (stage) {
			case 'Release':
				color = Styles.colors.green500;
				break;
			case 'Beta':
				color = Styles.colors.purple500;
				break;
			case 'Internal':
				color = Styles.colors.orange500;
				break;
			default:
				color = Styles.colors.blue500;
				break;
		}

		return <Badge label={stage} backgroundColor={color}></Badge>;
	};

	static transformClaimsToRows = (
		results: ClaimsDataType,
		onEdit: (claim: ClaimDataType) => void,
		onDelete: (claim: ClaimDataType) => void,
    hasAccessToManageClaims?: boolean
	): IParsedDataTableRow[] => {
		if (results) {
			const claims = results.claims;

			const getParsedRow = (claim: ClaimDataType): IDataTableRowAction[] => {
				return hasAccessToManageClaims
					? [
							{
								label: 'Edit',
								action: () => onEdit(claim),
								dataTestId: 'editClaimAction'
							},
							{
								label: 'Delete',
								action: () => onDelete(claim),
								dataTestId: 'deleteClaimAction'
							}
					]
					: [];
			};

			const parsedData: IParsedDataTableRow[] = claims.map((claim: ClaimDataType, index: number) => {
				const parsedRow: IParsedDataTableRow = {
					id: (index + 1).toString(),
					actions: getParsedRow(claim),
					columns: [
						{
							id: 'Key',
							value: claim.key
						},
						{
							id: 'Label',
							value: claim.label
						},
						{
							id: 'Description',
							value: claim.description ? claim.description : ''
						},
						{
							id: 'Stage',
							value: claim.stage?.trim() ? ClaimsService.generateClaimBadge(claim.stage) : ''
						}
					]
				};

				return parsedRow;
			});
			return parsedData;
		}
		return [];
	};

	static claimActionOptions: IRadioOption[] = [
		{ id: 'add', label: 'Add Claims' },
		{ id: 'replace', label: 'Replace Claims' },
		{ id: 'remove', label: 'Remove Claims' }
	];

	static tableHeaderColumns: IDataTableHeader[] = [
		{
			label: 'Key',
			id: 'Key'
		},
		{
			label: 'Label',
			id: 'Label'
		},
		{
			label: 'Description',
			id: 'Description'
		},
		{
			label: 'Stage',
			id: 'Stage',
			disableSort: true
		}
	];

	static buildSanitizedClaimsSet = (claimKeys: string[], validClaims = new Set<string>()): Set<string> => {
		const claimsSet = new Set<string>();
		claimKeys.forEach((k) => {
			if (validClaims.size > 0) {
				// Only add claims that are valid.
				if (validClaims.has(k)) {
					claimsSet.add(k);
				}
			} else {
				// If the valid claims is empty it is because the getClaims API either hasn't responded
				// yet or had failed. In this case, we will show all users' current claims without sanitizing.
				claimsSet.add(k);
			}
		});
		return claimsSet;
	};
}
