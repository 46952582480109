const typographyTokens = {
  fontFamily: {
    primary: 'Inter, Arial',
    // used for tabular tables
    mono: 'Roboto Mono',
  },
  fontSize: {
    h1: '28px',
    h2: '24px',
    h3: '22px',
    h4: '18px',
    xxs: '10px',
    xs: '12px',
    s: '14px',
    m: '16px', // (base)
    l: '18px',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
} as const;

export default typographyTokens;
