import styled from 'styled-components';
import { CSSProperties } from 'react';

interface OverviewBarContainerProps {
  $width: CSSProperties['width'];
}

const OverviewBarContainer = styled.div<OverviewBarContainerProps>`
  width: ${({ $width }) => $width};
`;

const DataBlockContainer = styled.div`
  display: flex;
`;

interface DataBlockTitleContainerProps {
  justifyTitles: CSSProperties['justifyContent'];
}

const DataBlockTitleContainer = styled.div<DataBlockTitleContainerProps>`
  display: flex;
  justify-content: ${({ justifyTitles }) => justifyTitles};
  flex-wrap: wrap;
`;

export { OverviewBarContainer, DataBlockContainer, DataBlockTitleContainer };
