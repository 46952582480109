import { FunctionComponent, useState, useEffect } from "react";
import { OrgService, PermittedMarketerType } from "../common/services/OrgService";
import {
  DataTable,
  IDataTableParams,
  IParsedDataTableRow,
  DefaultDataTableParams,
  TableSelection,
} from "../dataTable/DataTable";
import { ConfirmationModal } from "../common/components/ConfirmationModal/ConfirmationModal";
import { TableHelper } from "../common/helpers/TableHelper";
import { Button } from "@integrate/hedgehogger";
import { BulkModal, BulkModes } from "../common/components/BulkModal/BulkModal";
import { UserDataType } from "../common/services/UsersService";
import { DropdownItem } from "@integrate/hedgehogger";

interface PermittedMarketersProps {
  data: UserDataType;
  refreshData: (tabIndex: number) => void;
  onRemoveMarketers: (changedMarketers: PermittedMarketerType[]) => void;
}

export const PermittedMarketers: FunctionComponent<PermittedMarketersProps> = ({
  data,
  refreshData,
  onRemoveMarketers,
}) => {
  const [selectedMarketers, setSelectedMarketers] = useState<PermittedMarketerType[]>([]);
  const [tableData, setTableData] = useState<IParsedDataTableRow[]>([]);
  const [filteredData, setFilteredData] = useState<IParsedDataTableRow[]>([]);
  const [processedTableData, setProcessedTableData] = useState< IParsedDataTableRow[] >([]);
  const [tableParams, setTableParams] = useState(DefaultDataTableParams);
  const [showRemoveMarketersConfirmation, setShowRemoveMarketersConfirmation] = useState(false);
  const [bulkModalVisible, setBulkModalVisible] = useState(false);

  useEffect(() => {
    const parsedData = OrgService.transformOrgDataTypeArrayIntoRows(
      data.permittedMarketers,
      selectedMarketers,
      undefined,
      undefined,
      onRemoveSingleMarketerClicked,
    );
    setFilteredData(parsedData);
    setTableData(parsedData);
  }, [data, selectedMarketers]);

  useEffect(() => {
    transformTableData();
  }, [
    tableParams.search,
    tableParams.skip,
    tableParams.take,
    tableParams.sortBy,
    tableParams.sortOrder,
    tableData,
  ]);

  const transformTableData = (): void => {
    let newData = OrgService.searchRows(tableData, tableParams.search);
    setFilteredData(newData);
    newData = OrgService.sort(newData, tableParams);
    newData = newData.slice(tableParams.skip, tableParams.skip + tableParams.take);
    setProcessedTableData(newData);
  };

  const onRemoveSingleMarketerClicked = (marketerId: string): void => {
    const idx = data.permittedMarketers.findIndex((pm) => {
      return (pm.id === marketerId);
    });
    const marketerToRemove = data.permittedMarketers[idx];
    setSelectedMarketers([marketerToRemove]);
    setShowRemoveMarketersConfirmation(true);
  };

  const removePermittedMarketers = async (marketers: PermittedMarketerType[]) => {
    onRemoveMarketers(marketers);
  };

  const onPermittedMarketersSelected = (selections: TableSelection[]): void => {
    const newSelections = TableHelper.calcSelectedRows(selectedMarketers, selections, data.permittedMarketers);
    setSelectedMarketers(newSelections);
  };

  const handleTableParamChange = (newParams: IDataTableParams): void => {
    setTableParams(newParams);
  };

  const handleCancelRemoveMarketers = (): void => {
    setShowRemoveMarketersConfirmation(false);
    setSelectedMarketers([]);
  };

  const handleConfirmRemoveMarketers = (): void => {
    removePermittedMarketers(selectedMarketers);
    setShowRemoveMarketersConfirmation(false);
    setSelectedMarketers([]);
  };

  const generateConfirmRemoveText = (marketers: PermittedMarketerType[]): string => {
    let modalText = "";
    if (marketers.length == 1){
      modalText = `[${marketers[0].name}] will be removed from Permitted Marketers.`;
    } else {
      modalText = `${marketers.length} marketers will be removed from Permitted Marketers.`;
    }
    return modalText;
  };

  const handleShowBulkModal = () => {
    setBulkModalVisible(true);
  };

  const handleHideBulkModal = () => {
    setBulkModalVisible(false);
    refreshData(2);
  };

  const handleBulkAction = (bulkActionItem: DropdownItem) => {
    switch (bulkActionItem.id) {
      case "unassign":
        setShowRemoveMarketersConfirmation(true);
        break;
      default:
        break;
    }
  };

  const bulkOptions: DropdownItem[] = [
    {
      id: "unassign",
      value: "Unassign Marketers",
    },
  ];

  return (
    <div>
      <DataTable
        key={data.permittedMarketers.length + ""}
        button={
          <Button
            onClick={handleShowBulkModal}
            label="Add Marketer"
            minWidth="104px"
            width="70%"
            maxWidth="150px"
            dataTestId="addOrgButton"
          ></Button>
        }
        bulkActionOptions={{
          onBulkItemClicked: handleBulkAction,
          bulkActionItems: bulkOptions,
        }}
        name="Permitted Marketers"
        columns={OrgService.tableHeaderColumns}
        onSelectItems={onPermittedMarketersSelected}
        numSelectedItems={selectedMarketers.length}
        data={processedTableData}
        onChangeParams={handleTableParamChange}
        totalAmount={filteredData.length}
        popoverWidth="80px"
      />
      <ConfirmationModal
        label={generateConfirmRemoveText(selectedMarketers)}
        active={showRemoveMarketersConfirmation}
        onCancel={handleCancelRemoveMarketers}
        onConfirm={handleConfirmRemoveMarketers}
      />
      <BulkModal
        onCancel={handleHideBulkModal}
        selectedOrgs={[]}
        selectedUsers={[data]}
        selectedClaims={[]}
        currentStepKey={1}
        active={bulkModalVisible}
        mode={BulkModes.AddMarketers}
      />
    </div>
  );
};
