import styled from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  height: ${Styles.uno.dimensions.topNav};
  padding: 0px 16px 0px 24px;
  background: #283237;
  .user-profile {
    padding: 0;
    .avatar {
      &:hover {
        background-color: ${Styles.uno.color.core.secondary200};
        color: ${Styles.uno.color.text.darkPrimary};
        .avatar-icon {
          color: ${Styles.uno.color.text.darkPrimary};
        }
      }
    }
    &[aria-expanded="true"] {
      .avatar {
        background: ${Styles.uno.color.core.secondary300};
        &:hover {
          color: ${Styles.uno.color.text.lightPrimary};
        }
      }
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    text-decoration: none;
    margin-left: 24px;
    color: ${Styles.uno.color.text.lightPlaceholder};
    font-size: 13px;
    font-family: ${Styles.uno.fontFamily.primary};

    :first-child {
      margin-left: 0px;
    }

    span {
      display: none;
    }

    &:hover {
      color: ${Styles.uno.color.core.secondary100};
    }

    &.active {
      color: ${Styles.uno.color.core.secondary100};
      span {
        display: inline-block;
      }
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 16px;
`;

export { Container, List, ButtonsContainer };
