/* eslint-disable */
import { Styles } from "../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ErrorAndWarningProps {
  $hasError?: boolean;
  $hasWarning?: boolean;
}

interface WrapperProps {
  $maxWidth: string;
  $minWidth: string;
  $width: string;
  $formStyle: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ $maxWidth, $minWidth, $width, $formStyle }) =>
    css`
      max-width: ${$maxWidth};
      min-width: ${$minWidth};
      width: ${$width};
      ${$formStyle ? "display: grid" : ""};
    `}
`;

interface ButtonWrapperProps {
  disabled: boolean;
  $formStyle: boolean;
  $isOpen: boolean;
}

const ButtonWrapper = styled.button<ButtonWrapperProps & ErrorAndWarningProps>`
  position: relative;
  display: grid;
  grid-template-columns: auto 2fr auto;
  border-radius: ${Styles.uno.radius.xs};
  padding: 1px 12px;
  margin: 0;
  height: 38px;
  outline: 0;
  font-family: ${Styles.uno.fontFamily.primary};
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  background-color: ${Styles.uno.color.core.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${Styles.uno.color.neutral.warm300};
  transition: all 0.2s ease;
  user-select: none;

  ${({ $formStyle }) =>
    $formStyle &&
    css`
      grid-template-columns: 2fr auto;
      margin-top: 8px;
      height: 45px;
      border-width: 2px;
      width: 100%;
    `}

  &:hover {
    background-color: ${Styles.uno.color.neutral.warm100};
    ${({ $formStyle }) =>
      $formStyle &&
      css`
        background: 0;
        border-color: ${Styles.uno.color.accent.blue500};
      `}
  }

  &:focus {
    background-color: ${Styles.uno.color.neutral.warm100};

    ${({ $formStyle }) =>
      $formStyle &&
      css`
        background: 0;
        border: 2px solid ${Styles.uno.color.accent.blue500};
      `}
  }

  ${({ $isOpen, $formStyle }) =>
    $isOpen &&
    css`
      background-color: ${Styles.uno.color.neutral.warm100};

      ${$formStyle &&
      css`
        background: 0;
        border: 2px solid ${Styles.uno.color.accent.blue500};
      `}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${Styles.uno.color.neutral.warm100};
      border-color: ${Styles.uno.color.neutral.warm300};
      border: 1px solid ${Styles.uno.color.neutral.warm300};

      &:hover {
        background-color: ${Styles.uno.color.neutral.warm100};
        border-color: ${Styles.uno.color.neutral.warm300};
      }
    `}

    border-color: ${({ $hasError, $hasWarning }) =>
    $hasError
      ? Styles.uno.color.semantic.negative500
      : $hasWarning
      ? Styles.uno.color.semantic.warn500
      : ""};
`;

interface LabelWrapperProps {
  disabled?: boolean;
}

const LabelWrapper = styled.label<LabelWrapperProps & ErrorAndWarningProps>`
  text-transform: uppercase;
  font-family: ${Styles.uno.fontFamily.primary};
  font-size: ${Styles.uno.fontSize.xs};
  align-self: center;
  padding-top: 1px;
  margin: 0 8px 0 0;
  text-align: left;
  font-weight: 500;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ disabled, $hasError, $hasWarning }) =>
    disabled
      ? Styles.uno.color.neutral.warm300
      : $hasError
      ? Styles.uno.color.semantic.negative500
      : $hasWarning
      ? Styles.uno.color.semantic.warn500
      : Styles.uno.color.neutral.warm700};
`;

interface FormLabelWrapperProps {
  $isOpen: boolean;
  $maxWidth: string;
  $width: string;
  disabled?: boolean;
}

const FormLabelWrapper = styled.label<
  FormLabelWrapperProps & ErrorAndWarningProps
>`
  display: inline-block;
  font-family: ${Styles.uno.fontFamily.primary};
  color: ${Styles.uno.color.text.darkSecondary};
  font-size: ${Styles.uno.fontSize.s};
  text-align: left;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ $hasError, $hasWarning }) =>
    $hasError
      ? Styles.uno.color.semantic.negative500
      : $hasWarning
      ? Styles.uno.color.semantic.warn500
      : ""};

  &:focus {
    color: ${Styles.uno.color.accent.blue500};
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      color: ${Styles.uno.color.accent.blue500};
    `}

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
`;

interface ValueWrapperProps {
  $formStyle: boolean;
  $isPlaceholder: boolean;
}

const ValueWrapper = styled.span<ValueWrapperProps>`
  font-family: ${Styles.uno.fontFamily.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${Styles.uno.fontSize.s};
  align-self: center;
  text-align: end;
  justify-self: end;
  width: 100%;

  ${({ $isPlaceholder }) =>
    $isPlaceholder &&
    css`
      color: ${Styles.colors.text.darkPlaceholder};
    `}

  ${({ $formStyle }) =>
    $formStyle &&
    css`
      text-align: start;
      justify-self: start;
      margin-left: 4px;
      font-size: ${Styles.uno.fontSize.m};
    `}
`;

const IconWrapper = styled.span`
  transition: all 0.8s ease;
  opacity: 0.5;
  margin-left: 12px;
  align-self: center;
  justify-self: end;
`;

interface OptionsWrapperProps {
  $width?: string;
  $maxWidth?: string;
}

const OptionsWrapper = styled.div<OptionsWrapperProps>`
  position: relative;
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
`;

const HelperText = styled.p<ErrorAndWarningProps>`
  color: ${({ $hasError }) =>
    $hasError
      ? Styles.uno.color.semantic.negative500
      : Styles.uno.color.text.darkPrimary};
  font-size: ${Styles.uno.fontSize.xs};
  word-break: all;
  margin-top: 4px;
  font-family: ${Styles.uno.fontFamily.primary};
`;

const HelperIcon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`;

export {
  Wrapper,
  ButtonWrapper,
  LabelWrapper,
  FormLabelWrapper,
  ValueWrapper,
  IconWrapper,
  OptionsWrapper,
  HelperText,
  HelperIcon,
};
