import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { Avatar } from "../Avatar/Avatar";
import MenuButton from "../MenuButton/MenuButton";
import { Container, List, ButtonsContainer } from "./styles";
import { getDisplayName, getUserInitials } from "./helpers/userProfile";
import { Styles } from "../../utils/styles/designTokens/styles";
import typographyTokens from "../../utils/styles/unoTokens/typography";

export interface UserProfile {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
}
export interface NavbarProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  logout: () => void;
  userProfile: UserProfile;
  orgSwitcher?: React.ReactElement;
  settings?: React.ReactElement;
  children?: Array<React.ReactChild | boolean> | React.ReactChild;
}

function UnoNavbar({
  id,
  className,
  dataTestId = "uno-navbar",
  logout,
  userProfile,
  orgSwitcher,
  settings,
  children,
}: NavbarProps): JSX.Element {
  const [displayName, setDisplayName] = useState("");
  const [initials, setInitials] = useState<string | undefined>();

  const { firstName, lastName, email } = userProfile;

  useEffect(() => {
    const name = getDisplayName(firstName, lastName, email);
    setDisplayName(name);
    setInitials(getUserInitials(firstName, lastName));
  }, [firstName, lastName, email]);

  const userOptions = [
    {
      id: "userName",
      value: displayName,
      disabled: true,
      separator: true,
    },
    {
      id: "logout",
      value: "Log out",
      action: logout,
    },
  ];

  return (
    <Container
      id={id}
      className={className}
      data-testid={dataTestId}
      role="navigation"
    >
      <List>{children}</List>
      {orgSwitcher && orgSwitcher}
      <ButtonsContainer>
        {settings && settings}
        <MenuButton
          styleType="transparent"
          hideText
          className="user-profile"
          options={userOptions}
          icon={
            <Avatar
              className="avatar"
              size="l"
              fontSize={typographyTokens.fontSize.s}
              initials={initials}
              color={Styles.uno.color.text.lightPrimary}
              backgroundColor={Styles.uno.color.core.black}
              icon={
                <FontAwesomeIcon
                  className="avatar-icon"
                  icon={faUser}
                  color={Styles.uno.color.text.lightPrimary}
                  aria-label="avatar"
                  width="40px"
                  height="40px"
                  fontWeight={Styles.uno.fontWeight.medium}
                />
              }
            />
          }
        >
          User Options
        </MenuButton>
      </ButtonsContainer>
    </Container>
  );
}

export { UnoNavbar };
