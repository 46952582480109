/* eslint-disable */
import React, { FC } from "react";
import {
  DataBlockText,
  DataBlockTitleContainer,
  DataBlockTitleDot,
} from "./styles";

interface DataBlockTitlesProps {
  title: string;
  color: string;
  count: number;
  onClick?: () => void;
  ariaTitleLabel?: string;
  showValues?: boolean;
  dataTestId?: string;
}

export const DataBlockTitle: FC<DataBlockTitlesProps> = ({
  title,
  color,
  count,
  onClick,
  ariaTitleLabel,
  showValues,
  dataTestId = "dataBlockTitle",
}) => {
  const ariaLabel = ariaTitleLabel || title;

  return (
    <DataBlockTitleContainer
      data-testid={dataTestId}
      onClick={onClick}
      aria-label={ariaLabel}
      $clickable={!!onClick}
    >
      <DataBlockTitleDot data-testid={`${dataTestId}Dot`} $color={color} />
      <DataBlockText data-testid={`${dataTestId}Text`} $clickable={!!onClick}>
        {showValues ? `${title} (${count})` : title}
      </DataBlockText>
    </DataBlockTitleContainer>
  );
};
