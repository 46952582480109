import { FunctionComponent } from "react";
import { Button, Modal } from "@integrate/hedgehogger";

type ConfirmationModalProps = {
  title?: string;
  label: string;
  active: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  title="Are you sure?",
  label,
  active,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      closeOnBlur
      closeCallback={onCancel}
      title={title}
      active={active}
      width="500px;"
      buttons={[
        <Button label="Cancel" onClick={onCancel} type="text" key="cancelBtn"></Button>,
        <Button type="primary" key="confirmBtn" dataTestId="confirmBtn" label="Confirm" isDanger onClick={onConfirm}></Button>,
      ]}
    >
      {label}
    </Modal>
  );
};
