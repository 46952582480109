/* eslint-disable */
import { Styles } from "../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

const Legend = styled.legend`
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.medium};
  font-size: ${Styles.fontSizes.base};
  margin-left: 8px;
`;

interface IGridProps {
  $horizontal: boolean;
  $maxWidth?: string;
}

const Grid = styled.div<IGridProps>`
  display: grid;
  margin-top: 4px;

  ${({ $horizontal }) =>
    $horizontal &&
    css`
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    `}

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}
`;

export { Fieldset, Legend, Grid };
