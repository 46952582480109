import { FunctionComponent, ReactElement, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const AutoLoginPage: FunctionComponent = (): ReactElement => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [])
  return <div></div>
};
