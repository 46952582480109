import { TableSelection } from "../../dataTable/DataTable";

interface TableDataType {
  id: string;
}

export class TableHelper {
  static checkIdExistsInArray = <T extends TableDataType>(id: string, selectedItems: T[]): boolean => {
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].id === id) {
        return true;
      }
    }
    return false;
  }

  static calcSelectedRows = <T extends TableDataType>(currentItems: T[], newItems : TableSelection[], existingData: T[]): T[] => {
    const newObjects: any = [];
    const currentObjects: any = [...currentItems];

    newItems.forEach((item: TableSelection) => {
      if (item.isSelected) {
        const newObject: T | undefined = existingData.find((obj: T) => {
          return obj.id === item.id;
        })

        const alreadySelected = currentItems.some((obj: T) => {
          return obj.id === item.id;
        });

        if (newObject && !alreadySelected) {
          newObjects.push(newObject);
        }
      } else {
        const index = currentObjects.findIndex((obj: T) => {
          return item.id === obj.id;
        })
        currentObjects.splice(index, 1);
      }
    })
    return currentObjects.concat(newObjects);
  }
}