/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useState } from "react";
import { Button } from "../../../Button/Button";
import { Checkbox } from "../../../Checkbox/Checkbox";
import { Body, ButtonWrapper, ScrollableBody } from "./styles";

interface IHideColumnsTablePopover {
  columns: any;
  onApply: (selectedOptions: string[]) => void;
  onReset: () => void;
  hiddenColumns: string[];
}
export const HideColumnsTablePopover: FunctionComponent<IHideColumnsTablePopover> = ({
  columns,
  onApply,
  onReset,
  hiddenColumns,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(hiddenColumns);
  const isApplyDisabled = columns.length === selectedOptions.length;
  const updateSelectedOptions = (id: string) => () => {
    const hasBeenSelected = selectedOptions.filter((item) => item === id)
      .length;

    if (hasBeenSelected) {
      const updatedSelectedOptions = selectedOptions.filter(
        (item) => item !== id
      );
      return setSelectedOptions(updatedSelectedOptions);
    }
    return setSelectedOptions((selectedOptions) => [...selectedOptions, id]);
  };

  const renderOptions = () => {
    return columns.map(({ label, hideDisabled, id }) => {
      const isHidden = !!selectedOptions.filter((item) => item === id).length;
      return (
        <Checkbox
          dataTestId={`${id}-checkbox`}
          key={id}
          label={label}
          disabled={hideDisabled}
          isDefaultChecked={!isHidden}
          onChange={updateSelectedOptions(id)}
        />
      );
    });
  };

  const handleApply = () => {
    onApply(selectedOptions);
  };

  return (
    <div data-testid="hideColumnsPopover">
      <Body>
        <ScrollableBody>{renderOptions()}</ScrollableBody>
      </Body>
      <ButtonWrapper>
        <Button
          label="Reset"
          type="secondary"
          onClick={onReset}
          dataTestId="popoverResetButton"
        />
        <Button
          label="Apply"
          type="primary"
          onClick={handleApply}
          dataTestId="popoverApplyButton"
          disabled={isApplyDisabled}
        />
      </ButtonWrapper>
    </div>
  );
};
