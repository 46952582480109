import {
  Button,
  KeywordSearch,
  Modal,
  Notifications,
} from "@integrate/hedgehogger";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  DataTable,
  DefaultDataTableParams,
  IDataTableHeader,
  IDataTableParams,
  IParsedDataTableRow,
  TableSelection,
} from "../../dataTable/DataTable";
import { useDebounce } from "../../hooks/useDebounce";
import { OrgService, OrgDataType, OrgsType, PartnerDataType, PartnersResponse } from "../../common/services/OrgService";
import { SearchWrapper } from "../../shared/StyledComponents";
import { NotificationHelper } from "../../common/helpers/NotificationHelper";
import { TableHelper } from "../../common/helpers/TableHelper";
import { ConfirmationModal } from "../../common/components/ConfirmationModal/ConfirmationModal";

const StyledLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

interface OrgPartnerProps {
  data: OrgDataType;
}

export const OrgPartner: FunctionComponent<OrgPartnerProps> = ({ data }) => {
  const [notifications, setNotifications]: any[] = useState([]);
  const [results, setResults] = useState<PartnersResponse>();
  const [tableData, setTableData] = useState<IParsedDataTableRow[]>([]);
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [modalActive, setModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<IDataTableParams>(DefaultDataTableParams);
  const [modalParams, setModalParams] = useState<IDataTableParams>(DefaultDataTableParams);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorText, setErrorText] = useState("");
  const debouncedSearchQuery = useDebounce(searchTerm, 400);
  const [sellerId, setSellerId] = useState("");
  const [selectedItems, setSelectedItems] = useState<PartnerDataType[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [partnersToDelete, setPartnersToDelete] = useState<PartnerDataType[]>();
  const orgId = data.id;

  useEffect(() => {
    const newParams = Object.assign({}, modalParams);
    newParams.search = debouncedSearchQuery.trim();
    setModalParams(newParams);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (sellerId === orgId) {
      setErrorText("Cannot partner with yourself.");
    } else {
      setErrorText("");
    }
  }, [sellerId, orgId]);

  useEffect(() => {
    if (results) {
      transformResults(results);
    }
  }, [results, selectedItems])

  const getPartners = async () => {
    try {
      setLoading(true);
      const newData = await OrgService.getPartners(data, params);
      setResults(newData);
    } catch (e) {
      setNotifications([NotificationHelper.error("Unable to get partners, please try again.")]);
    }

    setLoading(false);
  };

  const transformResults = (results: PartnersResponse) => {
    if (results) {
      const partners = results.partners;

      if (partners) {
        const parsedData: IParsedDataTableRow[] = partners.map(
          (partner: PartnerDataType) => {
            const isChecked = TableHelper.checkIdExistsInArray(partner.id, selectedItems)

            const parsedRow: IParsedDataTableRow = {
              id: partner.id,
              isDefaultChecked: isChecked,
              actions: [
                {
                  label: "Unassign",
                  action: () => handlePartnerUnassignConfirmation([partner]),
                  dataTestId: "deleteAction",
                },
              ],
              columns: [
                {
                  id: "OwnerName",
                  value: partner.ownerName ?? "",
                },
                {
                  id: "OwnerShortId",
                  value: partner.ownerShortId ?? "",
                },
                {
                  id: "PublisherName",
                  value: partner.publisherName ?? "",
                },
                {
                  id: "PublisherShortId",
                  value: partner.publisherShortId ?? "",
                },
              ],
            };

            return parsedRow;
          }
        );
        setTableData(parsedData);
        setTotalUsers(results.totalCount);
      }
    }
  };

  useEffect(() => {
    getPartners();
  }, [
    params.search,
    params.skip,
    params.take,
    params.sortBy,
    params.sortOrder,
  ]);

  useEffect(() => {
    const getOrgs = async () => {
      try {
        const results = await OrgService.getOrgs(modalParams);
        if (!results) {
          throw new Error("error getting orgs");
        }
        transformResults(results);
      } catch (e) {
        setNotifications([NotificationHelper.error("Unable to get orgs, please try again.")]);
      }
    };

    const transformResults = (data: OrgsType) => {
      if (data) {
        const organizations = data.organizations;
        const parsedData: DropdownItem[] = organizations.map(
          (org: OrgDataType, index: number) => {
            return {
              key: index,
              id: org.id,
              value: `${org.name} [SID: ${org.shortId}]`,
            };
          }
        );
        setDropdownItems(parsedData);
      }
    };
    if (modalParams.search) {
      getOrgs();
    }

    setErrorText("");
    setSellerId("");
    setSearchTerm("");
  }, [modalParams.search]);

  const columns: IDataTableHeader[] = [
    {
      label: "Buyer Name",
      id: "OwnerName",
    },
    {
      label: "Buyer Short Id",
      id: "OwnerShortId",
    },
    {
      label: "Seller Name",
      id: "PublisherName",
    },
    {
      label: "Seller Short Id",
      id: "PublisherShortId",
    },
  ];

  const bulkOptions: DropdownItem[] = [
    {
      id: "delete",
      value: "Delete Selected",
    }
  ];

  const handleDeletePartners = async () => {
    if (partnersToDelete) {
      const partnerIds = partnersToDelete.map((partner) => {
        return partner.id;
      });
      const body = {
        ids: partnerIds,
      };

      try {
        const results = await OrgService.deletePartners(data.id, body);
        if (!results) {
          throw new Error("error deleting partner");
        }
        setNotifications([NotificationHelper.success(`Deleted ${partnerIds.length} partner(s).`)]);
        getPartners();
      } catch (e) {
        setNotifications([NotificationHelper.error("Unable to delete partners, please try again.")]);
      }
    }
    setShowConfirmationModal(false);
  };

  const handlePartnerUnassignConfirmation = (partners: PartnerDataType[]) => {
    setShowConfirmationModal(true);
    setPartnersToDelete(partners);
  }

  const handleHideConfirmModal = () => {
    setShowConfirmationModal(false);
  }

  const handleDeleteSelectedPartners = async () => {
    setShowConfirmationModal(true);
    setPartnersToDelete(selectedItems);
  };

  const handleMatchPartner = async () => {
    if (sellerId) {
      try {
        await OrgService.matchPartner(data, sellerId);
        setNotifications([NotificationHelper.success(`Matched buyer and seller.`)]);
        getPartners();
      } catch (e: any) {
        if (e && e.data && e.data.messages) {
          setNotifications([NotificationHelper.error(e.data.messages[0])]);
        } else {
          setNotifications([NotificationHelper.error("Unable to match partners, please try again.")]);
        }
      }
    }

    setSellerId("");
    setModalActive(false);
    setSearchTerm("");
  };

  const handleSetModalActive = (active: boolean) => () => {
    setSellerId("");
    setModalActive(active);
  };

  const handleChangeParams = (newParams: IDataTableParams) => {
    setParams(newParams);
  };


  const handleSetSelectedItems = (newItems: TableSelection[]) => {
    const newSelection = TableHelper.calcSelectedRows(selectedItems, newItems, results?.partners ?? []);
    setSelectedItems(newSelection);
  };

  const handleChangeSellerQuery = (search: string) => {
    setSearchTerm(search);
  };

  const handleSelectSeller = (seller: any) => {
    if (seller && seller.id) {
      setSellerId(seller.id);
    } else {
      setSellerId("");
    }
  };

  interface DropdownItem {
    id: string;
    value: string;
  }

  const handleBulkAction = (bulkActionItem: DropdownItem) => {
    if (bulkActionItem.id === "delete") {
      handleDeleteSelectedPartners();
    }
  };

  return (
    <div>
      <DataTable
        button={
          <Button
            onClick={handleSetModalActive(true)}
            label="Match Partner"
            minWidth="84px"
            width="70%"
            maxWidth="210px"
            dataTestId="partnerbutton"
          ></Button>
        }
        bulkActionOptions={{
          onBulkItemClicked: handleBulkAction,
          bulkActionItems: bulkOptions,
        }}
        numSelectedItems={selectedItems.length}
        name="Partners"
        columns={columns}
        onSelectItems={handleSetSelectedItems}
        data={tableData}
        onChangeParams={handleChangeParams}
        totalAmount={totalUsers}
        popoverWidth="80px"
        loading={loading}
      ></DataTable>
      <Modal
        width="470px"
        buttons={[
          <Button label="Cancel" onClick={handleSetModalActive(false)} type="text" key="cancelbutton"></Button>,
          <Button
            disabled={!sellerId || !!errorText}
            type="primary"
            key="matchbutton"
            dataTestId="matchbutton"
            label="Match"
            onClick={handleMatchPartner}
          ></Button>,
        ]}
        closeOnBlur
        closeCallback={handleSetModalActive(false)}
        title="Match Partner"
        active={modalActive}
      >
        {modalActive && (
          <div>
            <StyledLabel>Buyer</StyledLabel>
            <SearchWrapper>
              <KeywordSearch
                disabled
                placeholder={data.name}
                label="Buyer"
                onChange={handleChangeSellerQuery}
              ></KeywordSearch>
            </SearchWrapper>
            <StyledLabel>Seller</StyledLabel>
            <SearchWrapper>
              <KeywordSearch
                dataTestId="match-dropdown"
                onSelect={handleSelectSeller}
                items={dropdownItems}
                placeholder={"Search Partners"}
                label="Seller"
                onChange={handleChangeSellerQuery}
                errorText={errorText}
              ></KeywordSearch>
            </SearchWrapper>
          </div>
        )}
      </Modal>
      <ConfirmationModal
          label={`${partnersToDelete?.length} organization(s) will be unassigned as a partner of ${data.name}.`}
          active={showConfirmationModal}
          onCancel={handleHideConfirmModal}
          onConfirm={handleDeletePartners}
        />
      <Notifications notifications={notifications} duration={4000} />
    </div>
  );
};

