import { FunctionComponent, useEffect, useState } from "react";
import { BulkH1, Centered } from "../../../shared/StyledComponents";
import { OrgDataType, OrgService } from "../../services/OrgService";
import { UserDataType, UsersService } from "../../services/UsersService";
import styled, { css } from "styled-components";
import { KeywordSearch, Styles } from "@integrate/hedgehogger";
import { ClaimDataType } from "../../services/ClaimsService";
import { BulkModes } from "../BulkModal/BulkModal";
import { ClaimActions } from "../SelectClaims/SelectClaims";

interface GridProps {
  threeColumns: boolean;
}

const DynamicGrid = styled.div<GridProps>`
  display: grid;
  grid-gap: 24px;

  ${({ threeColumns }) =>
    threeColumns
      ? css`
          grid-template-columns: 1fr 1fr 1fr;
        `
      : css`
          grid-template-columns: 1fr 1fr;
        `}
`;

const Wrapper = styled.div`
  margin: 4px 10%;
`;

interface ScrollDivProps {
  height?: string;
}

const ScrollDiv = styled.div<ScrollDivProps>`
  overflow-y: auto;
  height: ${({ height }) => height || "48vh;"};
`;

const BoldText = styled.div`
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  margin: 16px 0;
`;

const LightText = styled.div`
  font-weight: 400;
  opacity: 0.33;
  padding: 4px 0;
`;

const TextItem = styled.div`
  margin: 12px 0;
  width: 95%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  border-bottom: 1px solid ${Styles.colors.neutral100};
`;

const ClaimTextItem = styled.li`
  margin: 12px 0;
`;

const FixedHeightDiv = styled.div`
  padding-bottom: 16px;
  height: 64px;
`;

const InnerContainer = styled.div`
  display: grid;
  border-radius: 3px;
  border: 1px solid #cfcfcf;
  grid-template-rows: max-content;
  padding: 12px 0 12px 24px;
`;

interface ReviewPageProps {
  selectedOrgs: OrgDataType[];
  selectedUsers: UserDataType[];
  selectedClaims: ClaimDataType[];
  mode: BulkModes;
  onValidityChanged: (valid: boolean) => void;
  claimAction: ClaimActions | null;
}

export const ReviewPage: FunctionComponent<ReviewPageProps> = ({
  selectedOrgs,
  selectedUsers,
  selectedClaims,
  mode,
  onValidityChanged,
  claimAction,
}) => {
  useEffect(() => {
    onValidityChanged(true);
    filterOrg("");
    filterUser("");
  }, [selectedOrgs, selectedUsers, selectedClaims, mode]);

  const [userSearch, setUserSearch] = useState("");
  const [orgSearch, setOrgSearch] = useState("");
  const [filteredOrgs, setFilteredOrgs] = useState(selectedOrgs);
  const [filteredUsers, setFilteredUsers] = useState(selectedUsers);

  const filterUser = (search: string) => {
    const newUsers = [...selectedUsers];
    const filteredUserItems = UsersService.searchUserData(newUsers, search);
    setFilteredUsers(filteredUserItems);
  };

  const filterOrg = (search: string) => {
    const newOrgs = [...selectedOrgs];
    const filteredOrgItems = OrgService.searchOrgData(newOrgs, search);
    setFilteredOrgs(filteredOrgItems);
  };

  useEffect(() => {
    filterUser(userSearch);
  }, [userSearch]);

  useEffect(() => {
    filterOrg(orgSearch);
  }, [orgSearch]);

  const renderedUsers = () => {
    return filteredUsers.map((user: UserDataType) => {
      return (
        <TextItem key={user.id}>
          {user.contact?.lastName}, {user.contact?.firstName} <LightText>{user.userName}</LightText>
        </TextItem>
      );
    });
  };

  const renderedOrgs = () => {
    return filteredOrgs.map((org: OrgDataType) => {
      return (
        <TextItem key={org.id}>
          {org.name} <LightText>{org.shortId}</LightText>
        </TextItem>
      );
    });
  };

  const renderedClaims = () => {
    return selectedClaims.map((claim: ClaimDataType) => {
      return <ClaimTextItem key={claim.key}>{claim.label}</ClaimTextItem>;
    });
  };

  const renderLeftText = () => {
    if (selectedUsers.length === 1) {
      return `${selectedUsers[0].userName}`;
    } else {
      return `The following ${selectedUsers.length} users`;
    }
  }

  const renderCenterText = () => {
    switch (mode) {
      case BulkModes.AssignUsers:
      case BulkModes.AssignOrgs:
        if (selectedOrgs.length === 1) {
          return `will be assigned to ${selectedOrgs[0].name}`
        } else {
          return `will be assigned to these ${selectedOrgs.length} organizations`
        }
      case BulkModes.UnassignUsers:
      case BulkModes.UnassignOrgs:
        if (selectedOrgs.length === 1) {
          return `will be unassigned from ${selectedOrgs[0].name}`
        } else {
          return `will be unassigned from these ${selectedOrgs.length} organizations`
        }
      case BulkModes.AddMarketers:
        return `will be assigned these marketers`
      default:
        return "";
    }
  };

  const renderRightText = () => {
    switch (claimAction) {
      case ClaimActions.Replace:
        return `with only these claims`
      case ClaimActions.Add:
        return `with these newly added claims`
      case ClaimActions.Remove:
        return `without these claims`
      default:
        return "";
    }
  };

  const handleChangeUserSearch = (val: string) => {
    setUserSearch(val.trim());
  };

  const handleChangeOrgSearch = (val: string) => {
    setOrgSearch(val.trim());
  };

  const isAssignMode = (mode === BulkModes.AssignUsers || mode === BulkModes.AssignOrgs || mode === BulkModes.AddMarketers);
  const isMarketer = (mode === BulkModes.AddMarketers);
  const showClaimPage = !isMarketer && isAssignMode;

  return (
    <Wrapper>
      <Centered>
        <BulkH1>{`${renderLeftText()} ${renderCenterText()}.`}</BulkH1>
      </Centered>
      <DynamicGrid threeColumns={showClaimPage}>
        <div>
          <BoldText>
            {renderLeftText()}...
          </BoldText>
          <InnerContainer>
            <FixedHeightDiv>
              <KeywordSearch
                onChange={handleChangeUserSearch}
                placeholder={"Find a user"}
                width="200px"
              ></KeywordSearch>
            </FixedHeightDiv>
            <ScrollDiv>{renderedUsers()}</ScrollDiv>
          </InnerContainer>
        </div>
        <div>
          <BoldText>...{renderCenterText()}...</BoldText>
            <InnerContainer>
              <FixedHeightDiv>
                <KeywordSearch
                  onChange={handleChangeOrgSearch}
                  placeholder="Find an org"
                  width="200px"
                ></KeywordSearch>
              </FixedHeightDiv>
              <ScrollDiv>{renderedOrgs()}</ScrollDiv>
            </InnerContainer>
        </div>
        {showClaimPage && (
          <div>
            <BoldText>...{renderRightText()}.</BoldText>
            <InnerContainer>
              <ScrollDiv height={"calc(48vh + 80px);"}>
                {renderedClaims()}
              </ScrollDiv>
            </InnerContainer>
          </div>
        )}
      </DynamicGrid>
    </Wrapper>
  );
};
