/* eslint-disable */
import { Styles } from "../../../../utils/styles/designTokens/styles";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: columns;
`;

const StyledButton = styled.button`
  height: 36px;
  border-radius: ${Styles.borderRadius.s};
  outline: none;
  line-height: 20px;
  border: none;
  transition: ${Styles.transitions.button};
  font-size: ${Styles.fontSizes.s3};
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.medium};
  color: ${Styles.colors.blue500};
  background-color: ${Styles.colors.white};
  cursor: pointer;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: ${Styles.colors.blue100};
  }
`;

export { Wrapper, StyledButton };
