/* eslint-disable */
import styled, { css } from "styled-components";
import { Styles } from "../../../utils/styles/designTokens/styles";

interface IWrapperProps {
  $visible: boolean;
  $hasClosed: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  display: grid;
  grid-template-columns: 48px 224px 48px;
  height: auto;
  margin-top: 8px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); // TODO:: Seperate this logic into the Styles
  color: ${Styles.colors.white};
  background-color: ${Styles.colors.white};
  transform: translateX(calc(-100% - 10px));
  box-sizing: border-box;
  transition: 0.34s ease-in-out;
  border-radius: ${Styles.borderRadius.s};

  ${({ $visible }) =>
    $visible &&
    css`
      transform: translateX(24px);
    `}

  ${({ $hasClosed }) =>
    $hasClosed &&
    css`
      display: none;
    `}
`;

interface IIconWrapperProps {
  $type: "success" | "error" | "info" | "pending";
}

const IconWrapper = styled.div<IIconWrapperProps>`
  display: grid;
  grid-column: 1;
  border-top-left-radius: ${Styles.borderRadius.s};
  border-bottom-left-radius: ${Styles.borderRadius.s};
  align-items: center;
  justify-content: center;
  background-color: #253238; //TODO::: FIND THE RIGHT COLOUR IN STYLES FOR THIS
  font-size: ${Styles.fontSizes.m4};
  ${({ $type }) =>
    $type === "success" &&
    css`
      color: ${Styles.colors.green500};
    `}
  ${({ $type }) =>
    $type === "error" &&
    css`
      color: #f76a5e;
    `}

  ${({ $type }) =>
    $type === "info" &&
    css`
      color: ${Styles.colors.blue500};
    `}
  ${({ $type }) =>
    $type === "pending" &&
    css`
      color: ${Styles.colors.yellow500};
    `}
`;

const Body = styled.div`
  grid-column: 2;
  background-color: ${Styles.colors.white};
  padding: 16px;
  font-family: ${Styles.fonts.primary};
`;

const Title = styled.h3`
  font-size: ${Styles.fontSizes.m1};
  font-weight: ${Styles.fontWeights.semibold};
  margin: 0;
  color: black;
`;

const Text = styled.p`
  font-size: ${Styles.fontSizes.s2};
  margin: 8px 0 0 0;
  padding: 0;
  color: black;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: elipsis;
`;

const CloseWrapper = styled.div`
  grid-column: 3;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: ${Styles.colors.neutral50};
  font-size: ${Styles.fontSizes.m4};
  color: ${Styles.colors.text.darkSecondary};
  cursor: pointer;
  border-top-right-radius: ${Styles.borderRadius.s};
  border-bottom-right-radius: ${Styles.borderRadius.s};
  &:hover {
    background-color: ${Styles.colors.neutral300};
    color: ${Styles.colors.text.darkPrimary};
  }
`;

export { Wrapper, IconWrapper, Body, Title, Text, CloseWrapper };
