/* eslint-disable */
import React, { FunctionComponent, useState, useEffect } from "react";
import { useHover } from "../../hooks/useHover";
import {
  CheckboxAndLabelWrapper,
  CheckboxFill,
  CheckboxInputArea,
  CheckboxWrapper,
  LabelSpan,
} from "./styles";

export interface ICheckboxProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  isDefaultChecked?: boolean;
  disabled?: boolean;
  onChange: Function;
  label: string;
  hideLabel?: boolean;
  maxWidth?: string;
  wrapLabel?: boolean;
}

export const Checkbox: FunctionComponent<ICheckboxProps> = ({
  id,
  className,
  dataTestId = "checkbox",
  isDefaultChecked = false,
  onChange,
  disabled = false,
  label,
  hideLabel = false,
  maxWidth,
  wrapLabel = false,
}) => {
  const [isChecked, setIsChecked] = useState(isDefaultChecked);
  const { isHovered, handleHover } = useHover();

  useEffect(() => {
    setIsChecked(isDefaultChecked);
  }, [isDefaultChecked]);

  const handleClick = () => {
    if (!disabled) {
      onChange(!isChecked);
      setIsChecked(!isChecked);
    }
  };

  return (
    <CheckboxAndLabelWrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      onMouseEnter={handleHover(true, disabled)}
      onMouseLeave={handleHover(false, disabled)}
      $maxWidth={maxWidth}
    >
      <CheckboxWrapper onClick={handleClick}>
        <CheckboxInputArea
          disabled={disabled}
          role="checkbox"
          checked={isChecked}
          aria-checked={isChecked}
          onChange={handleClick}
        />
        <CheckboxFill
          $isHovered={isHovered}
          disabled={disabled}
          $isChecked={isChecked}
        />
      </CheckboxWrapper>
      <LabelSpan
        disabled={disabled}
        $hideLabel={hideLabel}
        $wrapLabel={wrapLabel}
      >
        {label}
      </LabelSpan>
    </CheckboxAndLabelWrapper>
  );
};
