/* eslint-disable */
import { Styles } from "../../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 32px auto 1fr;
  position: relative;
  white-space: nowrap;
  font-family: ${Styles.fonts.primary};
`;

interface ILabelProps {
  disabled: boolean;
}

const Label = styled.label<ILabelProps>`
  cursor: pointer;
  text-align: left;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  font-weight: ${Styles.fontWeights.medium};
  font-size: ${Styles.fontSizes.s3};
  align-self: center;
  font-family: ${Styles.fonts.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ disabled }) =>
    disabled &&
    css`
      font-weight: ${Styles.fontWeights.extralight};
      cursor: not-allowed;
      color: ${Styles.uno.color.text.darkSecondary};
    `}
`;

interface IAnimatedCircleProps {
  $isHovered: boolean;
  $isSelected: boolean;
  disabled: boolean;
}

const AnimatedCircle = styled.span<IAnimatedCircleProps>`
  padding: 2px;
  border: ${Styles.colors.neutral700} 2px solid;
  border-radius: 64px;
  align-self: center;
  margin: 8px;
  transition: 50ms all ease-in;
  cursor: pointer;

  &:after {
    display: block;
    padding: 4px;
    content: "";
    border-radius: 64px;
  }

  ${({ $isHovered }) =>
    $isHovered &&
    css`
      border-color: ${Styles.colors.blue500};
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${Styles.colors.blue500};

      &:after {
        background-color: ${Styles.colors.blue500};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border-color: ${Styles.colors.neutral300};
    `}
`;

interface HiddenInputProps {
  disabled: boolean;
}

const HiddenInput = styled.input<HiddenInputProps>`
  opacity: 0;
  position: absolute;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:focus {
        + span {
          outline: 1px solid ${Styles.colors.blue500};
        }
      }
    `}
`;

const CircleWrapper = styled.div`
  display: grid;
`;

const IconWrapper = styled.div`
  width: 12px;
  margin-left: 8px;
  align-self: center;
  justify-self: left;
`;

export {
  Wrapper,
  Label,
  AnimatedCircle,
  HiddenInput,
  CircleWrapper,
  IconWrapper,
};
