/* eslint-disable */
import { Styles } from "../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";

interface ICheckboxAndLabelWrapperProps {
  $maxWidth?: string;
}

const CheckboxAndLabelWrapper = styled.label<ICheckboxAndLabelWrapperProps>`
  font-family: ${Styles.fonts.primary};
  color: ${Styles.colors.text.darkPrimary};
  font-weight: ${Styles.fontWeights.regular};
  font-size: ${Styles.fontSizes.s3};
  display: grid;
  grid-template-columns: 18px 1fr;
  align-self: center;
  align-items: center;
  line-height: 20px;
  margin: 0;
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}
`;

const CheckboxWrapper = styled.span`
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: ${Styles.borderRadius.xs};
`;

const CheckboxInputArea = styled.input`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  left: 0;
  top: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

interface ICheckboxFillProps {
  $isChecked: boolean;
  $isHovered: boolean;
  disabled: boolean;
}

const CheckboxFill = styled.span<ICheckboxFillProps>`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  background-color: ${Styles.colors.white};
  border: 2px solid ${Styles.colors.neutral500};
  border-radius: ${Styles.borderRadius.xs};
  border-collapse: separate;
  transition: all 0.1s;
  box-sizing: border-box;

  &:after {
    position: absolute;
    top: 44%;
    left: 15%;
    display: table;
    width: 6px;
    height: 13px;
    border: 2px solid ${Styles.colors.white};
    border-top: 0;
    border-left: 0;
    -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: " ";
    box-sizing: border-box;
  }

  ${({ $isHovered }) =>
    $isHovered &&
    css`
      border-color: ${Styles.colors.blue500};
    `}

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: ${Styles.colors.blue500};
      border-color: ${Styles.colors.blue500};

      &:after {
        position: absolute;
        border: 2px solid ${Styles.colors.white};
        border-top: 0;
        border-left: 0;
        -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.13s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: " ";
      }
    `}

${({ disabled, $isChecked }) =>
    disabled &&
    css`
      border-color: ${Styles.colors.neutral300};

      ${$isChecked &&
      css`
        background-color: ${Styles.colors.neutral300};

        &:after {
          border-color: ${Styles.colors.white};
        }
      `}
    `}
`;

interface ILabelSpanProps {
  disabled: boolean;
  $hideLabel: boolean;
  $wrapLabel: boolean;
}

const LabelSpan = styled.span<ILabelSpanProps>`
  user-select: none;
  cursor: pointer;
  text-align: left;
  font-weight: ${Styles.fontWeights.regular};

  ${({ $wrapLabel }) =>
    $wrapLabel &&
    css`
      padding: 0 0 0 8px;
      word-wrap: break-word;
    `}

  ${({ $wrapLabel }) =>
    !$wrapLabel &&
    css`
      padding: 1px 0 0 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      color: ${Styles.colors.neutral500};
      cursor: not-allowed;
    `}

    ${({ $hideLabel }) =>
    $hideLabel &&
    css`
      padding: 0;
      width: 0;
    `}
`;

export {
  CheckboxAndLabelWrapper,
  CheckboxWrapper,
  CheckboxInputArea,
  CheckboxFill,
  LabelSpan,
};
