/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useState, useEffect } from "react";
import { useVisibilityAnimation } from "../../hooks/useVisibilityAnimation";
import { keyframes } from "styled-components";
import { Wrapper } from "./styles";

export interface IDrawerProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  width?: string;
  height?: string;
  visible: boolean;
}

const moveInAnimation = () => keyframes`
    0% { transform: translateY(0); }
    100% { transform: translateY(calc(0px - 100% - 5px)); }
  `;

const moveOutAnimation = () => keyframes`
    0% { transform: translateY(calc(0px - 100% - 5px)); }
    100% { transform: translateY(0); }
  `;

export const Drawer: FunctionComponent<IDrawerProps> = ({
  id,
  className,
  dataTestId = "drawer",
  children,
  width = "100%",
  height = "72px",
  visible = false,
}) => {
  const [animation, setAnimation] = useState(null);
  const [display, setVisibility] = useVisibilityAnimation(300, visible);

  useEffect(() => {
    setVisibility(visible);

    if (visible) {
      return setAnimation(moveInAnimation());
    }

    if (animation) {
      setAnimation(moveOutAnimation());
    }
  }, [visible]);

  return (
    <Wrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      $width={width}
      $height={height}
      $visible={visible}
      $animation={animation}
      style={display}
    >
      {children}
    </Wrapper>
  );
};
