import styled, { css } from 'styled-components';
import { Styles } from '../../utils/styles/designTokens/styles';

interface ContainerProps {
  $width?: string;
  $active: boolean;
  $hasError: boolean;
  $hasWarning: boolean;
}

const Container = styled.div<ContainerProps>`
  box-sizing: border-box;
  font-family: ${Styles.uno.fontFamily.primary};
  font-size: ${Styles.uno.fontSize.s};
  color: ${Styles.uno.color.text.darkSecondary};
  word-break: break-all;
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}

  ${({ $active }) =>
    $active &&
    css`
      color: ${Styles.uno.color.accent.blue500};
    `}

  ${({ $hasWarning }) =>
    $hasWarning &&
    css`
      color: ${Styles.uno.color.semantic.warn500};
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${Styles.uno.color.semantic.negative500};
    `}
`;

interface InputProps {
  readOnly?: boolean;
  $hasError: boolean;
  $hasWarning: boolean;
  $maxWidth?: string;
  $maxHeight?: string;
  disabled?: boolean;
  $hasIcon?: boolean;
  $hasClear?: boolean;
  $iconPosition?: string;
  $prefixText?: string;
  $suffixText?: string;
  $isFocused: boolean;
  $color?: string;
}

const baseInput = `
  padding: 10px 8px;
  box-sizing: border-box;
  border-radius: ${Styles.borderRadius.s};
  border: 2px solid ${Styles.uno.color.neutral.warm300};
  width: 100%;
  cursor: text;
  transition: ${Styles.transitions.textfield};
  &:placeholder {
    color: ${Styles.uno.color.text.darkPlaceholder};
  }
  &:focus {
    outline: 0;
  }
  &:disabled {
    background: ${Styles.uno.color.neutral.warm100};
    cursor: not-allowed;
  }
`;

const baseText = `
  font-size: ${Styles.fontSizes.m1};
  font-family: ${Styles.fonts.primary};
  color: ${Styles.uno.color.text.darkPrimary};
  font-weight: normal;
`;

const Input = styled.input<InputProps>`
  ${baseInput};
  ${baseText};
  height: 45px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        border-color: ${Styles.uno.color.accent.blue500};
      }
    `}
  ${({ $hasIcon, $iconPosition }) =>
    $hasIcon &&
    $iconPosition &&
    $iconPosition === 'left' &&
    css`
      padding-left: 26px;
    `}
  ${({ $hasIcon, $iconPosition }) =>
    $hasIcon &&
    $iconPosition &&
    $iconPosition === 'right' &&
    css`
      padding-right: 26px;
    `}

  ${({ $hasWarning }) =>
    $hasWarning &&
    css`
      border-color: ${Styles.uno.color.semantic.warn500};
      &:hover,
      &:focus {
        border-color: ${Styles.uno.color.semantic.warn500};
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${Styles.uno.color.semantic.negative500};
      &:hover,
      &:focus {
        border-color: ${Styles.uno.color.semantic.negative500};
      }
    `}
  ${({ readOnly }) =>
    readOnly &&
    css`
      margin-top: 0px;
      border-width: 0px;
      padding: 0px;

      &:disabled {
        cursor: text;
        background: transparent;
      }
    `}
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

const TextArea = styled.textarea<InputProps>`
  ${baseInput}
  ${baseText};
  min-width: 100px;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        border-color: ${Styles.uno.color.accent.blue500};
      }
    `}
  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
    `}
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}

  ${({ $hasWarning }) =>
    $hasWarning &&
    css`
      border-color: ${Styles.uno.color.semantic.warn500};
      &:hover,
      &:focus {
        border-color: ${Styles.uno.color.semantic.warn500};
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${Styles.uno.color.semantic.negative500};
      &:hover,
      &:focus {
        border-color: ${Styles.uno.color.semantic.negative500};
      }
    `}
	  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

const InputContainer = styled.div<InputProps>`
  ${baseInput};
  ${baseText};
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    border-color: ${Styles.uno.color.accent.blue500};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${Styles.uno.color.neutral.warm100};
      cursor: not-allowed;
      &:hover {
        border-color: ${Styles.uno.color.neutral.warm300};
      }
    `}

  ${({ $hasIcon, $iconPosition }) =>
    $hasIcon &&
    $iconPosition &&
    $iconPosition === 'left' &&
    css`
      padding-left: 32px;
    `}

  ${({ $hasIcon, $iconPosition, $hasClear }) =>
    $hasIcon &&
    $iconPosition &&
    $iconPosition === 'right' &&
    !$hasClear &&
    css`
      padding-right: 32px;
    `}

  ${({ $isFocused }) => css`
    border-color: ${$isFocused
      ? Styles.uno.color.accent.blue500
      : Styles.uno.color.neutral.warm300};
  `}

  ${({ $hasWarning }) =>
    $hasWarning &&
    css`
      border-color: ${Styles.uno.color.semantic.warn500};
      &:hover {
        border-color: ${Styles.uno.color.semantic.warn500};
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${Styles.uno.color.semantic.negative500};
      &:hover {
        border-color: ${Styles.uno.color.semantic.negative500};
      }
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      margin-top: 0px;
      border-width: 0px;
      padding-top: 0px;
      padding-bottom: 0px;

      &:disabled {
        cursor: text;
        background: transparent;
      }
    `}
`;

const PrefixSuffixText = styled.p`
  padding: 0 4px;
  color: ${Styles.uno.color.text.darkPlaceholder};
  user-select: none;
`;

interface InputElementProps {
  $color?: string;
}

const InputElement = styled.input<InputElementProps>`
  ${baseText};
  width: 100%;
  border: 0;
  &:focus {
    border: 0px;
    outline: 0;
  }
  &:disabled {
    background: ${Styles.uno.color.neutral.warm100};
    cursor: not-allowed;
  }
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

interface HelperContainerProps {
  $color: string;
}

const HelperContainer = styled.p<HelperContainerProps>`
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
  margin-top: 4px;
  font-size: ${Styles.fontSizes.s2};
  word-break: break-all;
`;

const IconContainer = styled.span`
  margin-right: 4px;
`;

const InfoContainer = styled.div`
  display: inline-block;
  z-index: 1;
  justify-self: start;
  align-self: center;
  color: ${Styles.uno.color.text.darkSecondary};
`;

interface LabelProps {
  $hideLabel: boolean;
  $required?: boolean;
}

const LabelContainer = styled.div<LabelProps>`
  display: flex;
  padding-bottom: ${({ $hideLabel }) => ($hideLabel ? 0 : '8px')};
`;

const Label = styled.label<LabelProps>`
  margin-right: ${({ $required }) => ($required ? 0 : '8px')};
  ${({ $hideLabel }) =>
    $hideLabel &&
    css`
      height: 0;
      opacity: 0;
      width: 0;
    `}
`;

const TooltipContainer = styled.div`
  position: relative;
  bottom: 20px;
  left: 10px;
`;

const RequiredSymbol = styled.span`
  margin: 0 8px 0 4px;
`;

interface IconFieldContainerProps {
  $iconPosition?: string;
}

const IconFieldContainer = styled.div<IconFieldContainerProps>`
  position: absolute;
  color: ${Styles.uno.color.text.darkPlaceholder};
  left: ${({ $iconPosition }) => ($iconPosition === 'left' ? '10px' : 'auto')};
  right: ${({ $iconPosition }) => ($iconPosition === 'right' ? '10px' : 'auto')};
`;

const IconButton = styled.button`
  color: ${Styles.uno.color.text.darkPlaceholder};
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${Styles.uno.color.text.darkPrimary};
  }
`;

export {
  Container,
  Input,
  TextArea,
  InputContainer,
  InputElement,
  HelperContainer,
  IconContainer,
  InfoContainer,
  TooltipContainer,
  LabelContainer,
  Label,
  IconButton,
  IconFieldContainer,
  PrefixSuffixText,
  RequiredSymbol,
};
