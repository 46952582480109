/* eslint-disable */
import styled, { css } from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";

const FilterContainer = styled.div`
  position: relative;
  display: flex;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const FilterOptionsContainer = styled.div`
  z-index: 2;
  position: absolute;
  width: 264px;
  top: 45px;
  padding-top: 16px;
  background-color: ${Styles.colors.white};
  border-radius: 4px;
  box-shadow: 0px 4px 20px #00000033;
`;

const ChipContainer = styled.div`
  margin: 0 16px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 6px;
  align-items: center;
`;

const SearchContainer = styled.div`
  margin: 0 16px 16px 16px;
`;

const FieldsetWrapper = styled.fieldset`
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
`;

const OptionsContainer = styled.div`
  max-height: 264px;
  overflow: auto;
  margin: 0 16px;

  & > fieldset:not(:first-child) {
    margin-top: 16px;
  }

  &::-webkit-scrollbar {
    top: 5px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    height: 184px;
    border-radius: 4px;
    background-color: ${Styles.colors.neutral300};
  }

  scrollbar-color: ${Styles.colors.neutral300} transparent;
  scrollbar-width: thin;
  scrollbar-face-color: ${Styles.colors.neutral300};
  scrollbar-track-color: transparent;
`;

const OptionGroupTitle = styled.legend`
  margin-bottom: 16px;
  font-size: ${Styles.fontSizes.s3};
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.bold};
  color: ${Styles.colors.neutral900};
`;

const CheckboxContainer = styled.div`
  margin-bottom: 12px;
`;

const FilterOptionsFooter = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;

interface IFooterGradientProps {
  $height: number;
}

const FilterOptionsFooterGradient = styled.div<IFooterGradientProps>`
  position: sticky;
  z-index: 1;
  bottom: 0;
  width: 100%;

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}px;
    `}

  background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
`;

const FooterButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding: 16px;
  column-gap: 8px;
  justify-content: flex-end;
`;

const LinkButton = styled.button`
  text-align: left;
  text-decoration: underline;
  letter-spacing: 0px;
  color: ${Styles.colors.neutral700};
  font-size: ${Styles.fontSizes.s3};
  font-family: ${Styles.fonts.primary};
  opacity: 1;
  align-self: center;
  cursor: pointer;
  border: 0;
  background: transparent;

  &:hover {
    color: ${Styles.colors.neutral500};
  }
`;

const MessageTemplate = styled.div`
  display: grid;
  justify-items: center;
  font-family: ${Styles.fonts.primary};
  text-align: center;
  font-size: ${Styles.fontSizes.s3};
  color: ${Styles.colors.neutral700};

  div {
    max-width: 180px;
    margin-top: 16px;
  }

  button {
    margin-top: 16px;
  }
`;

export {
  FilterContainer,
  Overlay,
  FilterOptionsContainer,
  ChipContainer,
  SearchContainer,
  FieldsetWrapper,
  OptionsContainer,
  OptionGroupTitle,
  CheckboxContainer,
  FilterOptionsFooter,
  FilterOptionsFooterGradient,
  FooterButtonContainer,
  LinkButton,
  MessageTemplate,
};
