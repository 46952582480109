import { FunctionComponent, ReactElement, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Notifications } from "@integrate/hedgehogger";
import {
  DataTable,
  DefaultDataTableParams,
  IBulkActionOptions,
  IDataTableParams,
  IParsedDataTableRow,
  TableSelection,
} from "../dataTable/DataTable";
import { UserDataType, UsersDataType, UsersService } from "../common/services/UsersService";
import { NotificationHelper } from "../common/helpers/NotificationHelper";
import { TableHelper } from "../common/helpers/TableHelper";

interface UsersTableProps {
  onSelectUsers?: (users: UserDataType[]) => void;
  bulkActionOptions?: IBulkActionOptions;
  handleDelete?: () => void;
  handleChangeClaims?: () => void;
  maxHeight?: string;
  disableLinks?: boolean;
  button?: ReactElement;
  refresh?: number;
}

export const UsersTable: FunctionComponent<UsersTableProps> = ({
  onSelectUsers,
  bulkActionOptions,
  handleDelete,
  handleChangeClaims,
  maxHeight,
  disableLinks,
  button,
  refresh,
}) => {
  const [data, setData] = useState<IParsedDataTableRow[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [params, setParams] = useState<IDataTableParams>(DefaultDataTableParams);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<UsersDataType>();
  const [notifications, setNotifications]: any[] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<UserDataType[]>([]);
  const history = useHistory();
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    }
  }, []);

  const getResults = async () => {
    try {
      setLoading(true);
      const results = await UsersService.getUsers(params);
      if (!results) {
        throw new Error("error getting users");
      }

      if (mountedRef.current) {
        setResults(results);
        setTotalUsers(results.totalCount);
      }
    } catch (e) {
      if (mountedRef.current) {
        setNotifications([NotificationHelper.error("Unable to get users, please try again.")]);
      }
    }

    if (mountedRef.current) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResults();
  }, [params.search, params.skip, params.take, params.sortBy, params.sortOrder, refresh]);

  useEffect(() => {
    if (results) {
      let parsedRows;

      if (disableLinks) {
        parsedRows = UsersService.transformUsersToRows(results, selectedUsers);
      } else {
        parsedRows = UsersService.transformUsersToRows(results, selectedUsers, handleEditUser, handleChangeClaims, handleDelete);
      }
      setData(parsedRows);
    }
  }, [selectedUsers, results]);

  const handleTableParamsChange = (newParams: IDataTableParams) => {
    setParams(newParams);
  };

  const handleEditUser = (userId: string) => {
    history.push(`/users/${userId}`);
  };

  const handleSetSelectedItems = (items: TableSelection[]) => {
    const selectedRows = TableHelper.calcSelectedRows(selectedUsers, items, results?.users ?? []);
    setSelectedUsers(selectedRows);
    if (onSelectUsers) {
      onSelectUsers(selectedRows);
    }
  };

  return (
    <div>
      <DataTable
        name="Users"
        columns={UsersService.tableHeaderColumns}
        data={data}
        onChangeParams={handleTableParamsChange}
        totalAmount={totalUsers}
        loading={loading}
        onSelectItems={handleSetSelectedItems}
        bulkActionOptions={bulkActionOptions}
        numSelectedItems={selectedUsers.length}
        maxHeight={maxHeight}
        button={button}
      ></DataTable>
      <Notifications notifications={notifications} duration={4000} />
    </div>
  );
};
