import { Styles } from "@integrate/hedgehogger";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  a {
    transition: color 0.07s ease;
    color: ${Styles.colors.blue500};
    font-weight: 500;
  
    &:hover {
      color: ${Styles.colors.blue700};
    }
  }
`

interface StyledLinkProps {
  to: string,
  children?: any,
}

export const StyledLink: FunctionComponent<StyledLinkProps> = ({
  to,
  children,
}) => {
  return (
    <Wrapper>
      <Link to={to}>{children}</Link>
    </Wrapper>
  );
};
