/* eslint-disable */
import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// free solid
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons/faStopwatch";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faColumns } from "@fortawesome/free-solid-svg-icons/faColumns";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faUserMinus } from "@fortawesome/free-solid-svg-icons/faUserMinus";

// duo tone
import { faSortUp } from "@fortawesome/pro-duotone-svg-icons/faSortUp";
import { faSortDown } from "@fortawesome/pro-duotone-svg-icons/faSortDown";
import { faSort } from "@fortawesome/pro-duotone-svg-icons/faSort";

// pro regular
import { faChartLine as faChartLinePro } from "@fortawesome/pro-regular-svg-icons/faChartLine";
import { faStopwatch as faStopwatchPro } from "@fortawesome/pro-regular-svg-icons/faStopwatch";
import { faInfoCircle as faInfoCirclePro } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";
import { faCalendar as faCalendarPro } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { faBuilding as faBuildingPro } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faCog as faCogPro } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faMonitorHeartRate as faMonitorHeartRatePro } from "@fortawesome/pro-regular-svg-icons/faMonitorHeartRate";
import { faLifeRing as faLifeRingPro } from "@fortawesome/pro-regular-svg-icons/faLifeRing";
import { faSignOutAlt as faSignOutAltPro } from "@fortawesome/pro-regular-svg-icons/faSignOutAlt";
import { faExternalLinkAlt as faExternalLinkAltPro } from "@fortawesome/pro-regular-svg-icons/faExternalLinkAlt";
import { faLockAlt as faLockAltPro } from "@fortawesome/pro-regular-svg-icons/faLockAlt";
import { faFilter } from "@fortawesome/pro-regular-svg-icons/faFilter";
// pro light
import { faSearch as faSearchLight } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faBell as faBellLight } from "@fortawesome/pro-light-svg-icons/faBell";
import { faCog as faCogLight } from "@fortawesome/pro-light-svg-icons/faCog";
import { faTimes as faTimesLight } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faStopwatch as faStopwatchLight } from "@fortawesome/pro-light-svg-icons/faStopwatch";
import { faInfoCircle as faInfoCircleLight } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import { faAngleDown as faAngleDownLight } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleUp as faAngleUpLight } from "@fortawesome/pro-regular-svg-icons/faAngleUp";
import { faCalendar as faCalendarLight } from "@fortawesome/pro-light-svg-icons/faCalendar";
import { faExclamationTriangle as faExclamationTriangleLight } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import { faFolderOpen as faFolderOpenLight } from "@fortawesome/pro-light-svg-icons/faFolderOpen";
import { faUser as faUserLight } from "@fortawesome/pro-light-svg-icons/faUser";
import { faUserPlus as faUserPlusLight } from "@fortawesome/pro-light-svg-icons/faUserPlus";
import { faUserMinus as faUserMinusLight } from "@fortawesome/pro-light-svg-icons/faUserMinus";
import { consoleDeprecationWarning } from "../../utils/deprecationWarning";
import { IconWrapper } from "./styles";

export interface IIconProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  name: string;
  ariaLabel?: string;
  variant?: "light" | "pro";
  spin?: boolean;
  opacity?: number;
  padding?: string;
  onClick?: Function;
  fontSize?: string;
  color?: string;
}

export type IconStringName =
  | "faLock"
  | "faCheck"
  | "faExclamation"
  | "faCog"
  | "faSpinner"
  | "faEdit"
  | "faTimes"
  | "faInfo"
  | "faStopwatch"
  | "faChevronLeft"
  | "faChevronRight"
  | "faFilter"
  | "faArrowUp"
  | "faArrowDown"
  | "faChartLine"
  | "faInfoCircle"
  | "faEllipsisV"
  | "faSort"
  | "faSortUp"
  | "faSortDown"
  | "faColumns"
  | "faCopy"
  | "faFolder"
  | "faFolderOpen"
  | "faImage"
  | "faFile"
  | "faExclamationCircle"
  | "faExclamationTriangle"
  | "faInfoCircle"
  | "faCalendar"
  | "faBuilding"
  | "faMonitorHeartRate"
  | "faLifeRing"
  | "faSignOutAlt"
  | "faExternalLinkAlt"
  | "faCaretLeft"
  | "faCaretRight"
  | "faCaretDown"
  | "faAngleUp"
  | "faAngleDown"
  | "faSearch"
  | "faBell"
  | "faLockAlt"
  | "faUser"
  | "faUserPlus"
  | "faUserMinus";

/**
 * @deprecated use FontAwesomeIcon prop type
 */
export const Icon: FunctionComponent<IIconProps> = ({
  id,
  className,
  dataTestId = "icon",
  name,
  variant,
  ariaLabel,
  spin = false,
  opacity = 1,
  padding = "0",
  onClick,
  fontSize,
  color = "",
  children,
}) => {
  consoleDeprecationWarning(
    "Icon",
    "Icon component is deprecated. Use FontAwesomeIcon from '@fortawesome/react-fontawesome' instead.",
  );
  const isLight = variant === "light";
  const isPro = variant === "pro";
  const isDecorative = !ariaLabel;

  const getName = () => {
    switch (name) {
      case "faLock":
        return faLock;
      case "faCheck":
        return faCheck;
      case "faExclamation":
        return faExclamation;
      case "faCog":
        return isLight ? faCogLight : isPro ? faCogPro : faCog;
      case "faSpinner":
        return faSpinner;
      case "faEdit":
        return faEdit;
      case "faTimes":
        return isLight ? faTimesLight : faTimes;
      case "faInfo":
        return faInfo;
      case "faStopwatch":
        return isPro
          ? faStopwatchPro
          : isLight
            ? faStopwatchLight
            : faStopwatch;
      case "faChevronLeft":
        return faChevronLeft;
      case "faChevronRight":
        return faChevronRight;
      case "faFilter":
        return faFilter;
      case "faArrowUp":
        return faArrowUp;
      case "faArrowDown":
        return faArrowDown;
      case "faChartLine":
        return isPro ? faChartLinePro : faChartLine;
      case "faInfoCircle":
        return isLight ? faInfoCircleLight : faInfoCirclePro;
      case "faEllipsisV":
        return faEllipsisV;
      case "faSort":
        return faSort;
      case "faSortUp":
        return faSortUp;
      case "faSortDown":
        return faSortDown;
      case "faColumns":
        return faColumns;
      case "faCopy":
        return faCopy;
      case "faFolder":
        return faFolder;
      case "faFolderOpen":
        return faFolderOpenLight;
      case "faImage":
        return faImage;
      case "faFile":
        return faFile;
      case "faExclamationCircle":
        return faExclamationCircle;
      case "faExclamationTriangle":
        return isLight ? faExclamationTriangleLight : faExclamationTriangle;
      case "faInfoCircle":
        return faInfoCircle;
      case "faCalendar":
        return isLight ? faCalendarLight : faCalendarPro;
      case "faBuilding":
        return faBuildingPro;
      case "faMonitorHeartRate":
        return faMonitorHeartRatePro;
      case "faLifeRing":
        return faLifeRingPro;
      case "faSignOutAlt":
        return faSignOutAltPro;
      case "faExternalLinkAlt":
        return faExternalLinkAltPro;
      case "faCaretLeft":
        return faCaretLeft;
      case "faCaretRight":
        return faCaretRight;
      case "faCaretDown":
        return faCaretDown;
      case "faAngleUp":
        return faAngleUpLight;
      case "faAngleDown":
        return faAngleDownLight;
      case "faSearch":
        return isLight ? faSearchLight : faSearch;
      case "faBell":
        return isLight ? faBellLight : faBell;
      case "faLockAlt":
        return faLockAltPro;
      case "faUser":
        return isLight ? faUserLight : faUser;
      case "faUserPlus":
        return isLight ? faUserPlusLight : faUserPlus;
      case "faUserMinus":
        return isLight ? faUserMinusLight : faUserMinus;
      default:
        return faLock;
    }
  };

  return (
    <IconWrapper
      id={id}
      $padding={padding}
      $clickable={!!onClick}
      onClick={
        onClick as (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
      }
      $fontSize={fontSize}
    >
      <FontAwesomeIcon
        className={`${className || ""}${spin ? " fa-spin" : ""}`}
        data-testid={dataTestId}
        opacity={opacity}
        aria-label={ariaLabel}
        aria-hidden={isDecorative}
        icon={getName()}
        color={color}
      />
      {children}
    </IconWrapper>
  );
};
