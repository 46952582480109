/* eslint-disable */
import styled, { css } from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IBackgroundProps {
  $active: boolean;
}

interface IWrapperProps {
  $minHeight?: string;
  $minWidth?: string;
  $width?: string;
  $maxWidth?: string;
}

const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 14px;
  color: black;
`;

const Background = styled.div<IBackgroundProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${Styles.colors.black};
  z-index: 998;
  display: none;
  opacity: 0;
  ${({ $active }) =>
    $active &&
    css`
      opacity: 0.5;
      display: block;
    `}
`;

const Container = styled.div<IBackgroundProps>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  align-items: center;
  height: 100%;
  justify-content: center;
  opacity: 0.5;
  display: none;
  pointer-events: none;

  ${({ $active }) =>
    $active &&
    css`
      display: grid;
      opacity: 1;
    `}
`;

const Wrapper = styled.div<IWrapperProps>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: ${Styles.colors.white};
  z-index: 1000;
  pointer-events: all;
  border: solid 1px ${Styles.uno.color.neutral.warm100};
  border-radius: ${Styles.uno.radius.m};
  box-shadow: ${Styles.uno.shadow.m};
  box-sizing: border-box;
  padding: 24px;
  width: ${({ $width }) => $width || "auto"};
  max-width: ${({ $maxWidth }) => $maxWidth || "none"};
  min-width: ${({ $minWidth }) => $minWidth};
  min-height: ${({ $minHeight }) => $minHeight};
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: start;
  column-gap: 16px;
`;

const Title = styled.h3`
  color: ${Styles.uno.color.text.darkPrimary};
  font-weight: ${Styles.fontWeights.bold};
  line-height: 32px;
  font-size: ${Styles.uno.fontSize.h3};
  font-family: ${Styles.uno.fontFamily.primary};
  margin: 0;
  word-break: break-word;
`;

const SubTitle = styled.div`
  font-family: ${Styles.fonts.primary};
  font-style: normal;
  font-weight: ${Styles.fontWeights.regular};
  font-size: ${Styles.uno.fontSize.xs};
  line-height: 20px;
  word-break: break-word;
  margin-top: 8px;
  color: ${Styles.uno.color.neutral.cool900_075};
`;

const IconButton = styled.button`
  background-color: ${Styles.uno.color.core.secondary100};
  border-radius: 6px;
  color: ${Styles.colors.neutral700};
  border: 0px;
  cursor: pointer;
  display: grid;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  font-size: ${Styles.fontSizes.m4};
  &:hover {
    color: ${Styles.colors.neutral900};
  }
`;

const Body = styled.div`
  margin: 24px 0;
  line-height: 18px;
  color: ${Styles.uno.color.text.darkPrimary};
  font-weight: ${Styles.uno.fontWeight.regular};
  p {
    font-size: ${Styles.uno.fontSize.s};
  }
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const FooterContentContainer = styled.div`
  margin-right: 8px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  position: relative;
  z-index: 0;
`;

export {
  CustomFontAwesomeIcon,
  Background,
  Container,
  Wrapper,
  Header,
  Title,
  SubTitle,
  IconButton,
  Body,
  Footer,
  FooterContentContainer,
  ButtonContainer,
};
