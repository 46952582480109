/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useState, useEffect } from "react";
import { RadioButton } from "./RadioButton/RadioButton";
import { Fieldset, Grid, Legend } from "./styles";

export interface IRadioOption {
  label: string;
  id: string;
  tooltip?: string;
  disabled?: boolean;
}

export interface IRadioButtonGroupProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  onChange: (string) => void;
  label: string;
  options: IRadioOption[];
  maxWidth?: string;
  horizontal?: boolean;
  defaultSelected?: string;
}

export const RadioButtonGroup: FunctionComponent<IRadioButtonGroupProps> = ({
  id: groupId,
  className,
  dataTestId = `radio-button-group`,
  label,
  onChange,
  options,
  maxWidth = "400px",

  horizontal = false,
  defaultSelected = "",
}) => {
  const [selectedItem, setSelectedItem] = useState(defaultSelected);

  useEffect(() => {
    setSelectedItem(defaultSelected);
  }, [defaultSelected]);

  const handleClick = (id: string) => {
    if (id !== selectedItem) {
      onChange(id);
      setSelectedItem(id);
    }
  };

  const renderRadioButtons = options.map(({ id, disabled, label, tooltip }) => {
    let isChecked = id === selectedItem;

    return (
      <RadioButton
        key={id}
        id={id}
        dataTestId={id}
        onChange={handleClick}
        isSelected={isChecked}
        label={label}
        disabled={disabled}
        tooltip={tooltip}
      />
    );
  });

  return (
    <Fieldset id={groupId} className={className} data-testid={dataTestId}>
      <Legend>{label}</Legend>
      <Grid $maxWidth={maxWidth} $horizontal={horizontal} role="radiogroup">
        {renderRadioButtons}
      </Grid>
    </Fieldset>
  );
};
