/* eslint-disable */
// @ts-nocheck
import { Styles } from "../../utils/styles/designTokens/styles";
import styled, { css, keyframes } from "styled-components";

interface IScrollWrapperProps {
  $width?: string;
  $hideShadow?: boolean;
}

interface IWrapperProps {
  $maxHeight?: string;
}

const Container = styled.div`
  position: relative;
`;

const ScrollWrapper = styled.div<IScrollWrapperProps>`
  position: relative;
  overflow: auto;
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
  ${({ $hideShadow }) =>
    !$hideShadow &&
    css`
      box-shadow: 0px 3px 6px #00000029;
    `}
`;

const Wrapper = styled.div<IWrapperProps>`
  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
    `}
`;

const TableWrapper = styled.table`
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`;

interface ICellProps {
  $isFixed?: boolean;
  $leftPosition?: number;
  $rightBorder?: boolean;
  $rightFixed?: boolean;
  $isSelected?: boolean;
  $isSelectable?: boolean;
}

const Cell = styled.td<ICellProps>`
  padding: 12px 20px 12px 10px;
  font-family: ${Styles.fonts.primary};
  background: white;
  background-clip: padding-box;
  box-sizing: border-box;
  ${({ $isFixed, $leftPosition, $isSelectable }) =>
    $isFixed &&
    css`
      position: sticky;
      left: ${$isSelectable
        ? $leftPosition + 49
        : $leftPosition
        ? $leftPosition
        : 0}px;
      z-index: 1;
    `}

  ${({ $rightFixed }) =>
    $rightFixed &&
    css`
      position: sticky;
      right: 0;
      &:after {
        height: calc(100% + 1px);
        content: "";
        width: 10px;
        background: transparent
          linear-gradient(-90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        left: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
    `}
  
  ${({ $rightBorder }) =>
    $rightBorder &&
    css`
      &:after {
        height: calc(100% + 1px);
        content: "";
        width: 10px;
        background: transparent
          linear-gradient(90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        right: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
    `}
  
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: ${Styles.colors.blue50};
    `}
`;

const Row = styled.tr`
  border-bottom: 1px solid ${Styles.colors.neutral300};
  padding: 20px 0;
`;

interface IHideableWrapperProps {
  $isHighlighted: boolean;
}

const HideableWrapper = styled.div<IHideableWrapperProps>`
  ${({ $isHighlighted }) =>
    !$isHighlighted &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

  button {
    padding: 2px 10px !important;
  }
`;

interface IFixedRight {
  $isFixed: boolean;
  $overlayActive?: boolean;
  $isSelected?: boolean;
  disabled?: boolean;
}

const HideColumn = styled.th<IFixedRight>`
  background-color: ${Styles.colors.neutral100};
  padding: 10px;
  box-sizing: border-box;
  color: #788a9a;
  font-size: 14px;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 50px;
  ${({ $isFixed, $overlayActive }) =>
    $isFixed &&
    css`
      position: sticky;
      right: 0;
      padding-left: 20px;
      &:after {
        height: calc(100% + 1px);
        content: "";
        width: 10px;
        background: transparent
          linear-gradient(-90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        left: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
      ${$overlayActive &&
      css`
        z-index: 2;
      `}
    `}
`;

const ActionCell = styled.td<IFixedRight>`
  text-align: center;
  color: #757575;
  background-color: ${Styles.colors.white};
  background-clip: padding-box;
  ${({ $isFixed }) =>
    $isFixed &&
    css`
      position: sticky;
      right: 0;
      &:before {
        height: calc(100% + 1px);
        content: "";
        width: 10px;
        background: transparent
          linear-gradient(-90deg, #22272b 0%, #22272b00 100%) 0% 0% no-repeat
          padding-box;
        left: 0;
        position: absolute;
        top: 0;
        opacity: 0.15;
      }
      padding-left: 10px;
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: ${Styles.colors.neutral50};
    `}
  
    ${({ disabled }) =>
    disabled &&
    css`
      > span {
        color: ${Styles.colors.neutral300};
      }
    `}
`;

const HeaderRow = styled.tr`
  background-color: ${Styles.colors.neutral100};
  th {
    border-top: 1px solid ${Styles.colors.neutral300};
    border-bottom: 1px solid ${Styles.colors.neutral300};
  }
  th:first-child {
    border-left: 1px solid ${Styles.colors.neutral300};
  }
  th:last-child {
    border-right: 1px solid ${Styles.colors.neutral300};
  }
`;

interface ISelectAllProps {
  $isFixed: boolean;
  $singleSelect: boolean;
}

const SelectAll = styled.th<ISelectAllProps>`
  background-color: ${Styles.colors.neutral100};
  padding: 12px 20px 12px 10px;
  font-size: 14px;
  cursor: pointer;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 1;
  ${({ $singleSelect }) =>
    $singleSelect &&
    css`
      width: 100px;
    `}
  ${({ $isFixed }) =>
    $isFixed &&
    css`
      left: 0;
      z-index: 2;
    `}
`;

interface ITooltipWrapperProps {
  $right?: number;
}

const TooltipWrapper = styled.div<ITooltipWrapperProps>`
  position: absolute;
  top: 40px;
  right: 0;
  ${({ $right }) =>
    $right &&
    css`
      right: ${$right}px;
    `}
`;

const pulseAnimation = keyframes`
  0%,100% { opacity: 1; }
  50% { opacity: 0.3; }
`;

const LoadingCell = styled.div`
  width: 100px;
  height: 16px;
  background-color: ${Styles.colors.neutral100};
  border-radius: ${Styles.borderRadius.s};
  animation-name: ${pulseAnimation};
  animation-iteration-count: infinite;
  animation-duration: 2s;
`;

const LoadingAction = styled.div`
  width: 10px;
  height: 30px;
  background-color: ${Styles.colors.neutral100};
  border-radius: ${Styles.borderRadius.s};
  animation-name: ${pulseAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: auto;
`;

export {
  Container,
  ScrollWrapper,
  Wrapper,
  TableWrapper,
  Overlay,
  Cell,
  Row,
  HideableWrapper,
  HideColumn,
  ActionCell,
  HeaderRow,
  SelectAll,
  TooltipWrapper,
  LoadingCell,
  LoadingAction,
};
