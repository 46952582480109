import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Container, Text, Image } from "./styles";

export interface AvatarProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  url?: string | undefined;
  initials?: string | undefined;
  size?: "s" | "m" | "l";
  icon?: ReactElement;
  border?: boolean;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  borderRadius?: string;
}

export function Avatar({
  id,
  className,
  dataTestId = "avatar",
  url,
  initials,
  icon = <FontAwesomeIcon icon={faUser} aria-label="avatar" />,
  size = "s",
  border = false,
  color,
  backgroundColor,
  fontSize,
  borderRadius,
}: AvatarProps): JSX.Element {
  const image = url && <Image src={url} alt="avatar" />;
  const text = initials && (
    <Text $size={size} $fontSize={fontSize}>
      {initials}
    </Text>
  );

  return (
    <Container
      id={id}
      className={className}
      data-testid={dataTestId}
      $icon={!image && !text}
      $border={border}
      $size={size}
      $color={color}
      $backgroundColor={backgroundColor}
      $fontSize={fontSize}
      $borderRadius={borderRadius}
    >
      {image || text || icon}
    </Container>
  );
}
