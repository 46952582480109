export type DropdownItem = {
  value: string;
  label: string;
};

export const LICENSE_COUNT: DropdownItem[] = [
  { value: "Select Count...", label: "Select Count..." },
  { value: "0", label: "0" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "90", label: "90" },
  { value: "140", label: "140" },
];

export const EXCESS_LICENSE_ERROR_TEXT="To reduce number of licenses, Please remove the excess licenses from authorised users in the organisation"
export const ADVANCED_PC_LICENSE="Advanced PC License"
