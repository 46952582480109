/* eslint-disable */
import React, { FunctionComponent, useState } from "react";
import { useHover } from "../../../hooks/useHover";
import { Tooltip } from "../../Tooltip/Tooltip";
import { Icon } from "../../Icon/Icon";
import {
  AnimatedCircle,
  CircleWrapper,
  HiddenInput,
  IconWrapper,
  Label,
  Wrapper,
} from "./styles";

export interface IRadioButtonProps {
  id: string;
  className?: string;
  dataTestId?: string;
  isDefaultChecked?: boolean;
  disabled?: boolean;
  onChange: Function;
  isSelected: boolean;
  label: string;
  tooltip?: string;
}

export const RadioButton: FunctionComponent<IRadioButtonProps> = ({
  id,
  className,
  dataTestId = `radio-button`,
  onChange,
  isSelected,
  disabled = false,
  label,
  tooltip,
}) => {
  const { isHovered, handleHover } = useHover();
  const [iconHovered, setIconHover] = useState(false);

  const handleIconHover = (hoverState: boolean) => () => {
    setIconHover(hoverState);
  };

  const handleClick = () => {
    if (!disabled && !isSelected) {
      onChange(id);
    }
  };

  const renderTooltipIcon = () => {
    if (tooltip)
      return (
        <IconWrapper
          onMouseEnter={handleIconHover(true)}
          onMouseLeave={handleIconHover(false)}
        >
          <Icon variant="pro" name="faInfoCircle" opacity={0.6}>
            <Tooltip
              visible={iconHovered}
              arrowDirection="top"
              arrowPlacement={1}
              text={tooltip}
            />
          </Icon>
        </IconWrapper>
      );
  };

  return (
    <Wrapper
      className={className}
      data-testid={dataTestId}
      onMouseEnter={handleHover(true, disabled)}
      onMouseLeave={handleHover(false, disabled)}
      onClick={handleClick}
    >
      <CircleWrapper>
        <HiddenInput
          type="radio"
          id={id}
          value={id}
          disabled={disabled}
          defaultChecked={isSelected}
        />
        <AnimatedCircle
          $isHovered={isHovered}
          $isSelected={isSelected}
          disabled={disabled}
        />
      </CircleWrapper>
      <Label aria-label={label} htmlFor={id} disabled={disabled}>
        {label}
      </Label>
      {renderTooltipIcon()}
    </Wrapper>
  );
};
