/* eslint-disable */
// @ts-nocheck
import React, { FC, CSSProperties, useState, useEffect } from "react";
import { Styles } from "../../utils/styles/designTokens/styles";
import { DataBlock } from "./DataBlock/DataBlock";
import { DataBlockTitle } from "./DataBlockTitle/DataBlockTitle";
import {
  DataBlockContainer,
  DataBlockTitleContainer,
  OverviewBarContainer,
} from "./styles";
import { DataBlockInfo } from "./types";

export interface OverviewBarProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  dataBlocks: DataBlockInfo[];
  justifyTitles?: CSSProperties["justifyContent"];
  width?: CSSProperties["width"];
  showValues?: boolean;
}

export const OverviewBar: FC<OverviewBarProps> = ({
  id,
  className,
  dataTestId = "overviewBar",
  dataBlocks,
  justifyTitles = "flex-start",
  width = "100%",
  showValues = false,
}) => {
  const defaultBlockId = -1;
  const leftmostBarBorderRadius = `${Styles.borderRadius.m} 0 0 ${Styles.borderRadius.m}`;
  const rightmostBarBorderRadius = `0 ${Styles.borderRadius.m} ${Styles.borderRadius.m} 0`;
  const singleBarBorderRadius = `${Styles.borderRadius.m}`;

  const [totalCount, setTotalCount] = useState<number>(0);
  const [leftMostBarWithCountId, setLeftMostBarWithCountId] = useState<number>(
    defaultBlockId
  );
  const [
    rightMostBarWithCountId,
    setRightMostBarWithCountId,
  ] = useState<number>(defaultBlockId);

  useEffect(() => {
    const total = dataBlocks.reduce<number>((n, { count }) => n + count, 0);
    setTotalCount(total);

    const barsWithCount = dataBlocks.filter(({ count }) => count != 0);
    const hasBlockWithData = barsWithCount.length > 0;
    const left = hasBlockWithData ? barsWithCount[0].id : defaultBlockId;
    setLeftMostBarWithCountId(left);

    const right = hasBlockWithData
      ? barsWithCount[barsWithCount.length - 1].id
      : defaultBlockId;
    setRightMostBarWithCountId(right);
  }, [dataBlocks]);

  const calculateBorderRadius = (id: number) => {
    if (id === defaultBlockId) {
      return singleBarBorderRadius;
    }

    if (
      leftMostBarWithCountId === rightMostBarWithCountId &&
      id === leftMostBarWithCountId
    ) {
      return singleBarBorderRadius;
    }

    switch (id) {
      case leftMostBarWithCountId:
        return leftmostBarBorderRadius;
      case rightMostBarWithCountId:
        return rightmostBarBorderRadius;
      default:
        return;
    }
  };

  const renderEmptyDataBlock = () => {
    const borderRadius = calculateBorderRadius(defaultBlockId);
    return (
      <DataBlock
        key={defaultBlockId}
        dataTestId={`dataBlock${defaultBlockId}`}
        color={Styles.colors.white}
        count={0}
        borderColor={Styles.colors.neutral300}
        borderRadius={borderRadius}
        width={100}
      />
    );
  };

  const renderDataBlocks = () => {
    const hasData = totalCount > 0;
    if (!hasData) {
      return renderEmptyDataBlock();
    }
    return dataBlocks.map(({ id, title, color, count }) => {
      const dataBlockWidth = (100 / totalCount) * count;
      const borderRadius = calculateBorderRadius(id);
      return (
        <DataBlock
          key={id}
          dataTestId={`dataBlock${id}`}
          title={title}
          color={color}
          count={count}
          borderRadius={borderRadius}
          width={dataBlockWidth}
        />
      );
    });
  };

  const renderDataBlockTitles = () =>
    dataBlocks.map(
      ({ id, title, color, count, onClick, ariaTitleLabel }, key) => {
        return (
          <DataBlockTitle
            key={id}
            dataTestId={`dataBlockTitle${key}`}
            title={title}
            color={color}
            onClick={onClick}
            count={count}
            showValues={showValues}
            ariaTitleLabel={ariaTitleLabel}
          />
        );
      }
    );

  return (
    <OverviewBarContainer
      id={id}
      className={className}
      data-testid={dataTestId}
      aria-label="Overview bar"
      $width={width}
    >
      <DataBlockContainer data-testid="dataBlockContainer">
        {renderDataBlocks()}
      </DataBlockContainer>
      <DataBlockTitleContainer
        data-testid="dataBlockTitleContainer"
        justifyTitles={justifyTitles}
      >
        {renderDataBlockTitles()}
      </DataBlockTitleContainer>
    </OverviewBarContainer>
  );
};
