/* eslint-disable */
// @ts-nocheck
import React, {
  useState,
  useEffect,
  ChangeEvent,
  useRef,
  MutableRefObject,
} from "react";
import { Styles } from "../../utils/styles/designTokens/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";

import {
  Container,
  Input,
  TextArea,
  InputContainer,
  InputElement,
  HelperContainer,
  IconContainer,
  InfoContainer,
  TooltipContainer,
  LabelContainer,
  IconButton,
  IconFieldContainer,
  PrefixSuffixText,
  Label,
  RequiredSymbol,
} from "./styles";
import { Tooltip } from "../Tooltip/Tooltip";
import { getId } from "../../utils/string";
import { Icon, IconStringName } from "../Icon/Icon";
import { useHover } from "../../hooks/useHover";

interface TextFieldProps {
  id?: string;
  className?: string;
  autoFocus?: boolean;
  label: string;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  errorText?: string;
  warningText?: string;
  required?: boolean;
  helperText?: string;
  prefixText?: string;
  suffixText?: string;
  tooltip?: string;
  dataTestId?: string;
  onChange?: (val: string) => void;
  onBlur?: (val: string) => void;
  readOnly?: boolean;
  color?: string;
  defaultValue?: string;
  multiLine?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  icon?: IconStringName | React.ReactElement;
  iconPosition?: "right" | "left";
  type?: string;
  maxLength?: number;
  onFocus?: () => void;
  hasClear?: boolean;
  hideLabel?: boolean;
  autocomplete?: boolean;
  customLabelId?: string;
}

function TextField({
  id,
  className,
  autoFocus,
  label,
  width,
  placeholder,
  disabled,
  errorText,
  warningText,
  required,
  helperText,
  prefixText,
  suffixText,
  tooltip,
  dataTestId = "textField",
  onChange,
  onBlur,
  onFocus,
  readOnly = false,
  color,
  defaultValue = "",
  multiLine = false,
  maxHeight,
  maxWidth,
  icon,
  iconPosition = "right",
  type = "text",
  maxLength,
  hasClear = false,
  hideLabel = false,
  autocomplete = false,
  customLabelId,
}: TextFieldProps) {
  const [active, setActive] = useState(false);
  const { isHovered, handleHover } = useHover();
  const [val, setVal] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const hasError = !disabled && errorText && !!errorText.trim().length;
  const hasWarning = !disabled && warningText && !!warningText.trim().length;
  const hasIcon =
    icon &&
    (typeof icon === "string" ? !!icon.trim().length : true) &&
    !multiLine;
  const hasExtraElements = hasIcon || hasClear || prefixText || suffixText;
  const useInternalLabel = !customLabelId;
  const idFromLabel = label.toLowerCase().split(" ").join("_");

  useEffect(() => {
    setVal(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleFocus = (toggle: boolean) => () => {
    setActive(toggle);
    if (onFocus) onFocus();
  };

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    onChange(e.target.value);
    setVal(e.target.value);
  };

  const handleOnBlur = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    handleFocus(false);
    setActive(false);
    if (onBlur) onBlur(e.target.value);
  };

  const renderTooltip = () => {
    if (tooltip && !hideLabel) {
      return (
        <InfoContainer
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
          data-testid="infoContainer"
          id={`tooltip_${idFromLabel}`}
        >
          <TooltipContainer>
            <Tooltip
              text={tooltip}
              arrowDirection="left"
              arrowPlacement={10}
              width="200px"
              visible={isHovered}
            />
          </TooltipContainer>
          <FontAwesomeIcon icon={faInfoCircle} />
        </InfoContainer>
      );
    }
  };

  const renderError = () => {
    if (hasError) {
      return (
        <HelperContainer
          $color={Styles.uno.color.semantic.negative500}
          data-testid="errorMessage"
          id={`helperText_${idFromLabel}`}
        >
          <IconContainer>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </IconContainer>
          {errorText}
        </HelperContainer>
      );
    }

    if (hasWarning) {
      return (
        <HelperContainer
          $color={Styles.uno.color.text.darkPrimary}
          data-testid="warningMessage"
          id={`helperText_${idFromLabel}`}
        >
          <IconContainer>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              color={Styles.uno.color.semantic.warn500}
            />
          </IconContainer>
          {warningText}
        </HelperContainer>
      );
    }

    return (
      helperText && (
        <HelperContainer
          $color={Styles.uno.color.text.darkPrimary}
          data-testid="helperText"
          id={`helperText_${idFromLabel}`}
        >
          {helperText}
        </HelperContainer>
      )
    );
  };

  const renderIcon = () => {
    const shouldRender = iconPosition === "left" || !hasClear;
    if (shouldRender) {
      return (
        <IconFieldContainer
          $iconPosition={iconPosition}
          data-testid="fieldIcon"
        >
          {typeof icon === "string" ? (
            <Icon name={icon as IconStringName} />
          ) : (
            icon
          )}
        </IconFieldContainer>
      );
    }
    return null;
  };

  const handleClearIconClick = () => {
    onChange("");
    setVal("");
  };

  const renderClear = () => {
    const hasInputToClear = !!val;
    if (hasInputToClear && !disabled) {
      return (
        <IconButton
          id={getId(id, "clearButton")}
          onClick={handleClearIconClick}
          data-testid="clearInputButton"
          aria-label="clear text"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </IconButton>
      );
    }
    return null;
  };

  const renderField = () => {
    if (multiLine) {
      return (
        <TextArea
          ref={inputRef as MutableRefObject<HTMLTextAreaElement>}
          autoFocus={autoFocus}
          $hasError={hasError}
          $hasWarning={hasWarning}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={handleFocus(true)}
          onBlur={handleOnBlur}
          onChange={handleOnChange ?? handleOnChange}
          value={val}
          $maxHeight={maxHeight}
          $maxWidth={maxWidth}
          data-testid="textArea"
          maxLength={maxLength}
          $isFocused={active}
          $color={color}
          autoComplete={autocomplete ? "on" : "off"}
        />
      );
    }

    if (hasExtraElements) {
      return (
        <InputContainer
          $hasError={hasError}
          $hasWarning={hasWarning}
          readOnly={readOnly}
          disabled={disabled}
          $hasIcon={hasIcon}
          $isFocused={active}
          $iconPosition={iconPosition}
          $prefixText={prefixText}
          $suffixText={suffixText}
          data-testid="inputContainer"
          $hasClear={hasClear}
        >
          {hasIcon && renderIcon()}
          {prefixText && <PrefixSuffixText>{prefixText}</PrefixSuffixText>}
          <InputElement
            id={customLabelId || idFromLabel}
            ref={inputRef as MutableRefObject<HTMLInputElement>}
            readOnly={readOnly}
            autoFocus={autoFocus}
            disabled={disabled}
            placeholder={placeholder}
            onFocus={handleFocus(true)}
            onBlur={handleOnBlur}
            onChange={handleOnChange ?? handleOnChange}
            value={val}
            type={type}
            maxLength={maxLength}
            $color={color}
            autoComplete={autocomplete ? "on" : "off"}
            aria-describedby={
              !hideLabel
                ? `helperText_${idFromLabel} tooltip_${idFromLabel}`
                : ""
            }
          />
          {suffixText && <PrefixSuffixText>{suffixText}</PrefixSuffixText>}
          {hasClear && renderClear()}
        </InputContainer>
      );
    }

    return (
      <Input
        id={customLabelId || idFromLabel}
        ref={inputRef as MutableRefObject<HTMLInputElement>}
        autoFocus={autoFocus}
        readOnly={readOnly}
        $color={color}
        $hasError={hasError}
        $hasWarning={hasWarning}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={handleFocus(true)}
        onBlur={handleOnBlur}
        onChange={handleOnChange ?? handleOnChange}
        value={val}
        type={type}
        maxLength={maxLength}
        $isFocused={active}
        autoComplete={autocomplete ? "on" : "off"}
        aria-describedby={
          !hideLabel ? `helperText_${idFromLabel} tooltip_${idFromLabel}` : ""
        }
      />
    );
  };

  return (
    <Container
      id={id}
      className={className}
      $width={width}
      $active={active}
      $hasError={hasError}
      $hasWarning={hasWarning}
      data-testid={dataTestId}
    >
      {useInternalLabel && (
        <LabelContainer $hideLabel={hideLabel}>
          <Label
            $required={required}
            $hideLabel={hideLabel}
            htmlFor={idFromLabel}
          >
            {label}
          </Label>
          {required && !hideLabel && <RequiredSymbol>*</RequiredSymbol>}
          {renderTooltip()}
        </LabelContainer>
      )}
      {renderField()}
      {renderError()}
    </Container>
  );
}

export { TextField };
