import styled, { css } from 'styled-components';
import { Styles } from '../../../utils/styles/designTokens/styles';

const List = styled.ul`
  cursor: default;
  list-style:none;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

interface ItemsListProps {
  $minWidth?: string;
  $maxWidth?: string;
  $width?: string;
  $height?: string;
}

const ItemsList = styled(List)<ItemsListProps>`
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  transform-origin: top;
  background-color: ${Styles.colors.white};
  border-radius: 4px;
  box-shadow: 0px 1px 10px ${Styles.colors.neutral300};
  transition: all 0.5s;
  pointer-events: all;
  transition: all 1s ease-in;
  padding: 8px;

  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `}
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
  ${({ $height }) =>
    $height &&
    css`
      max-height: ${$height};
    `}
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}
`;

interface ItemWrapperProps {
  $sidePadding: string;
  disabled?: boolean;
  $isDanger?: boolean;
}

const ListItem = styled.li<ItemWrapperProps>`
  list-style: none;
  padding-inline-start: ${({ $sidePadding }) => $sidePadding || '8px'};
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

const ItemWrapper = styled(ListItem)<ItemWrapperProps>`
  font-family: ${Styles.fonts.primary};
  justify-self: start;
  text-align: start;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding-inline-start: ${({ $sidePadding }) => $sidePadding || '8px'};
  color: ${({ $isDanger }) =>
    $isDanger
      ? Styles.uno.color.semantic.negative700
      : Styles.colors.neutral900};

  &:hover,
  &:focus,
  &:active {
    color: ${({ $isDanger }) =>
      $isDanger
        ? Styles.uno.color.semantic.negative500
        : Styles.colors.blue700};
    background-color: ${({ $isDanger }) =>
      $isDanger
        ? Styles.uno.color.semantic.negative100
        : Styles.colors.blue100};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background: none;
      opacity: 0.65;
    `}
`;

const ItemText = styled.div`
  font-family: ${Styles.uno.fontFamily.primary};
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${Styles.fontSizes.m1};
  padding: 8px 8px 8px 0px;
  cursor: pointer;
`;

const ListHeader = styled(ItemText)`
  font-weight: ${Styles.fontWeights.medium};
  cursor: default;
  pointer-events: none;
`;

export { List, ItemsList, ListItem, ItemWrapper, ItemText, ListHeader };
