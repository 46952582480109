import styled from 'styled-components';

interface DataBlockContainerProps {
  $width: number;
}

const DataBlockContainer = styled.span<DataBlockContainerProps>`
  width: ${({ $width }) => `${$width}%`};
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const TooltipContainer = styled.span`
  position: relative;
  top: -12px;
  & > div {
    transform: translate(-50%, -100%);
  }
`;

interface DataBlockBarProps {
  $color: string;
  $borderRadius: string;
  $borderColor: string;
}

const DataBlockBar = styled.span<DataBlockBarProps>`
  position: relative;
  width: 100%;
  height: 8px;
  margin-bottom: 19px;
  cursor: pointer;
  background-color: ${({ $color }) => $color};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  border: 1px solid ${({ $borderColor }) => $borderColor};
  &:hover {
    z-index: 10;
    margin-top: -2px;
    height: 12px;
    width: calc(100% + 4px);
    margin-bottom: 17px;
  }
`;

export { DataBlockContainer, TooltipContainer, DataBlockBar };
