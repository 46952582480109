import { FunctionComponent, useState, useEffect } from "react";
import { Button, Modal, TextField, Notifications, Switch } from "@integrate/hedgehogger";
import { Wrapper } from "../shared/StyledComponents";
import { PageHeader } from "../pageHeader/PageHeader";
import { BulkModal, BulkModes, StepEnums } from "../common/components/BulkModal/BulkModal";
import { OrgsTable } from "./OrgsTable";
import { OrgService, OrgDataType } from "../common/services/OrgService";
import { DropdownItem } from "@integrate/hedgehogger";
import { NotificationHelper } from "../common/helpers/NotificationHelper";
import { ModalGrid, SwitchWrapper, SwitchLabel } from "../shared/StyledComponents";

export const OrgsPage: FunctionComponent = () => {
  const [notifications, setNotifications]: any[] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState<OrgDataType[]>([]);
  const [isAssignMode, setIsAssignMode] = useState(false);
  const [createOrgModalVisible, setCreateOrgModalVisible] = useState(false);
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [desc, setDesc] = useState("");
  const [isAgency, setIsAgency] = useState(false);
  const [nameError, setNameError] = useState("");
  const [shortNameError, setShortNameError] = useState("");
  const [disableCreateOrgBtn, setDisableCreateOrgBtn] = useState(true);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setDisableCreateOrgBtn(!name || !!nameError || !shortName || !!shortNameError);
  }, [name, shortName, nameError, shortNameError]);
  const [active, setActive] = useState(false);
  const [zuoraId, setZuoraId] = useState("");
  const [salesforceIds, setSalesforceIds] = useState("");

  const bulkOptions: DropdownItem[] = [
    {
      id: "assign",
      value: "Assign Users",
    },
    {
      id: "unassign",
      value: "Unassign Users",
    },
  ];

  const handleShowCreateOrg = () => {
    setCreateOrgModalVisible(true);
  };

  const hideBulkModal = () => {
    setActive(false);
  };

  const handleDeleteOrg = () => {
    //TODO: Implement delete org confirmation modal
  };

  const handleChangeClaims = () => {
    //TODO: Implement delete org confirmation modal
  };

  const handleCreateNewOrg = async () => {
    const newOrg: OrgDataType = OrgService.transformOrgDataForSaving(
      "00000000-0000-0000-0000-000000000000",
      "",
      name,
      shortName,
      desc,
      zuoraId,
      salesforceIds,
      isAgency,
      true
    );
    const response = await OrgService.createOrg(newOrg);
    hideCreateOrgModal();
    if (response && response.success) {
      setNotifications([...notifications, NotificationHelper.success("Organization was successfully created.")]);
      setRefresh(refresh + 1);
    } else {
      setNotifications([
        ...notifications,
        NotificationHelper.error("Unable to create organization, please try again."),
      ]);
    }
  };

  const handleSetSelectedOrgs = (orgs: OrgDataType[]) => {
    setSelectedOrgs(orgs);
  };

  const hideCreateOrgModal = () => {
    resetCreateOrgModal();
    setCreateOrgModalVisible(false);
  };

  const resetCreateOrgModal = () => {
    setName("");
    setShortName("");
    setNameError("");
    setShortNameError("");
    setDesc("");
    setIsAgency(false);
  };

  const handleBulkAction = (bulkActionItem: DropdownItem) => {
    switch (bulkActionItem.id) {
      case "assign":
        setIsAssignMode(true);
        setActive(true);
        break;
      case "unassign":
        setIsAssignMode(false);
        setActive(true);
        break;
      default:
        break;
    }
  };

  const onNameChange = (val: string) => {
    const trimmedName = val.trim();
    setName(trimmedName);
    if (trimmedName) {
      setNameError("");
    } else {
      setNameError("Name is required.");
    }
  };

  const onShortNameChange = (val: string) => {
    const trimmedName = val.trim();
    setShortName(trimmedName);
    if (trimmedName) {
      setShortNameError("");
    } else {
      setShortNameError("Short name is required.");
    }
  };

  const handleZuoraIdChange = (val: string): void => {
    setZuoraId(val.trim());
  };

  const handleSalesforceIdsChange = (val: string): void => {
    setSalesforceIds(val.trim());
  };

  const handleIsAgencyChange = (val: boolean): void => {
    setIsAgency(val);
  };

  const onDescChange = (val: string) => {
    const trimmedDesc = val.trim();
    setDesc(trimmedDesc);
  };

  return (
    <Wrapper>
      <PageHeader title="Organizations"></PageHeader>
      <OrgsTable
        handleChangeClaims={handleChangeClaims}
        handleDelete={handleDeleteOrg}
        onSelectOrgs={handleSetSelectedOrgs}
        button={<Button label="Create Org" onClick={handleShowCreateOrg} width="120px"></Button>}
        bulkActionOptions={{
          onBulkItemClicked: handleBulkAction,
          bulkActionItems: bulkOptions,
        }}
        refresh={refresh}
      ></OrgsTable>
      {createOrgModalVisible && (
        <Modal
          width="500px"
          buttons={[
            <Button label="Cancel" onClick={hideCreateOrgModal} type="text" key="cancelCreateUOrgBtn"></Button>,
            <Button
              disabled={disableCreateOrgBtn}
              type="primary"
              key="createOrgBtn"
              dataTestId="createOrgBtn"
              label="Create"
              onClick={handleCreateNewOrg}
            ></Button>,
          ]}
          closeOnBlur
          closeCallback={hideCreateOrgModal}
          title="Create New Organization"
          active={createOrgModalVisible}
        >
          <ModalGrid>
            <TextField label="Name" defaultValue="" placeholder="" onChange={onNameChange} errorText={nameError} />
            <TextField
              label="Short Name"
              defaultValue=""
              placeholder=""
              onChange={onShortNameChange}
              errorText={shortNameError}
            />
            <TextField
              label="Description (optional)"
              defaultValue={desc}
              placeholder="Description of the organization"
              multiLine
              onChange={onDescChange}
            />
            <TextField label="Zuora ID (optional)" placeholder="Enter Zuora ID" onChange={handleZuoraIdChange} />
            <TextField
              label="Salesforce ID (optional)"
              placeholder="Enter Salesforce ID"
              helperText="IDs are separated via commas"
              onChange={handleSalesforceIdsChange}
            />
            <div>
              <SwitchWrapper>
                <Switch onChange={handleIsAgencyChange} />
                <SwitchLabel>Is an Agency?</SwitchLabel>
              </SwitchWrapper>
            </div>
          </ModalGrid>
        </Modal>
      )}
      <Notifications notifications={notifications} duration={4000} />
      <BulkModal
        onCancel={hideBulkModal}
        selectedOrgs={selectedOrgs}
        selectedClaims={[]}
        selectedUsers={[]}
        currentStepKey={StepEnums.SelectUsers}
        active={active}
        mode={isAssignMode ? BulkModes.AssignUsers : BulkModes.UnassignUsers}
      />
    </Wrapper>
  );
};
