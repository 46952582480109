/* eslint-disable */
import { Styles } from "../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";

const Container = styled.nav`
  display: block;
  width: max-content;
  padding: 4px;
`;

const PaginationBody = styled.span`
  background-color: ${Styles.colors.neutral100};
  border-radius: ${Styles.borderRadius.s};
  user-select: none;
  margin: 0 8px;
  height: 32px;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-block;
`;

interface IIconContainerProps {
  disabled: boolean;
}

const IconContainer = styled.button<IIconContainerProps>`
  display: inline-block;
  vertical-align: top;
  height: 32px;
  background: none;
  border: 0;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
  transform-origin: center;
  border-radius: ${Styles.borderRadius.s};
  transition: opacity 0.1s ease-in;

  &:hover {
    background-color: ${Styles.colors.neutral300};
  }

  &:active {
    background-color: ${Styles.colors.blue500};
    color: ${Styles.colors.white};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;

      &:hover {
        transform: scale(1);
      }
    `}
`;

export { Container, PaginationBody, IconContainer };
