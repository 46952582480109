/* eslint-disable */
import { Styles } from "../../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";

interface IContainerProps {
  onClick: (e: Event) => void;
  $isSelected: boolean;
  $isDivider?: boolean;
  $isHovered?: boolean;
  disabled?: boolean;
  $width: number;
  $fontSize: string;
}

const Container = styled.button<IContainerProps>`
  display: inline-block;
  vertical-align: top;
  height: 32px;
  margin: auto;
  background-color: ${Styles.colors.neutral100};
  border: 2px;
  border-color: ${Styles.colors.neutral300};
  border-radius: ${Styles.borderRadius.s};
  font-weight: ${Styles.fontWeights.medium};
  color: ${Styles.colors.neutral900};
  font-family: ${Styles.fonts.primary};
  line-height: 1;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}

  ${({ $isHovered, disabled }) =>
    $isHovered &&
    !disabled &&
    css`
      background-color: ${Styles.colors.neutral300};
      transition: background-color 0.1s ease-in;
    `}

  &:active {
    background-color: ${Styles.colors.blue500};
  }

  &:focus {
    position: relative;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      cursor: default;
      transform: scale(1.1);
      background-color: ${Styles.colors.blue500};
      color: ${Styles.colors.white};
      vertical-align: unset;
    `}

  ${({ $width, $fontSize }) => `
    width: ${$width}px;
    font-size: ${$fontSize};
  `}
`;

export { Container };
