import styled from 'styled-components';
import { Styles } from '../../../../utils/styles/designTokens/styles';

const Wrapper = styled.div`
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 370px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Text = styled.h3`
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.m5};
  color: ${Styles.colors.neutral700};
  font-weight: ${Styles.fontWeights.regular};
`;

export { Wrapper, Text };
