/* eslint-disable */
import React, { ReactNode } from "react";
import { Icon } from "../Icon/Icon";
// import '@reach/menu-button/styles.css';
import { Menu, MenuItem } from "@reach/menu-button";
import {
  CustomMenuButton,
  CustomMenuItem,
  MenuList,
  ButtonContent,
  LoadingContainer,
  LoadingInnerContainer,
  MenuItemValue,
} from "./styles";

export interface MenuItemType {
  id: string | number;
  value: string | ReactNode;
  action?: () => void;
  disabled?: boolean;
  danger?: boolean;
  visible?: boolean;
  separator?: boolean;
}

export type StyleType = "primary" | "secondary" | "transparent";

export interface MenuButtonProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  options: MenuItemType[];
  children: string;
  styleType?: StyleType;
  isDanger?: boolean;
  icon?: HTMLElement | string | ReactNode;
  hideText?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export default function MenuButton({
  id,
  className,
  dataTestId = "menu-button",
  children,
  options,
  styleType = "primary",
  hideText = false,
  icon = hideText ? "faEllipsisH" : "faCaretDown",
  isDanger = false,
  disabled = false,
  loading = false,
}: MenuButtonProps): JSX.Element {
  const menuButtonClasses = `${styleType} ${isDanger ? "danger" : ""}`;
  const cn = className
    ? className.concat(" ", menuButtonClasses)
    : menuButtonClasses;
  return (
    <Menu>
      <CustomMenuButton
        id={id}
        className={cn}
        data-testid={dataTestId}
        disabled={disabled || loading}
        aria-label={children}
      >
        {loading ? (
          <LoadingContainer>
            <LoadingInnerContainer>
              <Icon dataTestId="loading" name="faSpinner" spin />
            </LoadingInnerContainer>
          </LoadingContainer>
        ) : null}
        <ButtonContent $visible={!loading}>
          {hideText ? null : children}
          {typeof icon === "string" ? (
            <Icon
              dataTestId={typeof icon === "string" ? icon : undefined}
              name={icon}
            />
          ) : (
            icon
          )}
        </ButtonContent>
      </CustomMenuButton>
      <MenuList className={styleType}>
        {options
          .filter((option) => option.visible !== false)
          .map(
            ({
              id,
              danger,
              disabled: itemDisabled,
              action,
              value,
              separator,
            }) => (
              <MenuItem
                key={id}
                as={CustomMenuItem}
                disabled={itemDisabled}
                $danger={danger}
                $isAction={!!action}
                $separator={separator}
                onSelect={() => {
                  if (action) {
                    action();
                  }
                }}
              >
                <MenuItemValue>{value}</MenuItemValue>
              </MenuItem>
            )
          )}
      </MenuList>
    </Menu>
  );
}
