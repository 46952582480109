import styled, { css } from 'styled-components';
import { Styles } from '../../utils/styles/designTokens/styles';

interface WrapperProps {
  $width?: string;
  $height?: string;
  $visible: boolean;
  $animation: string;
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  left: 0;
  overflow: hidden;
  background-color: ${Styles.colors.white};
  box-shadow: 0 0 6px ${Styles.colors.text.darkDisabled};
  padding: 12px;
  box-sizing: border-box;
  display: grid;
  width: ${({ $width }) => $width};
  ${({ $animation }) =>
    $animation &&
    css`
      -webkit-animation: ${$animation} 0.3s ease forwards;
      animation: ${$animation} 0.3s ease forwards;
    `}
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
      bottom: calc(0px - ${$height} - 5px);
    `}
`;

export { Wrapper };
