import { FunctionComponent, ReactElement, useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "@integrate/hedgehogger";
import { ClaimDataType, ClaimsService, DeleteOrgClaimsBody, ClaimsDataType, SetOrgClaimsBody } from "../../services/ClaimsService";
import { OrgDataType } from "../../services/OrgService";
import { AddPermittedMarketersResponse, AddPermittedMarketersRequest, UserDataType, UsersService } from "../../services/UsersService";
import { SelectUsers } from "../SelectUsers/SelectUsers";
import { SelectOrgs } from "../SelectOrgs/SelectOrgs";
import { ClaimActions, SelectClaims } from "../SelectClaims/SelectClaims";
import { ReviewPage } from "../ReviewPage/ReviewPage";
import { DonePage } from "../DonePage/DonePage";

interface IRootWrapperProps {
  active: boolean;
}

export enum BulkModes {
  AssignUsers,
  UnassignUsers,
  AssignOrgs,
  UnassignOrgs,
  AddMarketers,
}

const RootWrapper = styled.div<IRootWrapperProps>`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s;
  width: 100%;
  z-index: 10;

  ${({ active }) => {
    return `
    visibility: ${active ? "visible" : "hidden"};
    opacity: ${active ? "1" : "0"};
  `;
  }}
`;

const BgWrapper = styled.div`
  background-color: black;
  height: 100%;
  left: 0;
  opacity: 60%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  background-color: white;
  border-radius: 6px;
  height: calc(100% - 48px);
  left: 0;
  margin: 24px;
  opacity: 100%;
  position: fixed;
  top: 0;
  width: calc(100% - 48px);
  overflow: auto;
  z-index: 11;
`;

const ModalTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

const ModalHeader = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 170px auto 170px;
  padding: 24px 24px 0 24px;
`;

const ModalBody = styled.div`
  padding: 0 24px 0 24px;
`;

export enum StepEnums {
  SelectOrgs = 1,
  SelectUsers,
  SelectClaims,
  Review,
  Confirmation,
}

export interface IBulkModalProps {
  selectedOrgs: OrgDataType[];
  selectedUsers: UserDataType[];
  selectedClaims: ClaimDataType[];
  currentStepKey: StepEnums;
  onCancel: (refresh: boolean) => void;
  active: boolean;
  mode: BulkModes;
}

interface IStepButtonConfig {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

interface IStepConfig {
  key: StepEnums;
  nextKey?: StepEnums;
  title: string;
  component?: ReactElement;
  buttonConfig: IStepButtonConfig;
}

export const BulkModal: FunctionComponent<IBulkModalProps> = ({
  selectedOrgs,
  selectedUsers,
  selectedClaims,
  currentStepKey,
  onCancel,
  active,
  mode,
}) => {
  const [orgs, setOrgs] = useState<OrgDataType[]>(selectedOrgs);
  const [users, setUsers] = useState<UserDataType[]>(selectedUsers);
  const [claims, setClaims] = useState<ClaimDataType[]>(selectedClaims);
  const [claimAction, setClaimAction] = useState<ClaimActions | null>(null);

  let isSuccess = false;
  const errorMessages: string[] = [];
  const isAssignMode = (mode === BulkModes.AddMarketers || mode === BulkModes.AssignOrgs || mode === BulkModes.AssignUsers);
  const isMarketerMode = (mode === BulkModes.AddMarketers);

  const setInitialConfig = () => {
    setOrgs(selectedOrgs);
    setUsers(selectedUsers);
    setClaims(selectedClaims);
    setClaimAction(null);
    let current: IStepConfig = findStep(steps, currentStepKey);
    current = setStepComponent(current);
    setCurrentStep(current);
    const nextKey = current.nextKey ?? StepEnums.Review;
    const next = findStep(steps, nextKey);
    setNextStep(next);
  };

  useEffect(() => {
    setInitialConfig();
  }, [selectedOrgs, selectedUsers, selectedClaims, mode, isAssignMode]);

  useEffect(() => {
    if (claimAction !== null && currentStep.key === StepEnums.SelectClaims) {
      setClaims(selectedClaims);
      const current = setStepComponent(currentStep);
      setCurrentStep(current);
    }
  }, [claimAction]);

  const setStepComponent = (step: IStepConfig) => {
    if ((!isAssignMode || isMarketerMode) && step.key === StepEnums.SelectClaims) {
      step = findStep(steps, StepEnums.Review);
    }
    switch (step.key) {
      case StepEnums.SelectUsers:
        step.component = (
          <SelectUsers
            selectedItems={selectedOrgs}
            mode={mode}
            onChange={onSelectUsersChanged}
            onValidityChanged={onStepValidityChanged}
          />
        );
        break;
      case StepEnums.SelectOrgs:
        step.component = (
          <SelectOrgs
            selectedItems={selectedUsers}
            mode={mode}
            onChange={onSelectOrgsChanged}
            onValidityChanged={onStepValidityChanged}
          />
        );
        break;
      case StepEnums.SelectClaims:
        step.component = (
          <SelectClaims
            selectedItems={selectedClaims ?? []}
            onChange={onSelectClaimsChanged}
            onValidityChanged={onStepValidityChanged}
            users={users}
            claimAction={claimAction}
            onClaimActionChanged={onClaimActionChange}
            orgs={orgs}
          />
        );
        break;
      case StepEnums.Review:
        step.component = (
          <ReviewPage
            selectedClaims={claims}
            selectedOrgs={orgs}
            selectedUsers={users}
            mode={mode}
            claimAction={claimAction}
            onValidityChanged={onStepValidityChanged}
          />
        );
        break;
      case StepEnums.Confirmation:
        step.component = <DonePage isSuccess={isSuccess} errorMessages={errorMessages} mode={mode}/>;
        break;
    }
    return step;
  };

  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
  const onActionButtonClicked = async () => {
    if (currentStep.key === StepEnums.Review) {
      const userIds = users.map((user) => {
        return user.id;
      });
      const orgIds = orgs.map((org) => {
        return org.id;
      });

      let keys: string[];
      let setMarketersRequest: AddPermittedMarketersRequest;
      let setMarketerResult: AddPermittedMarketersResponse;
      let claimsBody: SetOrgClaimsBody;
      let claimsResult: ClaimsDataType;
      let deleteClaimsBody: DeleteOrgClaimsBody;

      switch (mode) {
        case BulkModes.AddMarketers:
          keys = orgs.map((org) => {
            return org.id;
          });
          setMarketersRequest = {
            userId: users[0].id,
            permittedMarketerIds: keys
          }
          setMarketerResult = await UsersService.addPermittedMarketers(setMarketersRequest);
          isSuccess = setMarketerResult && setMarketerResult.success;
          if (!isSuccess) {
            setMarketerResult.messages.forEach(message => {
              errorMessages.push(message);
            })
          }
          break;
        case BulkModes.AssignUsers:
        case BulkModes.AssignOrgs:
          keys = claims.map((claim) => {
            return claim.key;
          });
          claimsBody = {
            organizationIds: orgIds,
            userIds: userIds,
            claims: keys,
            action: claimAction ?? ClaimActions.Replace
          };
          claimsResult = await ClaimsService.setOrgClaims(claimsBody);
          isSuccess = claimsResult && claimsResult.success;
          break;

        case BulkModes.UnassignUsers:
        case BulkModes.UnassignOrgs:
          deleteClaimsBody = {
            organizationIds: orgIds,
            userIds: userIds,
          };
          claimsResult = await ClaimsService.deleteOrgClaims(deleteClaimsBody);
          isSuccess = claimsResult && claimsResult.success;
          break;
      }
    }
    const current = setStepComponent(nextStep);
    setCurrentStep(current);

    if (current.nextKey) {
      let next = findStep(steps, current.nextKey);
      next = setStepComponent(next);
      setNextStep(next);
    }
  };

  const onCancelButtonClicked = async () => {
    setInitialConfig();
    onCancel(false);
  };

  const onFinishButtonClicked = async () => {
    setInitialConfig();
    onCancel(true);
  };

  const onSelectUsersChanged = (users: UserDataType[] | undefined) => {
    setUsers(users ?? []);
  };

  const onSelectOrgsChanged = (orgs: OrgDataType[] | undefined) => {
    setOrgs(orgs ?? []);
  };

  const onSelectClaimsChanged = (claims: ClaimDataType[] | undefined) => {
    setClaims(claims ?? []);
  };

  const onStepValidityChanged = (val: boolean) => {
    setIsCurrentStepValid(val);
  };

  const onClaimActionChange = (id: string) => {
    switch (id) {
      case ClaimsService.claimActionOptions[0].id:
        setClaimAction(ClaimActions.Add);
        break;
      case ClaimsService.claimActionOptions[1].id:
        setClaimAction(ClaimActions.Replace);
        break;
      case ClaimsService.claimActionOptions[2].id:
        setClaimAction(ClaimActions.Remove);
        break;
      default:
        setClaimAction(null);
        break;
    }
  }

  const orgsOrMarketers = isMarketerMode ? "Marketers" : "Organizations";
  const usersOrMarketers = isMarketerMode ? "Marketers" : "Users";

  const orgStep: IStepConfig = {
    key: StepEnums.SelectOrgs,
    nextKey: StepEnums.SelectClaims,
    title: `${orgsOrMarketers}`,
    buttonConfig: {
      label: `Select ${orgsOrMarketers}`,
    },
  };

  const steps: IStepConfig[] = [
    orgStep,
    {
      key: StepEnums.SelectUsers,
      nextKey: StepEnums.SelectClaims,
      title: `Users`,
      buttonConfig: {
        label: `Select Users`,
      },
    },
    {
      key: StepEnums.SelectClaims,
      nextKey: StepEnums.Review,
      title: "Permissions",
      buttonConfig: {
        label: `Select Claims`,
      },
    },
    {
      key: StepEnums.Review,
      nextKey: StepEnums.Confirmation,
      title: "Review",
      buttonConfig: {
        label: isAssignMode ? `Assign ${usersOrMarketers}` : `Unassign ${usersOrMarketers}`,
      },
    },
    {
      key: StepEnums.Confirmation,
      title: "Confirmation",
      buttonConfig: {
        label: "Done",
      },
    },
  ];

  const [currentStep, setCurrentStep] = useState(orgStep);
  const [nextStep, setNextStep] = useState(orgStep);

  const findStep = (steps: IStepConfig[], key: StepEnums) => {
    let step: IStepConfig | undefined = steps.find((step) => {
      return step.key === key;
    });
    if (step === undefined) {
      step = orgStep;
    }
    return step;
  };
  const isOnConfirmationPage = currentStep.key === StepEnums.Confirmation;

  return (
    <RootWrapper active={active}>
      <BgWrapper></BgWrapper>
      <ModalWrapper>
        <ModalHeader>
          {isOnConfirmationPage ? (
            <p>&nbsp;</p>
          ) : (
            <Button label="Cancel" onClick={onCancelButtonClicked} type="secondary"></Button>
          )}
          <ModalTitle>
            {isAssignMode ? "Assign" : "Unassign"} {currentStep.title}
          </ModalTitle>
          {isOnConfirmationPage ? (
            <Button label={currentStep.buttonConfig.label} onClick={onFinishButtonClicked}></Button>
          ) : (
            <Button
              label={currentStep.buttonConfig.label}
              onClick={onActionButtonClicked}
              disabled={!isCurrentStepValid}
            />
          )}
        </ModalHeader>
        {active && <ModalBody>{currentStep.component}</ModalBody>}
      </ModalWrapper>
    </RootWrapper>
  );
};
