import React, { FunctionComponent } from 'react';
import Styled, { css } from 'styled-components';
import { Styles } from '@integrate/hedgehogger';

export interface IToggleButtonProps {
  isChecked: boolean;
  disabled?: boolean;
  onChange: () => void;
  dataTestId?: string;
}

interface IWrapperProps {
  isChecked: boolean;
  disabled: boolean;
}

const Wrapper = Styled.button<IWrapperProps>`
outline: 0;
margin: 0;
padding: 0;
position: relative;
display: inline-block;
box-sizing: border-box;
min-width: 44px;
height: 22px;
vertical-align: middle;
border: 1px solid transparent;
border-radius: 100px;
cursor: pointer;
&:after {
  position: absolute;
  top: 1px;
  width: 18px;
  height: 18px;
  background-color: ${Styles.colors.white};
  border-radius: 18px;
  cursor: pointer;
  transition: ${Styles.transitions.switch};
  content: ' ';
}

${({ isChecked }) =>
  isChecked
    ? `
  background-color: ${Styles.colors.blue500};
  transition: background-color 0.28s ease-in;
  &:after {
    left: 100%;
    margin-left: -1px;
    transform: translateX(-100%);
  }
`
    : `
  background-color: ${Styles.colors.neutral500};
  transition: background-color 0.3s ease-out;
  &:after {
    top: 1px;
    left: 1px;
  }
`}

${({ disabled }) =>
  disabled &&
  css`
    cursor: not-allowed;
    opacity: 0.4;
    &:after {
      cursor: not-allowed;
    }
  `}
`;

export const ToggleButton: FunctionComponent<IToggleButtonProps> = ({
  isChecked = false,
  dataTestId = 'togglebutton',
  disabled = false,
  onChange,
}) => {
  return (
    <Wrapper
      isChecked={isChecked}
      onClick={onChange}
      data-testid={dataTestId}
      disabled={disabled}
    ></Wrapper>
  );
};
