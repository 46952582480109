import styled from 'styled-components';

const Body = styled.div`
  height: 202px;
  width: 260px;
  overflow-y: hidden;
  padding: 16px 8px;
  box-sizing: border-box;
`;

const ScrollableBody = styled.div`
  display: grid;
  grid-row-gap: 8px;
  overflow-y: auto;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  border-top: 1px solid #cadae6;
  padding: 16px;
  box-sizing: border-box;
`;

export { Body, ScrollableBody, ButtonWrapper };
