/* eslint-disable */
import React, { FC, useState } from "react";
import { Styles } from "../../../utils/styles/designTokens/styles";
import { Tooltip } from "../../Tooltip/Tooltip";
import { DataBlockBar, DataBlockContainer, TooltipContainer } from "./styles";

interface DataBlockProps {
  title?: string;
  color: string;
  count: number;
  width: number;
  borderColor?: string;
  borderRadius?: string;
  dataTestId?: string;
}

export const DataBlock: FC<DataBlockProps> = ({
  title,
  color,
  count,
  width,
  borderRadius = "0",
  borderColor = Styles.colors.white,
  dataTestId = "dataBlock",
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnMouseEnter = () => setShowTooltip(true);
  const handleOnMouseLeave = () => setShowTooltip(false);

  const ariaLabel = title ? `${title}: ${count}` : `${count}`;

  return (
    <DataBlockContainer $width={width} data-testid={dataTestId}>
      <TooltipContainer>
        <Tooltip
          dataTestId={`${dataTestId}Tooltip`}
          text={`${count}`}
          arrowDirection="bottom"
          visible={showTooltip}
        />
      </TooltipContainer>
      <DataBlockBar
        data-testid={`${dataTestId}Bar`}
        aria-label={ariaLabel}
        $color={color}
        $borderRadius={borderRadius}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        $borderColor={borderColor}
      />
    </DataBlockContainer>
  );
};
