/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { useHover } from "../../../hooks/useHover";
import { Icon } from "../../Icon/Icon";
import { LabelWrapper, SortIconWrapper, Wrapper } from "./styles";

interface ITableHeader {
  id?: string;
  className?: string;
  dataTestId?: string;
  label: string;
  width?: string;
  handleActiveSort: Function;
  activeSort: boolean;
  isSelectable?: boolean;
  sortASC?: Function;
  sortDES?: Function;
  clearSort?: Function;
  fixedColumns?: number;
  isFixed: boolean;
  handleColumnWidth: Function;
  columnWidths: { id: string; width: number; index: number }[];
  loading: boolean;
  hasData: boolean;
}

export const TableHeader: FunctionComponent<ITableHeader> = ({
  id,
  className,
  dataTestId = "table-header",
  label,
  width,
  handleActiveSort,
  activeSort,
  isSelectable = false,
  sortASC,
  sortDES,
  clearSort,
  isFixed,
  handleColumnWidth,
  columnWidths,
  fixedColumns,
  loading,
  hasData,
}) => {
  const { handleHover } = useHover();
  const [sortOrder, setSortOrder] = useState("");
  const ref = useRef(null);
  const hasSort = sortASC && sortDES && clearSort && hasData;

  useEffect(() => {
    if (ref && isFixed) {
      handleColumnWidth({ id, width: ref.current.offsetWidth });
    }
  });

  useEffect(() => {
    !activeSort && setSortOrder("");
  }, [activeSort]);

  const handleSort = () => {
    const canSort = !loading && hasSort;
    handleActiveSort(id);
    if (canSort) {
      switch (sortOrder) {
        case "ascending":
          setSortOrder("descending");
          return sortDES();
        case "descending":
          setSortOrder("");
          handleActiveSort("");
          return clearSort();
        default:
          setSortOrder("ascending");
          return sortASC();
      }
    }
  };

  const renderSort = () => {
    const iconName =
      sortOrder === ""
        ? "faSort"
        : sortOrder === "descending"
        ? "faSortDown"
        : "faSortUp";

    return (
      hasData &&
      sortASC &&
      sortDES &&
      clearSort && (
        <SortIconWrapper
          $isLoading={loading}
          data-testid={`sort-${id}`}
          onClick={handleSort}
          $isActive={activeSort}
        >
          <Icon ariaLabel="Sort" name={iconName} />
        </SortIconWrapper>
      )
    );
  };

  const renderHeader = () => {
    // sort the column widths so that the index is in order when we hide or show columns
    const sortedColumnWidths = columnWidths.sort((a, b) => {
      return a.index - b.index;
    });

    // get the index of the column so we can add up all widths up to the index of current column
    const headerIndex = sortedColumnWidths.findIndex((col) => col.id === id);

    // add in the widths until you reach the current index of the header
    const leftPosition = sortedColumnWidths.reduce((acc, val, arrIndex) => {
      return arrIndex < headerIndex ? acc + val.width : acc;
    }, 0);

    const rightBorder = fixedColumns ? headerIndex + 1 === fixedColumns : false;

    return (
      <Wrapper
        id={id}
        className={className}
        data-testid={dataTestId}
        onMouseEnter={handleHover(true)}
        onMouseLeave={handleHover(false)}
        $width={width}
        $isSelectable={isSelectable}
        $isFixed={isFixed}
        ref={ref}
        $leftPosition={leftPosition}
        $rightBorder={rightBorder}
        $hasSort={hasSort}
        $isLoading={loading}
        onClick={handleSort}
      >
        <LabelWrapper>
          {label}
          {renderSort()}
        </LabelWrapper>
      </Wrapper>
    );
  };
  return renderHeader();
};
