// @ts-nocheck
const logger = process.env.NODE_ENV !== "production" ? console.warn : () => {};

const consoleDeprecationWarning = (() => {
  const shown = {};
  return (componentName: string, message: string) => {
    if (!shown[componentName]) {
      logger(`Hedgehogger WARNING: ${message}`);
    }
    shown[componentName] = true;
  };
})();

export { consoleDeprecationWarning };
