import styled from "styled-components";
import {
  MenuButton as ReachMenuButton,
  MenuList as ReachMenuList,
} from "@reach/menu-button";
import { Styles } from "../../utils/styles/designTokens/styles";
import "@reach/menu-button/styles.css";

interface StyledButtonContentProps {
  $visible: boolean;
}

// TODO: Use the same style for the Button and MenuButton
// Can set the MenuButton as=Button to use same style.
// https://styled-components.com/docs/basics#extending-styles
const CustomMenuButton = styled(ReachMenuButton)`
  border: none;
  border-radius: ${Styles.borderRadius.s};
  padding: 10px 16px;
  cursor: pointer;
  outline: 0;
  transition: 0.1s;
  word-break: break-word;
  position: relative;
  white-space: nowrap;
  color: ${Styles.colors.white};
  height: 40px;

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.primary {
    background: ${Styles.colors.blue500};

    &.danger {
      background: ${Styles.uno.color.semantic.negative500};

      &:hover {
        background: ${Styles.uno.color.semantic.negative700};
      }
    }

    &:focus,
    &:hover {
      background: ${Styles.colors.blue700};
    }
  }

  &.secondary {
    background: ${Styles.colors.neutral100};
    border: 2px solid ${Styles.colors.neutral300}${Styles.colors.opacity40};
    color: ${Styles.colors.neutral700};

    &.danger {
      color: ${Styles.uno.color.semantic.negative500};
    }

    &:focus,
    &:hover,
    &:active {
      color: ${Styles.colors.neutral900};

      &.danger {
        color: ${Styles.uno.color.semantic.negative700};
      }
    }
  }

  &.transparent {
    background: transparent;
    color: ${Styles.colors.neutral900};
    padding: 5px;

    &:focus {
      // these are the hh alpha focus mixin styles
      box-shadow: 0px 0px 3px 2px #1a94f5;
      border-radius: ${Styles.uno.radius.m};
      outline: 2px solid ${Styles.uno.color.core.white};
    }
    &.danger {
      color: ${Styles.uno.color.semantic.negative500};
    }
  }
`;

const MenuList = styled(ReachMenuList)`
  box-shadow: 0px 4px 16px 0px #0000001a;
  border: none;
  border-radius: 8px;
  margin-top: 8px;
  color: ${Styles.colors.neutral700};
  background: ${Styles.colors.white};
  min-width: 200px;
  padding: 8px;
  outline: none;
  font-family: ${Styles.fonts.primary};
`;

interface CustomMenuItemProps {
  $isAction?: boolean;
  $separator?: boolean;
  $danger?: boolean;
  disabled?: boolean;
}

const CustomMenuItem = styled.div<CustomMenuItemProps>`
  color: ${Styles.colors.neutral900};
  text-align: left;
  padding: 0 10px;
  font-size: ${Styles.uno.fontSize.s};
  ${({ disabled }) => disabled && `opacity: 0.5;`}
  ${({ $danger }) =>
    $danger && `color: ${Styles.uno.color.semantic.negative700};`}
  ${({ $separator }) =>
    $separator &&
    `
      > div {
        padding-bottom: 15px;
        border-bottom: solid gray 1px;
        margin-bottom: 5px;
      }
    `}

  &[data-reach-menu-item] {
    ${({ $isAction }) =>
      !$isAction &&
      `
      cursor: auto;
    `}
  }

  &[data-selected] {
    color: ${Styles.colors.blue700};
    background: ${Styles.colors.blue100};
    border-radius: 4px;

    ${({ $danger }) =>
      $danger &&
      `
      color: ${Styles.uno.color.semantic.negative500};
      background: ${Styles.uno.color.semantic.negative100};
    `}

    ${({ $isAction }) =>
      !$isAction &&
      `
      color: ${Styles.colors.neutral900};
      background: ${Styles.colors.white};
    `}

    ${({ $isAction, $danger }) =>
      !$isAction &&
      $danger &&
      `color: ${Styles.uno.color.semantic.negative700};`}
  }
`;

const MenuItemValue = styled.div`
  padding: 10px 0;
`;

const ButtonContent = styled.div<StyledButtonContentProps>`
  display: flex;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  justify-content: space-between;
  gap: 10px;
`;

const LoadingContainer = styled.div`
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingInnerContainer = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export {
  CustomMenuButton,
  MenuList,
  CustomMenuItem,
  MenuItemValue,
  ButtonContent,
  LoadingContainer,
  LoadingInnerContainer,
};
