import { FunctionComponent, ReactElement } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@integrate/hedgehogger";
import { BulkH1, Centered, Wrapper } from "../shared/StyledComponents";

export const LoginPage: FunctionComponent = (): ReactElement => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return isAuthenticated ? (
    <div>
      <div>Login Page</div>
      <div>Hello {user?.name}</div>
      <Button
        label={"Log Out"}
        onClick={() => {
          logout({ returnTo: window.location.origin });
        }}
      >
      </Button>
    </div>
  ) : (
    <div>
      <Wrapper>
          <Centered>
            <BulkH1>Please login through SSO.</BulkH1>
          </Centered>
          <Centered>
            <Button label={"Login"} onClick={() => loginWithRedirect()}></Button>
          </Centered>
      </Wrapper>
    </div>
  );
};
