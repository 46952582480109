import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import Environment from "./environment.json";

ReactDOM.render(
  <Auth0Provider
    domain={Environment.auth0Domain}
    clientId={Environment.auth0ClientId}
    redirectUri={window.location.origin}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById("root")
);
