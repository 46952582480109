import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Wrapper, Centered, LoadingAnimation, ErrorMessage } from "../shared/StyledComponents";
import { PageHeader } from "../pageHeader/PageHeader";
import { TabbedPages } from "../common/components/TabbedPages/TabbedPages";
import { UserOrgs } from "./UserOrgs";
import {
  UsersService,
  UserType,
  UserDataType,
  DeletePermittedMarketersRequest
} from "../common/services/UsersService";
import { PermittedMarketerType } from "../common/services/OrgService";
import { UserGeneralInfo } from "./UserGeneralInfo";
import { PermittedMarketers } from "./PermittedMarketers";
import { Notifications } from "@integrate/hedgehogger";
import { NotificationHelper, INotification } from "../common/helpers/NotificationHelper";

type URLParams = {
  userId: string;
};

const NOTIFICATION_DURATION = 4000;

export const UserPage: FunctionComponent = () => {
  const { userId } = useParams<URLParams>();
  const [userData, setUserData] = useState<UserDataType | null>(null);
  const [hasUserDataError, setHasUserDataError] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const transformUserDataFromBE = (data: UserType): UserDataType | null => {
    if (data && data.user) {
      setHasUserDataError(false);
      return data.user;
    } else {
      setHasUserDataError(true);
      return null;
    }
  };

  const onRemovePermittedMarketers = async (marketers: PermittedMarketerType[]) => {
    if (userData && marketers.length > 0) {
      const marketerIds = marketers.map((pm) => pm.id);

      const req: DeletePermittedMarketersRequest = {
        userId: userData.id,
        permittedMarketerIds: marketerIds,
      };
      const updatedUserData = await UsersService.deletePermittedMarketers(req);
      if (updatedUserData?.user?.permittedMarketers) {
        setNotifications([
          ...notifications,
          NotificationHelper.success(`${marketers.length} marketers have been removed from permitted marketers.`),
        ]);
        setCurrentTabIndex(2);
        setUserData(updatedUserData.user);
      } else {
        setNotifications([
          ...notifications,
          NotificationHelper.error(`There's an error removing the permitted marketers.`),
        ]);
      }
    }
  };

  const getUserData = async () => {
    setIsFetchingData(true);
    const data = await UsersService.getUser(userId);
    const transformed = transformUserDataFromBE(data);
    setUserData(transformed);
    setIsFetchingData(false);
  };

  const titleName = () => {
    if (!userData) return "";

    const contact = userData.contact;
    if (contact && contact.firstName && contact.lastName) {
      return `${contact.firstName} ${contact.lastName}`;
    }

    return userData?.userName;
  };

  const refresh = (tabIndex: number) => {
    getUserData();
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }

    return function cleanup() {
      setUserData(null);
    }
  }, [userId]);

  return (
    <Wrapper>
      {isFetchingData ? (
        <Centered>
          <LoadingAnimation>Loading...</LoadingAnimation>
        </Centered>
      ) : hasUserDataError ? (
        <Centered>
          <ErrorMessage>
            Error fetching user data. Please try again.
          </ErrorMessage>
        </Centered>
      ) : (
        userData && (
          <div>
            <PageHeader
              title={titleName()}
              returnToLabel="Users"
              returnToLink="/users"
            ></PageHeader>
            <TabbedPages
              key={userData.permittedMarketers.length + ""}
              tabData={[
                {
                  label: "General Information",
                  component: <UserGeneralInfo data={userData} />,
                },
                {
                  label: "Organizations",
                  component: <UserOrgs user={userData} refreshData={refresh} />,
                },
                {
                  label: "Permitted Marketers",
                  component: (
                    <PermittedMarketers
                      data={userData}
                      refreshData={refresh}
                      onRemoveMarketers={onRemovePermittedMarketers}
                    />
                  ),
                },
              ]}
              currentTabIndex={currentTabIndex}
            />
            <Notifications
              notifications={notifications}
              duration={NOTIFICATION_DURATION}
            />
          </div>
        )
      )}
    </Wrapper>
  );
};
