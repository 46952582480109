/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent } from "react";
import { StyledButton, Wrapper } from "./styles";

interface ITableActionPopoverLinks {
  buttons: { label: string; action: Function; dataTestId?: string }[];
  onButtonClick: Function;
}

export const ActionLinksTablePopover: FunctionComponent<ITableActionPopoverLinks> = ({
  buttons,
  onButtonClick,
}) => {
  const handleClick = (action) => () => {
    onButtonClick(action);
  };

  const renderButtons = () => {
    return buttons.map(({ label, action, dataTestId }, index) => {
      return (
        <StyledButton
          data-testid={dataTestId}
          key={`${label}-${index}`}
          onClick={handleClick(action)}
        >
          {label}
        </StyledButton>
      );
    });
  };

  return (
    <Wrapper data-testid="actionLinksTablePopover">{renderButtons()}</Wrapper>
  );
};
