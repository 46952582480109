/* eslint-disable */
// @ts-nocheck
import React, { FC, useState, Fragment } from "react";
import { Icon, IconStringName } from "../Icon/Icon";
import {
  ActionButton,
  ActionButtonWrapper,
  ButtonType,
  IconLabel,
  IconWrapper,
  LabelSpaceWrapper,
  LoadingContainer,
  LoadingInnerContainer,
  LoadingText,
  OptionsWrapper,
  Overlay,
  Wrapper,
  CustomDropdownOptions,
} from "./styles";
import { DropdownItem } from "../Dropdown/types";

export interface Props {
  id?: string;
  className?: string;
  dataTestId?: string;
  label: string;
  hideLabel?: boolean;
  onClick: () => void;
  onDropdownClick?: ({ id, value }) => void;
  type?: ButtonType;
  isDanger?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  disabled?: boolean;
  dropdownWidth?: string;
  dropdownMinWidth?: string;
  dropdownOptions?: DropdownItem[];
  loading?: boolean;
  loadingText?: string;
  name?: string;
  icon?: IconStringName | React.ReactElement;
}

export const Button: FC<Props> = ({
  id,
  className,
  dataTestId = "button",
  label,
  hideLabel = false,
  type = "primary",
  isDanger = false,
  disabled = false,
  width,
  minWidth,
  maxWidth,
  dropdownWidth,
  dropdownMinWidth = "180px",
  dropdownOptions,
  loading,
  onClick,
  onDropdownClick,
  loadingText,
  name = label,
  icon,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const renderDropdownIcon = () => {
    const isDropdown = type === "dropdown" && !loading;

    if (isDropdown) {
      return (
        <IconWrapper>
          <Icon name="faCaretDown" />
        </IconWrapper>
      );
    }

    return null;
  };

  const closeDropdown = () => {
    setDropdownActive(false);
  };

  const renderDropdown = () => {
    const shouldDisplayDropdown =
      (type === "dropdown" || type === "dropdown-action") &&
      dropdownActive &&
      dropdownOptions &&
      dropdownOptions.length &&
      onDropdownClick &&
      !loading &&
      !disabled;

    if (shouldDisplayDropdown) {
      return (
        <OptionsWrapper
          onClick={closeDropdown}
          $type={type}
          data-testid="dropdown"
          $width={width}
          aria-labelledby={label}
        >
          <CustomDropdownOptions
            dataTestId={`${dataTestId}-options`}
            width={dropdownWidth}
            minWidth={dropdownMinWidth}
            items={dropdownOptions}
            onValueChange={onDropdownClick}
          />
        </OptionsWrapper>
      );
    }

    return null;
  };

  const renderIcon = () => {
    if (icon) {
      return (
        <IconLabel $withLabel={!hideLabel}>
          {typeof icon === "string" ? (
            <Icon name={icon as IconStringName} />
          ) : (
            icon
          )}
        </IconLabel>
      );
    }
    return null;
  };

  const renderLabel = () => (
    <Fragment>
      {loading && (
        <LoadingContainer>
          <LoadingInnerContainer>
            {loadingText && (
              <LoadingText data-testid="loading-text">
                {loadingText}
              </LoadingText>
            )}

            <Icon name="faSpinner" spin />
          </LoadingInnerContainer>
        </LoadingContainer>
      )}

      <LabelSpaceWrapper $type={type} $visibile={!loading}>
        {renderIcon()}
        {!hideLabel && label}
      </LabelSpaceWrapper>
    </Fragment>
  );

  const handleClick = () => {
    if (type === "dropdown") {
      return setDropdownActive(!dropdownActive);
    }

    if (!loading) {
      onClick();
    }
  };

  const handleActionButtonClick = () => {
    setDropdownActive((isActive) => !isActive);
  };

  const renderButton = () => {
    if (type !== "dropdown-action") {
      return (
        <Fragment>
          <Overlay
            data-testid="overlay"
            $active={dropdownActive}
            onClick={closeDropdown}
          />
          <Wrapper
            id={id}
            className={className}
            data-testid={dataTestId}
            $btnType={type}
            $isDanger={isDanger}
            disabled={disabled || loading}
            $width={width}
            $minWidth={minWidth}
            $maxWidth={maxWidth}
            onClick={handleClick}
            name={name}
            aria-label={label}
          >
            {renderLabel()}
            {renderDropdownIcon()}
            {renderDropdown()}
          </Wrapper>
        </Fragment>
      );
    }

    const renderActionButton = () => {
      if (!loading) {
        return (
          <ActionButton
            name="dropdownButton"
            onClick={handleActionButtonClick}
            data-testid="dropdown-button"
            disabled={disabled || loading}
            aria-label={`Open options for ${label}`}
          >
            <Icon name="faCaretDown" />
          </ActionButton>
        );
      }
      return null;
    };

    return (
      <ActionButtonWrapper $width={width} $loading={loading}>
        <Overlay
          data-testid="overlay"
          $active={dropdownActive}
          onClick={closeDropdown}
        />
        <Wrapper
          id={id}
          className={className}
          data-testid={dataTestId}
          $btnType="dropdown-action"
          $isDanger={isDanger}
          disabled={disabled || loading}
          $minWidth={minWidth}
          $maxWidth={maxWidth}
          onClick={handleClick}
          $loading={loading}
          name={name}
          aria-label={label}
        >
          {renderLabel()}
          {renderDropdown()}
        </Wrapper>
        {renderActionButton()}
      </ActionButtonWrapper>
    );
  };

  return renderButton();
};
