import { FunctionComponent, useEffect } from "react";
import { OrgsTable } from "../../../orgs/OrgsTable";
import { SmallTableHeight } from "../../../shared/StyleConstants";
import { BulkH1, Centered } from "../../../shared/StyledComponents";
import { OrgDataType } from "../../services/OrgService";
import { UserDataType } from "../../services/UsersService";
import { BulkModes } from "../BulkModal/BulkModal";

interface ISelectOrgProps {
  selectedItems?: UserDataType[];
  mode: BulkModes;
  onChange: (items: OrgDataType[]) => void;
  onValidityChanged: (valid: boolean) => void;
}

export const SelectOrgs: FunctionComponent<ISelectOrgProps> = ({
  selectedItems,
  mode,
  onChange,
  onValidityChanged,
}) => {
  useEffect(() => {
    onValidityChanged(false);
  }, []);

  const handleSetSelectedOrgs = (orgs: OrgDataType[]) => {
    onChange(orgs);
    onValidityChanged(orgs.length > 0);
  };

  const amountOfUsers = selectedItems?.length ?? 0;
  const renderAssignText = () => {
    switch (mode) {
      case BulkModes.AssignOrgs:
        return `Which organizations would you like to assign to ${amountOfUsers} users?`;
      case BulkModes.UnassignOrgs:
        return `Which organizations would you like to unassign from ${amountOfUsers} users?`;
      case BulkModes.AddMarketers:
        return `Which marketers would you like to assign to this user?`;
      default:
        return `Which organizations would you like to assign to ${amountOfUsers} users?`;
    }
  }

  return (
    <div>
      <Centered>
        <BulkH1>
          {renderAssignText()}
        </BulkH1>
      </Centered>
      <OrgsTable onSelectOrgs={handleSetSelectedOrgs} maxHeight={SmallTableHeight} disableLinks></OrgsTable>
    </div>
  );
};
