/* eslint-disable */
import React, { FunctionComponent, useState, Fragment } from "react";
import { DropdownOptions } from "../Dropdown/DropdownOptions/DropdownOptions";
import { DropdownItem } from "../Dropdown/types";
import { TextField } from "../TextField/TextField";
import { OptionsWrapper, Overlay, Wrapper } from "./styles";
import { consoleDeprecationWarning } from "../../utils/deprecationWarning";

export interface IKeywordSearchProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  label?: string;
  hideLabel?: boolean;
  required?: boolean;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  errorText?: string;
  warningText?: string;
  items?: DropdownItem[];
  onChange: (val: string) => void;
  onSelect?: (val: any) => void;
  showItemsOnFocus?: boolean;
  defaultValue?: string;
  readOnly?: boolean;
}

/**
 * @deprecated use Select component from \@integrate/hedgehogger-alpha package instead
 */
export const KeywordSearch: FunctionComponent<IKeywordSearchProps> = ({
  id,
  className,
  dataTestId = "keyword-search",
  label = "Search",
  hideLabel = true,
  required,
  placeholder = "Search",
  width = "100%",
  disabled = false,
  errorText,
  warningText,
  items = [],
  onChange,
  onSelect,
  showItemsOnFocus,
  defaultValue,
  readOnly = false,
}) => {
  consoleDeprecationWarning(
    "KeywordSearch",
    "KeywordSearch component is deprecated. Use Select from '@integrate/hedgehogger-alpha' instead.",
  );

  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState(defaultValue || "");

  const handleChange = (value: string) => {
    const hasValue = value && value.trim().length;
    if (hasValue) {
      setIsOpen(true);
    } else if (!showItemsOnFocus) {
      setIsOpen(false);
      onSelect && onSelect(null);
    }

    onChange(value);
    setSearchInput(value);
  };

  const handleValueChange = (item: DropdownItem) => {
    setIsOpen(false);
    setSearchInput(item.value);
    onSelect && onSelect(item);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleFocus = () => {
    if (showItemsOnFocus) {
      setIsOpen(showItemsOnFocus);
    }
  };

  const renderOptions = () => {
    const shouldRender = isOpen && items.length;
    if (shouldRender) {
      return (
        <OptionsWrapper $hideLabel={hideLabel}>
          <DropdownOptions
            items={items}
            onValueChange={handleValueChange}
            width={width}
          />
        </OptionsWrapper>
      );
    }
  };

  return (
    <Fragment>
      <Overlay
        data-testid={`${dataTestId}-overlay`}
        $isClosed={!isOpen}
        onClick={closeDropdown}
      />
      <Wrapper $width={width}>
        <TextField
          id={id}
          className={className}
          dataTestId={dataTestId}
          label={label}
          required={required}
          placeholder={placeholder}
          width={width}
          disabled={disabled}
          errorText={errorText}
          warningText={warningText}
          onChange={handleChange}
          onFocus={handleFocus}
          hasClear={!readOnly}
          hideLabel={hideLabel}
          icon={readOnly ? undefined : "faSearch"}
          iconPosition="left"
          defaultValue={searchInput}
          readOnly={readOnly}
        />
        {renderOptions()}
      </Wrapper>
    </Fragment>
  );
};
