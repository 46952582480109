/* eslint-disable */
import React, { FunctionComponent } from "react";
import { Wrapper } from "./styles";

export interface ITablePopoverProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  width?: string;
  top?: number;
  right?: number;
}

export const TablePopover: FunctionComponent<ITablePopoverProps> = ({
  id,
  className,
  dataTestId = "table-popover",
  children,
  width,
  top,
  right,
}) => {
  return (
    <Wrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      $width={width}
      $top={top}
      $right={right}
    >
      {children}
    </Wrapper>
  );
};
