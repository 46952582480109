import { useState } from 'react';

const useHover = () => {
  const [isHovered, setHovered] = useState(false);

  const handleHover = (hoverState: boolean, disabled = false) => () => {
    if (!disabled) {
      setHovered(hoverState);
    }
  };

  const setHover = (hoverState: boolean) => {
    setHovered(hoverState);
  };

  return {
    isHovered,
    handleHover,
    setHover,
  };
};

export { useHover };
