import { FunctionComponent } from "react";
import { BulkH1, Centered, Wrapper } from "../../../shared/StyledComponents";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import styled, { css } from "styled-components";
import { Styles } from "@integrate/hedgehogger";
import { BulkModes } from "../BulkModal/BulkModal";

interface IconWrapperProps {
  isSuccess: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  font-size: 200px;

  ${({ isSuccess }) =>
    isSuccess ?
    css`
      color: ${Styles.colors.green500};
    ` : css`
    color: ${Styles.colors.red500};
  `}
`

interface DonePageProps {
  isSuccess: boolean;
  mode: BulkModes;
  errorMessages: string[]
}

export const DonePage: FunctionComponent<DonePageProps> = ({
  isSuccess,
  mode,
  errorMessages
}) => {

  const generateText = () => {
    const isAssignMode = (mode === BulkModes.AssignUsers || mode === BulkModes.AssignOrgs || mode === BulkModes.AddMarketers);
    const un = isAssignMode ? "" : "un";
    const isMarketer = (mode === BulkModes.AddMarketers);
    const usersOrMarketers = isMarketer ? "marketers" : "users";
    return isSuccess ? `The ${usersOrMarketers} were ${un}assigned.` : `Unable to ${un}assign ${usersOrMarketers}.${generateErrorMessages()}`;
  }

  const generateErrorMessages = () => {
    let errorString = "";

    errorMessages.forEach(message => {
      if (message[message.length - 1] === "."){
        errorString += ` ${message}`;
      } else {
        errorString += ` ${message}.`;
      }
    })

    return errorString;
  }

  return (
    <Wrapper>
      <Centered>
        <BulkH1>
          {generateText()}
        </BulkH1>
      </Centered>
      <Centered>
        <IconWrapper isSuccess={isSuccess}>
          <FontAwesomeIcon icon={isSuccess ? faCheck : faTimes} />
        </IconWrapper>
      </Centered>
    </Wrapper>
  );
};
