const colorTokens = {
  core: {
    white: '#FFFFFF',
    black: '#000000',
    // Primary
    primary100: '#F0F8FF',
    primary200: '#D6ECFF',
    primary300: '#066CE9',
    primary400: '#0555B8',
    primary500: '#033E86',
    // Secondary
    secondary100: '#F8F8F8',
    secondary200: '#F0F0F0',
    secondary300: '#60789A',
  },
  brand: {
    blue: '#4b8fc9',
    green: '#a2cd4e',
    orange: '#f69d38',
  },
  text: {
    // Light
    lightPrimary: '#FFFFFFF5',
    lightSecondary: '#FFFFFFDE',
    lightPlaceholder: '#FFFFFF8A',
    lightDisabled: '#FFFFFF61',
    // Dark
    darkPrimary: '#000000DE',
    darkSecondary: '#0000008A',
    darkPlaceholder: '#00000061',
    darkDisabled: '#00000042',
  },
  neutral: {
    // Cool
    cool50: '#F3F4F5',
    cool100: '#E8EAED',
    cool300: '#C9CCD0',
    cool500: '#A2A7B0',
    cool700: '#78808A',
    cool800: '#2A3B47',
    cool900: '#041738',
    cool900_075: '#041738BF',
    // Warm
    warm50: '#FAFAFA',
    warm100: '#EFEFEF',
    warm300: '#CFCFCF',
    warm500: '#A3A3A3',
    warm700: '#737373',
    warm900: '#424242',
  },
  semantic: {
    // Positive
    positive300: '#73E89A',
    positive500: '#23D760',
    positive700: '#1DB450',
    // Warn
    warn300: '#FFDE5C',
    warn500: '#FFD428',
    warn700: '#EBBC00',
    // Negative
    negative100: '#FFECEC',
    negative300: '#E02E2E',
    negative500: '#D52020',
    negative700: '#A80000',
    // Info
    info300: '#99E4FF',
    info500: '#6BD7FF',
    info700: '#36C1F5',
  },
  accent: {
    // Blue
    blue100: '#DEEEFD',
    blue500: '#4492E9',
    blue700: '#245CA7',
    // Byzantium
    byzantium100: '#F8E4FB',
    byzantium500: '#CB88D4',
    byzantium700: '#9C5F9A',
    // Purple
    purple100: '#EBE6FD',
    purple500: '#A091E7',
    purple700: '#7468B5',
    // Indigo
    indigo100: '#E6E9FD',
    indigo500: '#4F68F6',
    indigo700: '#3749AD',
    // Yellow
    yellow100: '#FAF3DA',
    yellow500: '#E8C969',
    yellow700: '#B59646',
    // Orange
    orange100: '#F8E7D8',
    orange500: '#E1A35C',
    orange700: '#AB6A39',
    // Red
    red100: '#FCE9E9',
    red500: '#E77364',
    red700: '#B14540',
    // Green
    green100: '#E6F4DA',
    green500: '#94C96D',
    green700: '#325A21',
  },
} as const;

export default colorTokens;
