import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Wrapper, Centered, LoadingAnimation, ErrorMessage } from "../../shared/StyledComponents";
import { PageHeader } from "../../pageHeader/PageHeader";
import { TabbedPages } from "../../common/components/TabbedPages/TabbedPages";
import { OrgGeneralInfo } from "./OrgGeneralInfo";
import { OrgPartner } from "./OrgPartner";
import { OrgDispositions } from "./OrgDispositions";
import { OrgUsers } from "./OrgUsers";
import { OrgService, OrgType, OrgDataType } from "../../common/services/OrgService";

type OrgParams = {
  orgId: string;
};

export const OrgPage: FunctionComponent = () => {
  const { orgId } = useParams<OrgParams>();
  const [orgData, setOrgData] = useState<OrgDataType | null>(null);
  const [hasOrgDataError, setHasOrgDataError] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const transformOrgDataFromBE = (data: OrgType): OrgDataType | null => {
    if (data && data.organization) {
      setHasOrgDataError(false);
      return data.organization;
    } else {
      setHasOrgDataError(true);
      return null;
    }
  };

  const getOrgData = async () => {
    setIsFetchingData(true);
    const data = await OrgService.getOrg(orgId);
    const transformed = transformOrgDataFromBE(data);
    setOrgData(transformed);
    setIsFetchingData(false);
  };

  useEffect(() => {
    if (orgId) {
      getOrgData();
    }
  }, [orgId]);

  return (
    <Wrapper>
      {isFetchingData ? (
        <Centered>
          <LoadingAnimation>Loading...</LoadingAnimation>
        </Centered>
      ) : hasOrgDataError ? (
        <Centered>
          <ErrorMessage>Error fetching org data. Please try again.</ErrorMessage>
        </Centered>
      ) : (
        orgData && (
          <div>
            <PageHeader title={`${orgData.name}`} returnToLabel="Orgs" returnToLink="/orgs"></PageHeader>
            <TabbedPages
              tabData={[
                {
                  label: "General Information",
                  component: <OrgGeneralInfo data={orgData} />,
                },
                {
                  label: "Dispositions",
                  component: <OrgDispositions data={orgData} />,
                },
                {
                  label: "Users",
                  component: <OrgUsers data={orgData} />,
                },
                {
                  label: "Partners",
                  component: <OrgPartner data={orgData} />,
                },
              ]}
              currentTabIndex={0}
            />
          </div>
        )
      )}
    </Wrapper>
  );
};
