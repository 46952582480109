/* eslint-disable */
import { Styles } from "../../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";

interface DataBlockTitleDotProps {
  $color: string;
}

const DataBlockTitleDot = styled.span<DataBlockTitleDotProps>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;

interface DataBlockTextProps {
  $clickable: boolean;
}

const DataBlockText = styled.span<DataBlockTextProps>`
  font-size: 12px;
  font-family: ${Styles.fonts.primary};
  color: ${Styles.colors.neutral500};

  ${({ $clickable }) =>
    $clickable &&
    css`
      text-decoration: underline;
    `}
`;

interface DataBlockTitleContainerProps {
  $clickable: boolean;
}

const DataBlockTitleContainer = styled.span<DataBlockTitleContainerProps>`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 16px;
  }

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;

      &:hover ${DataBlockText} {
        color: ${Styles.colors.neutral900};
      }
    `}
`;

export { DataBlockTitleDot, DataBlockText, DataBlockTitleContainer };
