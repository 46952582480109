export const camelize = (string: string) =>
  string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

export const getId = (
  parentId: string | undefined,
  ...path: string[]
): undefined | string => {
  if (!parentId) {
    return undefined;
  }
  return path.reduce(
    (accumulator, pathItem) => `${accumulator}_${camelize(pathItem)}`,
    parentId
  );
};

export const getTestId = (
  parentTestId: string | undefined,
  ...path: string[]
): undefined | string => {
  if (!parentTestId) {
    return undefined;
  }
  return path.reduce(
    (accumulator, pathItem) => `${accumulator}-${camelize(pathItem)}`,
    parentTestId
  );
};
