import color from './color';
import radius from './radius';
import shadow from './shadow';
import typography from './typography';
import dimensions from './dimensions';

const tokens = {
  color,
  radius,
  shadow,
  dimensions,
  ...typography,
};

export default tokens;
