/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Icon } from "../Icon/Icon";
import { DropdownOptions } from "./DropdownOptions/DropdownOptions";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import {
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { Styles } from "../../utils/styles/designTokens/styles";
import {
  ButtonWrapper,
  FormLabelWrapper,
  HelperIcon,
  HelperText,
  IconWrapper,
  LabelWrapper,
  OptionsWrapper,
  ValueWrapper,
  Wrapper,
} from "./styles";
import { DropdownItem } from "./types";

export interface DropdownProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  label: string;
  onClick: Function;
  items: DropdownItem[];
  disabled?: boolean;
  width?: string;
  placeholder?: string;
  popoverWidth?: string;
  popoverHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  multiSelect?: boolean;
  form?: boolean;
  errorText?: string;
  warningText?: string;
  helperText?: string;
  required?: boolean;
}

export interface DropdownItemsProps {
  onClick: Function;
  items: DropdownItem[];
  multiSelect: boolean;
  defaultSelectedItems?: DropdownItem[];
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
  id,
  className,
  dataTestId = "dropdown",
  label,
  onClick,
  items,
  multiSelect = false,
  disabled = false,
  form = false,
  width = "100%",
  minWidth = "90px",
  maxWidth = "280px",
  popoverWidth = null,
  popoverHeight = "220px",
  placeholder = "Select a value...",
  errorText,
  warningText,
  helperText,
  required,
}) => {
  const [selectedItems, setSelectedItems] = useState<DropdownItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const hasPlaceholderText = selectedItems.length === 0;
  const dropdownOptionsPadding = !!form ? "12px" : "8px";
  const dropdownOptionsWidth = popoverWidth || (form && "100%") || null;
  const itemsWithCurrentSelected = items.map((item) => ({
    isSelected: selectedItems.includes(item),
    ...item,
  }));

  const hasError = !disabled && !!errorText;
  const hasWarning = !disabled && !!warningText;

  useEffect(() => {
    let defaultSelectedItems = [];

    const findAllDefaultSelected = (items: DropdownItem[]) => {
      const itemsSelectedAtCurrentDepth = items.filter(
        (item) => item.isSelected
      );
      defaultSelectedItems = [
        ...defaultSelectedItems,
        ...itemsSelectedAtCurrentDepth,
      ];
      items.forEach((item) => {
        if (item.items) {
          findAllDefaultSelected(item.items);
        }
      });
    };

    findAllDefaultSelected(items);
    if (!multiSelect) {
      setSelectedItems([defaultSelectedItems[0]]);
      return;
    }
    setSelectedItems(defaultSelectedItems);
  }, [items, multiSelect]);

  useOutsideClick(dropdownRef, () => handleToggleDropdown(false));

  const handleSelect = (item: DropdownItem) => {
    if (multiSelect) {
      let currentItems = [...selectedItems];
      const isCurrentItem = !!currentItems.find(({ id }) => id === item.id);
      if (isCurrentItem) {
        currentItems = currentItems.filter(({ id }) => id !== item.id);
      } else {
        currentItems.push(item);
      }

      setSelectedItems(currentItems);
      onClick(currentItems);
      return;
    }

    setIsOpen(false);
    setSelectedItems([item]);
    onClick(item);
  };

  const renderLabel = () => {
    return (
      !form && (
        <LabelWrapper
          htmlFor={id}
          disabled={disabled}
          $hasError={hasError}
          $hasWarning={hasWarning}
        >
          {`${label} ${required ? "*" : ""}`}
        </LabelWrapper>
      )
    );
  };

  const renderFormLabel = () => {
    return (
      form && (
        <FormLabelWrapper
          htmlFor={id}
          disabled={disabled}
          $isOpen={isOpen}
          $maxWidth={maxWidth}
          $width={width}
          $hasError={hasError}
          $hasWarning={hasWarning}
        >
          {`${label} ${required ? "*" : ""}`}
        </FormLabelWrapper>
      )
    );
  };

  const calcText = () => {
    if (selectedItems.length > 1) {
      return "Multiple";
    }

    return selectedItems[0] ? selectedItems[0].value : placeholder;
  };

  const renderIcon = () => {
    let iconName = "faAngleDown";
    let ariaLabel = "menu closed";
    if (isOpen) {
      iconName = "faAngleUp";
      ariaLabel = "menu open";
    }
    return (
      <IconWrapper>
        <Icon name={iconName} ariaLabel={ariaLabel}></Icon>
      </IconWrapper>
    );
  };

  const renderHelperText = () => {
    if (hasError) {
      return (
        <HelperText $hasError data-testid="errorText">
          <HelperIcon icon={faExclamationCircle} />
          {errorText}
        </HelperText>
      );
    }

    if (hasWarning) {
      return (
        <HelperText data-testid="warningText">
          <HelperIcon
            icon={faExclamationTriangle}
            color={Styles.uno.color.semantic.warn500}
          />
          {warningText}
        </HelperText>
      );
    }

    return (
      helperText && (
        <HelperText data-testid="helperText">{helperText}</HelperText>
      )
    );
  };

  const handleToggleDropdown = (open: boolean) => {
    if (open !== isOpen) {
      setIsOpen(open);
    }
  };

  return (
    <Wrapper
      $width={width}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $formStyle={form}
      ref={dropdownRef}
    >
      {renderFormLabel()}
      <ButtonWrapper
        className={className}
        data-testid={dataTestId}
        onClick={() => handleToggleDropdown(!isOpen)}
        disabled={disabled}
        $isOpen={isOpen}
        $formStyle={form}
        $hasError={hasError}
        $hasWarning={hasWarning}
      >
        {renderLabel()}
        <ValueWrapper $formStyle={form} $isPlaceholder={hasPlaceholderText}>
          {calcText()}
        </ValueWrapper>
        {renderIcon()}
      </ButtonWrapper>
      {isOpen && (
        <OptionsWrapper $width={dropdownOptionsWidth} $maxWidth={maxWidth}>
          <DropdownOptions
            dataTestId={`${dataTestId}-options`}
            items={itemsWithCurrentSelected}
            onValueChange={handleSelect}
            width={dropdownOptionsWidth}
            maxWidth={maxWidth}
            height={popoverHeight}
            multiSelect={multiSelect}
            sidePadding={dropdownOptionsPadding}
          />
        </OptionsWrapper>
      )}
      {renderHelperText()}
    </Wrapper>
  );
};
