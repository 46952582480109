/* eslint-disable */
import React, { FunctionComponent, useState } from "react";
import { Wrapper } from "./styles";

export interface ISwitchProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  isDefaultChecked?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  small?: boolean;
}

export const Switch: FunctionComponent<ISwitchProps> = ({
  id,
  className,
  dataTestId = "switch",
  isDefaultChecked = false,
  onChange,
  small = false,
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = useState(isDefaultChecked);

  const handleClick = () => {
    onChange(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <Wrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      $isSmall={small}
      $isChecked={isChecked}
      onClick={handleClick}
      disabled={disabled}
    ></Wrapper>
  );
};
