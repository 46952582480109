/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent, useState, useEffect } from "react";
import { Icon } from "../../Icon/Icon";
import {
  Body,
  IconWrapper,
  Title,
  Wrapper,
  Text,
  CloseWrapper,
} from "./styles";

interface INotificationProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  title: string;
  text: string;
  type: "success" | "error" | "info" | "pending";
  duration?: number;
  hasClose?: boolean;
}

export const Notification: FunctionComponent<INotificationProps> = ({
  id,
  className,
  dataTestId = "notification",
  title,
  text,
  duration = 4500,
  type = "success",
  hasClose = true,
}) => {
  const [visible, setVisible] = useState(false);
  const [hasClosed, setHasClosed] = useState(false);

  const deleteNotification = () => {
    if (hasClose) {
      return setTimeout(() => {
        setHasClosed(true);
      }, 400);
    }

    return setTimeout(() => {
      setHasClosed(true);
    }, duration + 400);
  };

  const handleClose = () => {
    setVisible(false);
    deleteNotification();
  };

  useEffect(() => {
    let disappearTimeout, deleteTimeout;

    if (!hasClose) {
      disappearTimeout = setTimeout(() => {
        setVisible(false);
      }, duration);
      deleteTimeout = deleteNotification();
    }
    setVisible(true);

    return () => {
      disappearTimeout && clearTimeout(disappearTimeout);
      deleteTimeout && clearTimeout(deleteTimeout);
    };
  }, []);

  const renderIcon = () => {
    switch (type) {
      case "success":
        return (
          <Icon
            ariaLabel="Success"
            dataTestId="successIcon"
            name="faCheck"
          ></Icon>
        );
      case "error":
        return (
          <Icon
            ariaLabel="Error"
            dataTestId="errorIcon"
            name="faExclamation"
          ></Icon>
        );
      case "info":
        return <Icon ariaLabel="Info" dataTestId="infoIcon" name="faInfo" />;
      case "pending":
        return (
          <Icon
            ariaLabel="Pending"
            dataTestId="pendingIcon"
            name="faStopwatch"
            variant="pro"
          />
        );
      default:
        return <Icon ariaLabel="default" name="faCheck"></Icon>;
    }
  };

  const renderCloseButton = () => {
    if (hasClose) {
      return (
        <CloseWrapper onClick={handleClose} data-testid="notification-close">
          <Icon ariaLabel="Close Button" variant="light" name="faTimes" />
        </CloseWrapper>
      );
    }
  };

  return (
    <Wrapper
      id={id}
      className={className}
      data-testid={dataTestId}
      $visible={visible}
      $hasClosed={hasClosed}
    >
      <IconWrapper $type={type}>{renderIcon()}</IconWrapper>
      <Body>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Body>
      {renderCloseButton()}
    </Wrapper>
  );
};
