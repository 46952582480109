/* eslint-disable */
// @ts-nocheck
import React, {
  FunctionComponent,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import { TableHeader } from "./TableHeader/TableHeader";
import { TablePopover } from "./TablePopover/TablePopover";
import { HideColumnsTablePopover } from "./templates/HideColumnsTablePopover/HideColumnsTablePopover";
import { ActionLinksTablePopover } from "./templates/ActionLinksTablePopover/ActionLinksTablePopover";
import { EmptyTableTemplate } from "./templates/EmptyTableTemplate/EmptyTableTemplate";
import { Icon } from "../Icon/Icon";
import { Checkbox } from "../Checkbox/Checkbox";
import { Tooltip } from "../Tooltip/Tooltip";
import { useHover } from "../../hooks/useHover";
import { Button } from "../Button/Button";
import {
  ActionCell,
  Cell,
  Container,
  HeaderRow,
  HideableWrapper,
  HideColumn,
  LoadingAction,
  LoadingCell,
  Overlay,
  Row,
  ScrollWrapper,
  SelectAll,
  TableWrapper,
  TooltipWrapper,
  Wrapper,
} from "./styles";

interface IDimensions {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  x?: number;
  y?: number;
  right?: number;
  bottom?: number;
}

interface IActivePopover {
  id: string;
  actions: any[];
  dimensions?: IDimensions;
}

export interface ITableHeader {
  label: string;
  id: string;
  width?: string;
  dataTestId?: string;
  hideDisabled?: boolean;
  sortASC?: Function;
  sortDES?: Function;
  clearSort?: Function;
}

export interface ITableData {
  id: string;
  value: any;
}

export interface ITableRow {
  id: string;
  checkboxDisabled?: boolean;
  isDefaultChecked?: boolean;
  actions?: any[]; // TODO: change actions type
  columns: ITableData[];
}

export interface TableSelection {
  id: string;
  isSelected: boolean;
}

export interface ITableProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  columns: ITableHeader[];
  data: ITableRow[];
  width?: string;
  hideColumns?: boolean;
  maxHeight?: string;
  defaultHiddenColumns?: string[];
  selectable?: boolean;
  onSelect?: (items: TableSelection[]) => void;
  fixedColumns?: number;
  actionPopoverWidth?: string;
  rightFixed?: boolean;
  loading?: boolean;
  emptyTemplate?: ReactNode;
  hideShadow?: boolean;
  onVisibleColumnsChange?: (columnIds: string[]) => void;
  singleSelect?: boolean;
}
export const Table: FunctionComponent<ITableProps> = ({
  id,
  className,
  dataTestId = "table",
  columns,
  width,
  data,
  hideColumns = false,
  maxHeight,
  defaultHiddenColumns = [],
  selectable = false,
  onSelect,
  fixedColumns = 0,
  actionPopoverWidth,
  rightFixed,
  loading = false,
  emptyTemplate,
  hideShadow = false,
  onVisibleColumnsChange,
  singleSelect = false,
}) => {
  const [activeSort, setActiveSort] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns);
  const [isHideColumnsPopoverActive, setIsHideColumnsPopoverActive] = useState(
    false
  );
  const [
    activeActionPopover,
    setActiveActionPopover,
  ] = useState<IActivePopover>({ id: "", actions: [] });
  const [selectedItems, setSelectedItems] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const [resizeToggle, setResizeToggle] = useState(false);
  const { isHovered, handleHover, setHover } = useHover();
  const tableRef = useRef<HTMLDivElement>();
  const visibleTableRef = useRef<HTMLDivElement>();
  const [hoveredRow, setHoveredRow] = useState("");

  const handleActiveSort = (id: string) => {
    setActiveSort(id);
  };

  const handleResize = () => {
    setResizeToggle((resizeToggle) => !resizeToggle);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!activeActionPopover.id || !isHideColumnsPopoverActive) setHover(false);
  }, [activeActionPopover.id, isHideColumnsPopoverActive]);

  useEffect(() => {
    if (!selectable) {
      setSelectedItems([]);
      return;
    }

    const preselectedData = data
      .filter((row) => row.isDefaultChecked && !row.checkboxDisabled)
      .map(({ id }) => id);

    if (preselectedData === selectedItems) return;

    setSelectedItems(preselectedData);

    // we disable action menu when in select mode
    if (preselectedData.length) closeActionPopover();
  }, [data, selectable]);

  const handleColumnWidth = (column: { id: string; width: number }) => {
    // check if id exists in column width - this prevents duplicate ids in array
    if (columnWidths.some(({ id }) => id === column.id)) {
      // id is in column widths

      // get the index of the column with matching index, so we can update this
      const indexOfColumn = columnWidths.findIndex(
        ({ id }) => id === column.id
      );

      if (columnWidths[indexOfColumn].width !== column.width) {
        //  if the width is different update the width
        let updatedColumnWidths = [...columnWidths];
        updatedColumnWidths[indexOfColumn].width = column.width;
        return setColumnWidths(updatedColumnWidths);
      }
      return;
    }
    // add index to the column so we can sort it in the table header
    const index = columns.findIndex((col) => col.id === column.id);
    setColumnWidths((columnWidths) => [...columnWidths, { ...column, index }]);
  };

  const handleSelectAll = (isSelected: boolean): void => {
    closeActionPopover();

    const IDs = data
      .filter(({ checkboxDisabled }) => !checkboxDisabled)
      .map(({ id }) => id);

    const selectItems: TableSelection[] = IDs.map((id) => {
      return {
        id,
        isSelected,
      };
    });
    onSelect(selectItems);
  };

  const handleSelectRow = (id: string) => (isSelected: boolean): void => {
    onSelect([
      {
        id,
        isSelected,
      },
    ]);
  };

  const handleSingleSelectRow = (id: string) => (): void => {
    onSelect([
      {
        id: id,
        isSelected: true,
      },
      ...selectedItems.map((s) => ({ id: s.id, isSelected: false })),
    ]);
  };

  const handleActionRow = (id: string, actions) => ({
    currentTarget,
  }: MouseEvent) => {
    if (selectedItems.length) {
      return;
    }

    if (activeActionPopover.id === id) {
      return closeActionPopover();
    }

    const dimensions = (currentTarget as HTMLElement).getBoundingClientRect();
    setActiveActionPopover({ id, actions, dimensions });
  };

  const handleAction = (buttonAction: () => void) => {
    closeActionPopover();
    buttonAction();
  };

  const renderOverlay = () => {
    if (activeActionPopover.id || isHideColumnsPopoverActive) {
      return <Overlay data-testid="popoverOverlay" onClick={handlePopover()} />;
    }
  };

  const renderSelectAll = () => {
    const isChecked =
      data
        .filter(({ checkboxDisabled }) => !checkboxDisabled)
        .map(({ id }) => id).length === selectedItems.length &&
      selectedItems.length !== 0;

    return (
      selectable && (
        <SelectAll $isFixed={fixedColumns > 0} $singleSelect={singleSelect}>
          {!singleSelect && (
            <Checkbox
              dataTestId="selectAllCheckbox"
              onChange={handleSelectAll}
              isDefaultChecked={isChecked}
              disabled={loading}
              label="Select all"
              hideLabel
            />
          )}
        </SelectAll>
      )
    );
  };

  const renderHeader = () => {
    // only render headers which are visible
    const visibleColumns = columns.filter(
      ({ id }) => !hiddenColumns.includes(id)
    );
    return visibleColumns.map(
      (
        { label, id, width, dataTestId, sortASC, sortDES, clearSort },
        index
      ) => {
        return (
          <TableHeader
            dataTestId={dataTestId}
            label={label}
            id={id}
            key={id}
            width={width}
            handleActiveSort={handleActiveSort}
            activeSort={id === activeSort}
            isSelectable={selectable}
            sortASC={sortASC}
            sortDES={sortDES}
            clearSort={clearSort}
            isFixed={fixedColumns > index}
            handleColumnWidth={handleColumnWidth}
            columnWidths={columnWidths}
            fixedColumns={fixedColumns}
            loading={loading}
            hasData={!!data.length}
          />
        );
      }
    );
  };

  const renderHideColumnsPopover = () => {
    if (isHideColumnsPopoverActive) {
      const columnList = columns.map(({ label, hideDisabled = false, id }) => ({
        id,
        label,
        hideDisabled,
      }));
      return (
        <TablePopover right={getTableRightPosition()}>
          <HideColumnsTablePopover
            columns={columnList}
            onApply={handleHiddenColumns}
            onReset={clearHiddenColumns}
            hiddenColumns={hiddenColumns}
          />
        </TablePopover>
      );
    }
  };

  const renderHideColumnIcon = () => {
    const hasActions = data.some(({ actions }) => actions && !!actions.length);

    if (hideColumns) {
      return (
        <HideColumn
          $isFixed={rightFixed}
          $overlayActive={isHideColumnsPopoverActive}
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
        >
          <Icon
            dataTestId="hideColumnsIcon"
            ariaLabel="hide columns"
            name="faColumns"
            padding="4px"
            onClick={handlePopover("hideColumns")}
          />
        </HideColumn>
      );
    }

    if (!hideColumns && hasActions) {
      return <HideColumn $isFixed={rightFixed} />;
    }
  };

  const checkSelected = (id: string) => {
    return selectedItems.includes(id);
  };

  const renderRows = () => {
    const rows = data.map(({ columns, id, actions, checkboxDisabled }) => {
      const rowId = id;
      const isChecked = checkSelected(id);
      const visibleColumns = columns.filter(({ id }) => {
        return !hiddenColumns.includes(id);
      });

      const renderActionCell = () => {
        const hasActions = actions && !!actions.length;

        if (loading && (hasActions || hideColumns)) {
          return (
            <ActionCell
              $isFixed={rightFixed}
              data-testid="loadingActionCell"
              $isSelected={isChecked}
            >
              <LoadingAction />
            </ActionCell>
          );
        }

        const isPopoverActive = activeActionPopover.id === id;

        if (hasActions || hideColumns) {
          return (
            <ActionCell
              data-testid={`action-cell-${id}`}
              $isFixed={rightFixed}
              $overlayActive={isPopoverActive}
              $isSelected={isChecked}
              disabled={!!selectedItems.length}
            >
              {hasActions && (
                <Icon
                  name="faEllipsisV"
                  ariaLabel="Actions"
                  dataTestId={`action-cell-${id}-icon`}
                  onClick={handleActionRow(id, actions)}
                />
              )}
            </ActionCell>
          );
        }
      };

      const rowProps = singleSelect
        ? {
            onMouseEnter: () => setHoveredRow(id),
            onMouseLeave: () => setHoveredRow(""),
          }
        : {};

      return (
        <Row key={id} {...rowProps}>
          {selectable ? (
            <Cell
              $isFixed={fixedColumns > 0}
              $leftPosition={0}
              $isSelected={checkSelected(rowId)}
            >
              {singleSelect ? (
                <HideableWrapper
                  data-testid={`rowSelect-${id}`}
                  $isHighlighted={hoveredRow === id || checkSelected(rowId)}
                >
                  <Button onClick={handleSingleSelectRow(id)} label="Select" />
                </HideableWrapper>
              ) : (
                <Checkbox
                  dataTestId={`rowCheckbox-${id}`}
                  onChange={handleSelectRow(id)}
                  isDefaultChecked={isChecked}
                  disabled={loading || checkboxDisabled}
                  label="Select row"
                  hideLabel
                />
              )}
            </Cell>
          ) : null}
          {visibleColumns.map(({ value, id }, index) => {
            const isFixed = fixedColumns > index;
            const rightBorder = index + 1 === fixedColumns;
            const leftPosition = columnWidths.reduce((acc, val, arrIndex) => {
              return arrIndex < index ? acc + val.width : acc;
            }, 0);
            return (
              <Cell
                $isSelected={checkSelected(rowId)}
                $isSelectable={selectable}
                $rightBorder={rightBorder}
                $leftPosition={leftPosition}
                $isFixed={isFixed}
                key={id}
              >
                {loading ? <LoadingCell data-testid="loadingCell" /> : value}
              </Cell>
            );
          })}
          {renderActionCell()}
        </Row>
      );
    });

    // add in loading rows if no rows exist and loading is true
    if (!rows.length && loading) {
      let i = 0;
      let loadingRows = [];

      const visibleColumns = columns.filter(({ id }) => {
        return !hiddenColumns.includes(id);
      });

      while (i <= 4) {
        loadingRows.push(
          <Row data-testid="loadingRow" key={`loadingRow-${i}`}>
            {selectable ? (
              <Cell>
                <Checkbox
                  label="select row"
                  hideLabel
                  disabled
                  onChange={handleSelectRow(i.toString())}
                />
              </Cell>
            ) : null}
            {visibleColumns.map(({ id }, index) => {
              const isFixed = fixedColumns > index;
              const rightBorder = index + 1 === fixedColumns;
              const leftPosition = columnWidths.reduce((acc, val, arrIndex) => {
                return arrIndex < index ? acc + val.width : acc;
              }, 0);

              return (
                <Cell
                  $isSelectable={selectable}
                  $rightBorder={rightBorder}
                  $leftPosition={leftPosition}
                  $isFixed={isFixed}
                  key={id}
                >
                  <LoadingCell />
                </Cell>
              );
            })}
            {hideColumns ? <Cell /> : null}
          </Row>
        );
        i++;
      }
      return loadingRows;
    }

    // empty state for the table
    if (!rows.length && !loading) {
      const numberOfColumns = columns.filter(
        ({ id }) => !hiddenColumns.includes(id)
      ).length;
      const colSpan = selectable ? numberOfColumns + 1 : numberOfColumns;
      return (
        <tr data-testid="emptyTable">
          <td colSpan={colSpan}>
            {emptyTemplate ? emptyTemplate : <EmptyTableTemplate />}
          </td>
        </tr>
      );
    }

    return rows;
  };

  const handlePopover = (popover?: string) => () => {
    if (popover === "hideColumns") {
      return setIsHideColumnsPopoverActive((isActive) => !isActive);
    }

    setIsHideColumnsPopoverActive(false);
    return closeActionPopover();
  };

  const closeActionPopover = () => {
    setActiveActionPopover({ id: "", actions: [] });
  };

  const updateHiddenColumns = (newHiddenColumnsSelected: string[]) => {
    setHiddenColumns(newHiddenColumnsSelected);

    const visibleColumns = columns
      .filter(({ id }) => !newHiddenColumnsSelected.includes(id))
      .map(({ id }) => id);

    onVisibleColumnsChange && onVisibleColumnsChange(visibleColumns);
  };

  const handleHiddenColumns = (newHiddenColumnsSelected: string[]) => {
    // this filters the column width and returns a new array wwhere any id cannot match the ids returned from hidden columns
    const updatedColumnWidths = columnWidths.filter(({ id }) => {
      return !newHiddenColumnsSelected.some((column) => id === column);
    });
    setColumnWidths(updatedColumnWidths);
    setIsHideColumnsPopoverActive(false);

    updateHiddenColumns(newHiddenColumnsSelected);
  };

  const clearHiddenColumns = () => {
    setIsHideColumnsPopoverActive(false);

    updateHiddenColumns(defaultHiddenColumns);
  };

  const getTableRightPosition = () => {
    if (visibleTableRef.current) {
      const {
        right: tableRight,
      } = tableRef.current.getBoundingClientRect() as IDimensions;
      const {
        right: visibleTableRight,
      } = visibleTableRef.current.getBoundingClientRect() as IDimensions;
      return tableRight - visibleTableRight;
    }
  };

  const renderActionsPopover = () => {
    if (activeActionPopover.id) {
      const {
        y: tableY,
      } = tableRef.current.getBoundingClientRect() as IDimensions;
      const actionCellX = getTableRightPosition();
      const actionCellY = activeActionPopover.dimensions.y - tableY + 13;
      return (
        <TablePopover
          width={actionPopoverWidth}
          top={actionCellY}
          right={actionCellX}
        >
          <ActionLinksTablePopover
            onButtonClick={handleAction}
            buttons={activeActionPopover.actions}
          />
        </TablePopover>
      );
    }
  };

  return (
    <Container ref={tableRef}>
      <TooltipWrapper $right={getTableRightPosition() + 88}>
        <Tooltip text="Hide columns" visible={isHovered} width="106px" />
      </TooltipWrapper>
      <ScrollWrapper
        $width={width}
        $hideShadow={hideShadow}
        ref={visibleTableRef}
      >
        <Wrapper $maxHeight={maxHeight}>
          <TableWrapper id={id} className={className} data-testid={dataTestId}>
            <thead>
              <HeaderRow>
                {renderSelectAll()}
                {renderHeader()}
                {renderHideColumnIcon()}
              </HeaderRow>
            </thead>
            <tbody>{renderRows()}</tbody>
          </TableWrapper>
        </Wrapper>
      </ScrollWrapper>
      {renderOverlay()}
      {renderHideColumnsPopover()}
      {renderActionsPopover()}
    </Container>
  );
};
