import React, { useState, useEffect, useRef, ReactElement } from "react";
import { TextWrapper, TooltipContainer } from "./styles";

export interface TooltipProps {
  id?: string;
  className?: string;
  dataTestId?: string;

  text: string | ReactElement<HTMLParagraphElement>;
  arrowDirection?: "top" | "right" | "bottom" | "left" | "";
  visible?: boolean;
  arrowPlacement?: number;
  width?: string;
  maxWidth?: string;
}

function Tooltip({
  id,
  className,
  dataTestId = "tooltip",
  text,
  arrowDirection = "",
  arrowPlacement,
  visible,
  width,
  maxWidth,
}: TooltipProps) {
  const [arrowPosition, setArrowPosition] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const calcArrowPosition = () => {
    if (!ref.current) {
      return 0;
    }

    const x = ref.current.offsetWidth - 16;
    const y = ref.current.offsetHeight - 16;
    const isVertical = arrowDirection === "top" || arrowDirection === "bottom";
    const isHorizontal =
      arrowDirection === "left" || arrowDirection === "right";

    // When no value is passed in, arrow will be centered
    if (!arrowPlacement) {
      const centerX = x / 2;
      const centerY = y / 2;
      return isVertical ? centerX : centerY;
    }

    const isNegative = arrowPlacement < 0;

    if (isNegative) {
      return 0;
    }

    if (isVertical) {
      return arrowPlacement > x ? x : arrowPlacement;
    }

    if (isHorizontal) {
      return arrowPlacement > y ? y : arrowPlacement;
    }
    return 0;
  };

  useEffect(() => {
    setArrowPosition(calcArrowPosition());
    // TODO: resolve useEffect dependency issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowPlacement, arrowDirection, width, maxWidth]);

  return (
    <TooltipContainer
      id={id}
      className={className}
      data-testid={dataTestId}
      ref={ref}
      $visible={visible}
      $arrowPlacement={arrowPosition}
      $arrowDirection={arrowDirection}
      $width={width}
      $maxWidth={maxWidth}
    >
      <TextWrapper data-testid={`${dataTestId}-content`}>{text}</TextWrapper>
    </TooltipContainer>
  );
}

export { Tooltip };
