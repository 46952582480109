/* eslint-disable */
import styled, { css } from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";
import { DropdownOptions } from "../Dropdown/DropdownOptions/DropdownOptions";

const blueBorder = `${Styles.colors.blue500}${Styles.colors.opacity50}`;
export type ButtonType =
  | "primary"
  | "secondary"
  | "text"
  | "dropdown"
  | "dropdown-action";

interface WrapperProps {
  $btnType: ButtonType;
  $isDanger?: boolean;
  $width?: string;
  $maxWidth?: string;
  $minWidth?: string;
  $loading?: boolean;
}

const Wrapper = styled.button<WrapperProps>`
  width: ${({ $width }) => $width || "auto"};
  max-width: ${({ $maxWidth }) => $maxWidth || "none"};
  min-width: ${({ $minWidth }) => $minWidth || "0"};
  border: 2px solid transparent;
  border-radius: ${Styles.borderRadius.s};
  padding: 10px 16px;
  font-family: ${Styles.fonts.primary};
  font-size: ${Styles.fontSizes.s3};
  color: ${Styles.colors.white};
  cursor: pointer;
  outline: 0;
  transition: 0.1s;
  white-space: nowrap;
  z-index: 1;
  position: relative;
  &:active {
    transform: scale(0.96);
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: 0 0 0 2px ${Styles.colors.white}, 0 0 0 4px ${blueBorder};
  }

  ${({ $btnType, $isDanger, $loading }) => {
    if ($btnType === "primary" || $btnType === "dropdown") {
      return css`
        background-color: ${$isDanger
          ? Styles.uno.color.semantic.negative500
          : Styles.colors.blue500};

        &:focus,
        &:hover {
          background-color: ${$isDanger
            ? Styles.uno.color.semantic.negative700
            : Styles.colors.blue700};
        }
      `;
    } else if ($btnType === "dropdown-action") {
      return css`
        display: grid;
        background-color: ${Styles.colors.blue500};

        ${$loading &&
        css`
          padding: 10px 32px;
        `}

        ${!$loading &&
        css`
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `}

        &:focus,
        &:hover {
          background-color: ${Styles.colors.blue700};
        }
        &:active {
          transform: scale(1);
        }
      `;
    } else if ($btnType === "secondary") {
      return css`
        background-color: ${Styles.colors.neutral100};
        border: 2px solid ${Styles.colors.neutral300}${Styles.colors.opacity40};
        color: ${$isDanger
          ? Styles.uno.color.semantic.negative700
          : Styles.colors.neutral700};

        &:focus,
        &:hover,
        &:active {
          color: ${$isDanger
            ? Styles.uno.color.semantic.negative700
            : Styles.colors.neutral900};
        }
      `;
    } else if ($btnType === "text") {
      return css`
        padding: 0;
        background-color: transparent;
        text-decoration: underline;
        color: ${$isDanger
          ? Styles.uno.color.semantic.negative500
          : Styles.colors.neutral700};

        &:focus,
        &:hover,
        &:active {
          color: ${$isDanger
            ? Styles.uno.color.semantic.negative700
            : Styles.colors.blue500};
        }
        &:disabled {
          color: ${Styles.colors.neutral500};
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      `;
    }
  }}
`;

const IconWrapper = styled.span`
  padding-left: 16px;
`;

interface IconLabelProps {
  $withLabel?: boolean;
}

const IconLabel = styled.span<IconLabelProps>`
  padding-right: ${({ $withLabel }) => ($withLabel ? "8px" : "")};
`;

interface OptionsWrapperProps {
  $width?: string;
  $type?: ButtonType;
}

const OptionsWrapper = styled.div<OptionsWrapperProps>`
  z-index: 1;
  position: absolute;
  top: 45px;
  right: -2px;
  width: ${({ $width }) => $width || "auto"};
  display: grid;
  justify-content: end;
  color: ${Styles.colors.neutral900};
  ${({ $type }) =>
    $type === "dropdown-action" &&
    css`
      right: -34px;
    `}
`;

const CustomDropdownOptions = styled(DropdownOptions)`
  position: relative;
`;

interface OverlayProps {
  $active: boolean;
}

const Overlay = styled.div<OverlayProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  display: ${({ $active }) => ($active ? "block" : "none")};
`;

interface ActionButtonProps {
  $width?: string;
  $loading?: boolean;
}

const ActionButton = styled.button`
  border: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-left: 1px solid ${Styles.colors.blue700}${Styles.colors.opacity50};
  color: ${Styles.colors.white};
  background-color: ${Styles.colors.blue500};
  background-color: ${({ disabled }) =>
    disabled ? Styles.colors.neutral300 : Styles.colors.blue500};
  width: 30px;
  padding: 12px 16px;
  border-top-right-radius: ${Styles.borderRadius.s};
  border-bottom-right-radius: ${Styles.borderRadius.s};
  z-index: 1;
  cursor: pointer;
  &:focus {
    background-color: ${Styles.colors.blue700};
    box-shadow: 0 0 0 2px ${Styles.colors.white}, 0 0 0 4px ${blueBorder};
  }
  &:hover {
    background-color: ${Styles.colors.blue700};
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      background-color: ${Styles.colors.neutral300};
    }
  }
`;

const ActionButtonWrapper = styled.div<ActionButtonProps>`
  width: auto;
  display: grid;
  grid-template-columns: ${({ $loading }) =>
    $loading ? "max-content" : "max-content 30px"};

  ${({ $width, $loading }) =>
    $width !== "auto" &&
    css`
      width: ${$width};
      grid-template-columns: ${$loading
        ? $width
        : `calc(${$width} - 33px) 30px`};
    `}
`;

const LoadingText = styled.span`
  margin-right: 8px;
`;

const LoadingContainer = styled.div`
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingInnerContainer = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
`;

interface LabelSpaceWrapperProps {
  $type: ButtonType;
  $visibile: boolean;
}

const LabelSpaceWrapper = styled.span<LabelSpaceWrapperProps>`
  width: auto;
  visibility: ${({ $visibile }) => ($visibile ? "visible" : "hidden")};

  ${({ $type, $visibile }) =>
    $type === "dropdown" &&
    !$visibile &&
    css`
      margin-right: 25px;
    `}
`;

export {
  Wrapper,
  IconWrapper,
  IconLabel,
  OptionsWrapper,
  Overlay,
  ActionButton,
  ActionButtonWrapper,
  LoadingText,
  LoadingContainer,
  LoadingInnerContainer,
  LabelSpaceWrapper,
  CustomDropdownOptions,
};
