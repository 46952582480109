import { FunctionComponent, ReactElement, useEffect, useState, useRef } from "react";
import { Notifications } from "@integrate/hedgehogger";
import { OrgDataType, OrgService, OrgsType } from "../common/services/OrgService";
import {
  DataTable,
  IDataTableParams,
  IParsedDataTableRow,
  DefaultDataTableParams,
  IBulkActionOptions,
  TableSelection,
} from "../dataTable/DataTable";
import { NotificationHelper } from "../common/helpers/NotificationHelper";
import { useHistory } from "react-router";
import { TableHelper } from "../common/helpers/TableHelper";

interface OrgsTableProps {
  onSelectOrgs?: (orgs: OrgDataType[]) => void;
  bulkActionOptions?: IBulkActionOptions;
  handleDelete?: () => void;
  handleChangeClaims?: () => void;
  maxHeight?: string;
  disableLinks?: boolean;
  button?: ReactElement;
  refresh?: number;
}

export const OrgsTable: FunctionComponent<OrgsTableProps> = ({
  onSelectOrgs,
  bulkActionOptions,
  maxHeight,
  disableLinks,
  button,
  refresh,
}) => {
  const [notifications, setNotifications]: any[] = useState([]);
  const [data, setData] = useState<IParsedDataTableRow[]>([]);
  const [params, setParams] = useState<IDataTableParams>(DefaultDataTableParams);
  const [totalOrgs, setTotalOrgs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<OrgsType>();
  const [selectedOrgs, setSelectedOrgs] = useState<OrgDataType[]>([]);
  const history = useHistory();
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    }
  }, []);

  const getOrgs = async () => {
    try {
      setLoading(true);
      const results = await OrgService.getOrgs(params);

      if (!results) {
        throw new Error("error getting orgs");
      }

      if (mountedRef.current) {
        setResults(results);
        setTotalOrgs(results.totalCount);
      }
    } catch (e) {
      if (mountedRef.current) {
        setNotifications([NotificationHelper.error("Unable to get orgs, please try again.")]);
      }
    }

    if (mountedRef.current) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrgs();
  }, [params.search, params.skip, params.sortBy, params.sortOrder, params.take, refresh]);

  useEffect(() => {
    let parsedRows;

    if (results) {
      if (disableLinks) {
        parsedRows = OrgService.transformOrgsToRows(results, selectedOrgs);
      } else {
        parsedRows = OrgService.transformOrgsToRows(results, selectedOrgs, handleEditOrg);
      }

      setData(parsedRows);
    }

  }, [selectedOrgs, results]);

  const handleEditOrg = (orgId: string) => {
    history.push(`/orgs/${orgId}`);
  };

  const handleChangeParams = (newParams: IDataTableParams) => {
    setParams(newParams);
  };

  const handleSetSelectedItems = (selectedItems: TableSelection[]) => {
    const newSelection = TableHelper.calcSelectedRows(selectedOrgs, selectedItems, results?.organizations ?? []);
    setSelectedOrgs(newSelection);
    if (onSelectOrgs) {
      onSelectOrgs(newSelection);
    }
  };

  return (
    <div>
      <DataTable
        name="Orgs"
        columns={OrgService.tableHeaderColumns}
        data={data}
        onChangeParams={handleChangeParams}
        totalAmount={totalOrgs}
        loading={loading}
        onSelectItems={handleSetSelectedItems}
        bulkActionOptions={bulkActionOptions}
        maxHeight={maxHeight}
        button={button}
        numSelectedItems={selectedOrgs.length}
      ></DataTable>
      <Notifications notifications={notifications} duration={4000} />
    </div>
  );
};
