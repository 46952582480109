import styled from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";

export enum Status {
  Draft = "draft",
  Scheduled = "scheduled",
  Live = "live",
  Done = "done",
  Ended = "ended",
  Archived = "archived",
}

export const getBackgroundColor = (statusType: string | undefined) => {
  switch (statusType) {
    case Status.Draft:
      return Styles.uno.color.accent.yellow100;
    case Status.Scheduled:
      return Styles.uno.color.accent.purple100;
    case Status.Live:
    case Status.Done:
      return Styles.uno.color.accent.green100;
    case Status.Ended:
      return Styles.uno.color.accent.red100;
    case Status.Archived:
      return Styles.uno.color.neutral.warm100;
    default:
      return Styles.uno.color.accent.blue100;
  }
};

interface ContainerProps {
  $color?: string;
  $backgroundColor?: string;
  $status?: string;
  $small?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-basis: 0;
  width: fit-content;
  gap: 8px;
  margin: 2px;
  padding: ${({ $small }) => ($small ? "0px 7px" : "7px 12px")};
  font-size: ${({ $small }) => Styles.uno.fontSize[$small ? "xxs" : "xs"]};
  border-radius: ${Styles.uno.radius.xl};
  background-color: ${({ $status, $backgroundColor }) =>
    $backgroundColor || getBackgroundColor($status)};
  font-family: ${Styles.uno.fontFamily.primary};
  color: ${({ $color }) => $color || Styles.uno.color.text.darkPrimary};
  text-transform: uppercase;
  font-weight: ${Styles.uno.fontWeight.medium};
  letter-spacing: 0.08em;
  line-height: 1;
`;

const BadgeGridContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 100px 100px 100px 100px;
  grid-column-gap: 16px;
`;

export { Container, BadgeGridContainer };
