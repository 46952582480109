import styled, { css } from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";

interface AvatarProps {
  $size?: "s" | "m" | "l";
  $border?: boolean;
  $icon?: boolean;
  $className?: string;
  $color?: string;
  $backgroundColor?: string;
  $fontSize?: string;
  $borderRadius?: string;
}

const Container = styled.div<AvatarProps>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || Styles.uno.color.neutral.cool100};
  color: ${({ $color }) => $color || Styles.uno.color.neutral.warm500};
  border-radius: ${({ $borderRadius }) => $borderRadius || "5px"};
  border: ${({ $border }) =>
    $border ? `1px solid ${Styles.uno.color.core.white}` : "none"};
  font-family: ${Styles.uno.fontFamily.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $size, $fontSize }) => {
    if ($size === "s") {
      return css`
        font-size: ${$fontSize || Styles.uno.fontSize.xs};
        height: 24px;
        width: 24px;
      `;
    }
    if ($size === "m") {
      return css`
        font-size: ${$fontSize || Styles.uno.fontSize.s};
        height: 32px;
        width: 32px;
      `;
    }
    return css`
      font-size: ${$fontSize || Styles.uno.fontSize.m};
      height: 40px;
      width: 40px;
      font-weight: ${Styles.uno.fontWeight.medium};
    `;
  }}
`;

const Image = styled.img`
  height: 100%;
  border-radius: 50%;
`;

const Text = styled.span<AvatarProps>`
  text-transform: uppercase;
  ${({ $size, $fontSize }) =>
    $size === "s" && !$fontSize ? `font-size: ${Styles.uno.fontSize.xs};` : ""}
`;

export { Container, Image, Text };
