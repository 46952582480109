/* eslint-disable */
import React, { FunctionComponent } from "react";
import { Checkbox } from "../../Checkbox/Checkbox";
import {
  ItemsList,
  ItemText,
  ItemWrapper,
  List,
  ListHeader,
  ListItem,
} from "./styles";
import { DropdownItem } from "../types";

export interface DropdownOptionsProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  onValueChange: Function;
  items: DropdownItem[];
  width?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  multiSelect?: boolean;
  sidePadding?: string;
}

export const DropdownOptions: FunctionComponent<DropdownOptionsProps> = ({
  id,
  className,
  dataTestId = "dropdown",
  items,
  multiSelect = false,
  minWidth = "90px",
  width,
  maxWidth,
  height = "220px",
  onValueChange,
  sidePadding = "8px",
}) => {
  const renderItems = (items: DropdownItem[]) =>
    items.map(
      ({
        disabled,
        value,
        id,
        isSelected = false,
        isDanger = false,
        items: nestedItems,
      }: DropdownItem) => {
        const handleSelect = () => {
          if (!disabled) {
            return onValueChange({ id, value });
          }
        };

        const renderMultiSelectItem = () => {
          return (
            <ItemText>
              <Checkbox
                isDefaultChecked={isSelected}
                label={value}
                disabled={disabled}
                maxWidth={width}
                onChange={handleSelect}
              />
            </ItemText>
          );
        };

        const renderSingleSelectItem = () => {
          return (
            <ItemText onClick={handleSelect} className="item-text">
              {value}
            </ItemText>
          );
        };

        if (nestedItems) {
          return (
            <ListItem key={id} aria-label={value} $sidePadding={sidePadding}>
              <ListHeader>{value}</ListHeader>
              <List>{renderItems(nestedItems)}</List>
            </ListItem>
          );
        }
        return (
          <ItemWrapper
            key={id}
            data-testid={`${dataTestId}-item-${id}`}
            aria-label={value}
            $sidePadding={sidePadding}
            disabled={disabled}
            $isDanger={isDanger}
          >
            {multiSelect ? renderMultiSelectItem() : renderSingleSelectItem()}
          </ItemWrapper>
        );
      }
    );

  return (
    <ItemsList
      id={id}
      className={className}
      data-testid={dataTestId}
      $minWidth={minWidth}
      $width={width}
      $height={height}
      $maxWidth={maxWidth}
    >
      {renderItems(items)}
    </ItemsList>
  );
};
