import { FunctionComponent, useEffect } from "react";
import { SmallTableHeight } from "../../../shared/StyleConstants";
import { BulkH1, Centered } from "../../../shared/StyledComponents";
import { UsersTable } from "../../../users/UsersTable";
import { OrgDataType } from "../../services/OrgService";
import { UserDataType } from "../../services/UsersService";
import { BulkModes } from "../BulkModal/BulkModal";

interface ISelectUserProps {
  selectedItems: OrgDataType[] | undefined;
  mode: BulkModes;
  onChange: (items: UserDataType[]) => void;
  onValidityChanged: (valid: boolean) => void;
}

export const SelectUsers: FunctionComponent<ISelectUserProps> = ({
  selectedItems,
  mode,
  onChange,
  onValidityChanged,
}) => {
  useEffect(() => {
    onValidityChanged(false);
  }, []);

  const handleSetSelectedUsers = (users: UserDataType[]) => {
    onChange(users);
    onValidityChanged(users.length > 0);
  };

  const amountOfOrgs = selectedItems?.length ?? 0;
  const renderAssignText = () => {
    switch (mode) {
      case BulkModes.AssignUsers:
        return `Which users would you like to assign to ${amountOfOrgs} organizations?`;
      case BulkModes.UnassignUsers:
        return `Which users would you like to unassign from ${amountOfOrgs} organizations?`;
      default:
        return `Which users would you like to assign to ${amountOfOrgs} organizations?`;
    }
  };

  return (
    <div>
      <Centered>
        <BulkH1>
          {renderAssignText()}
        </BulkH1>
      </Centered>
      <UsersTable onSelectUsers={handleSetSelectedUsers} maxHeight={SmallTableHeight} disableLinks></UsersTable>
    </div>
  );
};
