/* eslint-disable */
import styled, { css } from "styled-components";
import { Styles } from "../../utils/styles/designTokens/styles";

interface IWrapperProps {
  $backgroundColor: string;
  $borderColor: string;
  $clickable: boolean;
  $maxWidth?: string;
}

const Wrapper = styled.div<IWrapperProps>`
  padding: 2px 4px;
  display: inline-block;
  border-radius: ${Styles.uno.radius.m};
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};

  ${({ $borderColor }) =>
    $borderColor &&
    css`
      border: 1px solid ${$borderColor};
    `}
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `}
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}
  user-select: none;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
`;

interface ILabelWrapperProps {
  $fontColor: string;
}

const LabelWrapper = styled.div<ILabelWrapperProps>`
  display: inline-block;
  font-family: ${Styles.fonts.primary};
  font-weight: ${Styles.fontWeights.medium};
  ${({ $fontColor }) =>
    $fontColor &&
    css`
      color: ${$fontColor};
    `}
  font-size: ${Styles.uno.fontSize.xxs};
  line-height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
  color: ${Styles.colors.text.darkPlaceholder};
  cursor: pointer;

  margin-left: 6px;
  font-size: ${Styles.fontSizes.s1};
  margin-top: 1px;

  span:hover {
    background: transparent;
  }
`;

export { Wrapper, ContentWrapper, LabelWrapper, IconWrapper };
