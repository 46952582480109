/* eslint-disable */
// @ts-nocheck
import React, { FunctionComponent } from "react";
import { useHover } from "../../../hooks/useHover";
import { Styles } from "../../../utils/styles/designTokens/styles";
import { Container } from "./styles";

interface IPaginationCellProps {
  className?: string;
  dataTestId?: string;
  onClick?: (e: Event) => void;
  isSelected?: boolean;
  disabled?: boolean;
  value: string;
}

export const PaginationCell: FunctionComponent<IPaginationCellProps> = ({
  className,
  dataTestId = "pagination-cell",
  isSelected,
  value,
  onClick,
  disabled = false,
}) => {
  const { isHovered, handleHover } = useHover();
  const ariaLabel = `${isSelected ? "Current page" : "Go to page"} ${value}`;
  const buttonWidth =
    value !== "..." && value.length > 2 ? (value.length - 2) * 8 + 32 : 32;
  const fontSize = buttonWidth > 32 ? Styles.fontSizes.s2 : Styles.fontSizes.s3;

  return (
    <Container
      className={className}
      data-testid={dataTestId}
      $isHovered={isHovered}
      $isSelected={isSelected}
      onClick={(e) => {
        onClick(e);
      }}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-current={isSelected}
      aria-disabled={disabled}
      $width={buttonWidth}
      $fontSize={fontSize}
    >
      {value}
    </Container>
  );
};
