/* eslint-disable */
import { Styles } from "../../utils/styles/designTokens/styles";
import styled, { css } from "styled-components";

interface IIsHoveredProp {
  $isHovered: boolean;
  $width?: string;
}

interface InputWrapperProps {
  $width?: string;
}

const Wrapper = styled.div`
  font-family: ${Styles.fonts.primary};
  font-size: 32.44px;
  white-space: nowrap;
  min-height: 52px;
`;

const ActiveWrapper = styled.div`
  border: 0;
  padding: 0 0 4px 0;
`;

const InactiveActiveWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  padding: 0 0 2px 0;
  &:focus {
    outline: none;
  }
`;

const InputWrapper = styled.input<InputWrapperProps>`
  font-family: ${Styles.fonts.primary};
  font-size: 32.44px;
  color: ${Styles.colors.text.darkPrimary};
  outline: 0;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  padding: 0;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
`;

const ValueWrapper = styled.div<IIsHoveredProp>`
  color: ${Styles.colors.text.darkPrimary};
  border-bottom-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}

  ${({ $isHovered }) =>
    $isHovered &&
    css`
      border: 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
      border-radius: 0px;
      padding: 0px;
      transition: border-bottom-color 0.17s ease-in;
    `}
`;

const IconWrapper = styled.div<IIsHoveredProp>`
  font-size: 22px;
  margin-top: 6px;
  margin-left: 12px;
  visibility: hidden;
  opacity: 0;

  ${({ $isHovered }) =>
    $isHovered &&
    css`
      visibility: visible;
      opacity: 0.2;
      transition: visibility 0s linear 0s, opacity 300ms ease-in;
    `}
`;

export {
  Wrapper,
  ActiveWrapper,
  InactiveActiveWrapper,
  InputWrapper,
  ValueWrapper,
  IconWrapper,
};
