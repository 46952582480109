/* eslint-disable */
import React, { FunctionComponent } from "react";
import { Notification } from "./Notification/Notification";
import { NotificationWrapper } from "./styles";

export interface INotification {
  text: string;
  type: "success" | "error" | "pending" | "info";
  title: string;
  dataTestId?: string;
  hasClose?: boolean;
}

export interface INotificationsProps {
  id?: string;
  className?: string;
  dataTestId?: string;
  notifications: Array<INotification>;
  duration?: number;
}

export const Notifications: FunctionComponent<INotificationsProps> = ({
  id,
  className,
  dataTestId = "notifications",
  notifications = [],
  duration = 4500,
}) => {
  const renderNotifications = () => {
    if (notifications.length) {
      return notifications.map(
        ({ text, type, title, hasClose = true }, index) => {
          return (
            <Notification
              title={title}
              duration={duration}
              hasClose={hasClose}
              text={text}
              type={type}
              key={`${index} ${text}`}
            />
          );
        }
      );
    }
  };

  return (
    <NotificationWrapper id={id} className={className} data-testid={dataTestId}>
      {renderNotifications()}
    </NotificationWrapper>
  );
};
