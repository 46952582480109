export interface INotification {
    title: string,
    type: "success" | "error" | "pending" | "info",
    text: string,
    hasClose: boolean,
}

export class NotificationHelper {
  static error = (msg: string): INotification => {
    const newNotification: INotification = {
      title: "Error",
      type: "error",
      text: msg,
      hasClose: false,
    }
    return newNotification;
  };

  static success = (msg: string): INotification => {
    const newNotification: INotification = {
      title: "Success",
      type: "success",
      text: msg,
      hasClose: false,
    }
    return newNotification;
  };
}
