import { FunctionComponent, ReactElement, useState, useEffect } from "react";
import { Wrapper, Centered, BulkH1 } from "../../../shared/StyledComponents";
import styled from "styled-components";
import { Styles, Checkbox, RadioButtonGroup } from "@integrate/hedgehogger";
import { ClaimDataType, ClaimsService } from "../../services/ClaimsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { DefaultDataTableParams } from "../../../dataTable/DataTable";
import { Notifications } from "@integrate/hedgehogger";
import { NotificationHelper } from "../../helpers/NotificationHelper";
import { UserDataType } from "../../services/UsersService";
import { OrgDataType } from "../../services/OrgService";
import { IRadioOption } from "@integrate/hedgehogger/lib/components/RadioButtonGroup/RadioButtonGroup";

export enum ClaimActions {
  Add,
  Remove,
  Replace,
}

const WarningWrapper = styled.div`
  div {
    display: inline-block;
    padding: 8px;
    margin: 16px 0;
    border-radius: 4px;
    font-weight: 500;
  }
`;

const YellowText = styled.div`
  background-color: ${Styles.colors.yellow100};
  color: ${Styles.colors.yellow700};
`;

const GreenText = styled.div`
  background-color: ${Styles.colors.green100};
  color: ${Styles.colors.green700};
`;

const RedText = styled.div`
  background-color: ${Styles.colors.red100};
  color: ${Styles.colors.red700};
`;

const CheckboxLayoutGrid = styled.div`
  align-items: start;
  justify-items: start;
  display: grid;
  grid-template-columns: 10% 20% 20% 50%;
`;

const ActionGrid = styled.div`
  align-items: start;
  justify-items: start;
  display: grid;
  grid-template-columns: 10% 20% 50% 15%;
  margin: 8px 0;
`;

const Checkbox1ColGroupGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  align-items: start;
  row-gap: 12px;
`;

const Checkbox2ColsGroupGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  align-items: start;
  row-gap: 12px;
  column-gap: 40px;
`;

const CheckboxGroup = styled.p`
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 12px;
`;

const HorizontalRadioGroupWrapper = styled.div`
  fieldset {
    div {
      margin-top: 0;
      grid-template-columns: auto auto auto;
    }
  }
`;

export type OnChange = (claims: ClaimDataType[]) => void;
export type OnValidityChanged = (isValid: boolean) => void;

interface ISelectClaimProps {
  selectedItems: ClaimDataType[];
  onChange: (items: ClaimDataType[]) => void;
  onValidityChanged: (valid: boolean) => void;
  onClaimActionChanged: (value: string) => void;
  claimAction: ClaimActions | null;
  users: UserDataType[];
  orgs: OrgDataType[];
}

export const SelectClaims: FunctionComponent<ISelectClaimProps> = ({
  selectedItems,
  onChange,
  onValidityChanged,
  onClaimActionChanged,
  claimAction,
  users,
  orgs,
}) => {
  const [isUserBuyer, setIsUserBuyer] = useState(false);
  const [isUserSeller, setIsUserSeller] = useState(false);
  const [isUserAgency, setIsUserAgency] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [featureClaims, setFeatureClaims] = useState<ClaimDataType[]>([]);
  const [radioOptions, setRadioOptions] = useState<IRadioOption[]>(ClaimsService.claimActionOptions);
  const [selectedFeatureClaims, setSelectedFeatureClaims] = useState<
    ClaimDataType[]
  >([]);
  const [notifications, setNotifications]: any[] = useState([]);

  const isOneUser = orgs.length === 1 && users.length === 1;

  useEffect(() => {
    if (isOneUser) {
      const buyer = hasClaim(selectedItems, "Buyer");
      const seller = hasClaim(selectedItems, "Seller");
      const agency = hasClaim(selectedItems, "Agency");
      const admin = hasClaim(selectedItems, "Admin");
  
      setIsUserBuyer(buyer);
      setIsUserSeller(seller);
      setIsUserAgency(agency);
      setIsUserAdmin(admin);
      const newOptions = [ClaimsService.claimActionOptions[1]];
      setRadioOptions(newOptions);
      onClaimActionChanged(newOptions[0].id);
    } else {
      setRadioOptions(ClaimsService.claimActionOptions);
    }

  }, [users, orgs])

  const hasClaim = (array: ClaimDataType[], key: string): boolean => {
    return array.some((element) => {
      return element.key === key;
    });
  };

  const findClaim = (
    array: ClaimDataType[],
    key: string
  ): ClaimDataType | undefined => {
    return array.find((element) => {
      return element.key === key;
    });
  };

  useEffect(() => {
    const getClaims = async () => {
      try {
        const params = { ...DefaultDataTableParams };
        params.take = 1000;
        const results = await ClaimsService.getClaims(params);

        if (!results) {
          setNotifications([
            NotificationHelper.error("Unable to get claims, please try again."),
          ]);
        }
        setFeatureClaims(results.claims);
      } catch (e) {
        setNotifications([
          NotificationHelper.error("Unable to get claims, please try again."),
        ]);
      }
    };

    setSelectedFeatureClaims([]);
    getClaims();
  }, [users, orgs])

  useEffect(() => {
    if (!selectedFeatureClaims.length) {
      const newClaims: ClaimDataType[] = [];
      selectedItems.forEach((claim) => {
        if (featureClaims.some((c) => c.key === claim.key)) {
          newClaims.push(claim);
        }
      });
      setSelectedFeatureClaims(newClaims);
    } else {
      setSelectedFeatureClaims(selectedFeatureClaims);
    }

    onValidityChanged(checkSelectionIsValid());
  }, [selectedItems, claimAction, featureClaims, claimAction]);

  useEffect(() => {
    const updatedClaims: ClaimDataType[] = [];

    if (isUserBuyer) {
      updatedClaims.push({
        key: "Buyer",
        label: "Buyer",
        id: "buyerId",
        description: "",
        stage: "",
      });
    }
    if (isUserSeller) {
      updatedClaims.push({
        key: "Seller",
        label: "Seller",
        id: "sellerId",
        description: "",
        stage: "",
      });
    }
    if (isUserAgency) {
      updatedClaims.push({
        key: "Agency",
        label: "Agency",
        id: "agencyId",
        description: "",
        stage: "",
      });
    }
    if (isUserAdmin) {
      updatedClaims.push({
        key: "Admin",
        label: "Admin",
        id: "adminId",
        description: "",
        stage: "",
      });
    }

    if (claimAction !== ClaimActions.Remove) {
      updatedClaims.push({
        key: "User",
        label: "User",
        id: "userId",
        description: "",
        stage: "",
      });
    }

    selectedFeatureClaims.forEach((claim) => {
      updatedClaims.push(claim);
    });

    onChange(updatedClaims);
    onValidityChanged(checkSelectionIsValid());
  }, [
    isUserBuyer,
    isUserSeller,
    isUserAgency,
    isUserAdmin,
    selectedFeatureClaims,
    claimAction
  ]);

  const onUserBuyerChange = (val: boolean) => setIsUserBuyer(val);
  const onUserSellerChange = (val: boolean) => setIsUserSeller(val);
  const onUserAgencyChange = (val: boolean) => setIsUserAgency(val);
  const onUserAdminChange = (val: boolean) => setIsUserAdmin(val);

  const checkSelectionIsValid = (): boolean => {
    switch (claimAction) {
      case ClaimActions.Replace:
        return (isUserBuyer || isUserSeller || isUserAgency);
      case ClaimActions.Remove:
      case ClaimActions.Add:
        return true;
      case null:
        return false;
      default:
        return true;
    }
  };

  const onFeatureClaimChange = (claimKey: string, val: boolean) => {
    let newSelectedFeatureClaims = [...selectedFeatureClaims];
    const existingClaim = hasClaim(newSelectedFeatureClaims, claimKey);
    const newClaim = findClaim(featureClaims, claimKey);
    if (val) {
      if (!existingClaim && newClaim) newSelectedFeatureClaims.push(newClaim);
    } else {
      if (existingClaim) {
        newSelectedFeatureClaims = newSelectedFeatureClaims.filter(function (
          claim
        ) {
          return claim.key !== claimKey;
        });
      }
    }

    setSelectedFeatureClaims(newSelectedFeatureClaims);
  };

  const renderFeatureClaims = () => {
    const claimDoms: ReactElement[] = [];
    featureClaims.forEach((claim) => {
      claimDoms.push(
        <Checkbox
          key={claim.key}
          label={claim.label}
          onChange={(val: boolean) => {
            onFeatureClaimChange(claim.key, val);
          }}
          isDefaultChecked={hasClaim(selectedFeatureClaims, claim.key)}
        />
      );
    });
    return claimDoms;
  };

  const renderUserText = () => {
    if (users.length === 1) {
      return users[0].userName;
    } else {
      return `${users.length} users `;
    }
  };

  const renderOrgText = () => {
    if (orgs.length === 1) {
      return orgs[0].name;
    } else {
      return `these ${orgs.length} orgs`;
    }
  };

  const handleChangeClaimOperation = (id: string) => {
    onClaimActionChanged(id);
  };

  const generateClaimText = () => {
    switch (claimAction) {
      case ClaimActions.Add:
        return (
          <GreenText>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;These claims will be added to all selected users.
          </GreenText>
        );
      case ClaimActions.Replace:
        return (
          <YellowText>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp;These claims will be set for all selected users.
          </YellowText>
        );
      case ClaimActions.Remove:
        return (
          <RedText>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp;These claims will be removed from all selected users.
          </RedText>
        );
      case null:
      default:
        return <div></div>;
    }
  };

  return (
    <Wrapper>
      <Centered>
        <BulkH1>
          What claims should {renderUserText()} have for {renderOrgText()}?
        </BulkH1>
      </Centered>
      <Centered></Centered>
      <ActionGrid>
        <div></div>
        <div></div>
        <HorizontalRadioGroupWrapper>
          <CheckboxGroup>Action</CheckboxGroup>
          <RadioButtonGroup
            label=""
            onChange={handleChangeClaimOperation}
            options={radioOptions}
            defaultSelected={isOneUser ? radioOptions[0].id : ""}
            horizontal
            maxWidth="500px"
          ></RadioButtonGroup>
        </HorizontalRadioGroupWrapper>
        <div></div>
        <div></div>
      </ActionGrid>
      <Centered>
        <WarningWrapper>{generateClaimText()}</WarningWrapper>
      </Centered>
        <CheckboxLayoutGrid>
        <div></div>
        <div>
          <CheckboxGroup>Account Type</CheckboxGroup>
          <Checkbox1ColGroupGrid>
            <Checkbox
              label="Buyer"
              onChange={onUserBuyerChange}
              isDefaultChecked={isUserBuyer}
            />
            <Checkbox
              label="Seller"
              onChange={onUserSellerChange}
              isDefaultChecked={isUserSeller}
            />
            <Checkbox
              label="Agency"
              onChange={onUserAgencyChange}
              isDefaultChecked={isUserAgency}
            />
          </Checkbox1ColGroupGrid>
        </div>
        <div>
          <CheckboxGroup>User Roles</CheckboxGroup>
          <Checkbox1ColGroupGrid>
            <Checkbox
              label="Admin"
              onChange={onUserAdminChange}
              isDefaultChecked={isUserAdmin}
            />
          </Checkbox1ColGroupGrid>
        </div>
        <div>
          <CheckboxGroup>Feature Claims</CheckboxGroup>
          <Checkbox2ColsGroupGrid>
            {renderFeatureClaims()}
          </Checkbox2ColsGroupGrid>
        </div>
      </CheckboxLayoutGrid>
      <Notifications notifications={notifications} duration={4000} />
    </Wrapper>
  );
};
